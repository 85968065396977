import "../financePage/finSupplier.css";
import finImg from "../../assets/images/Startups.jpg";
import FinanceSuccsess from "../../assets/images/appliedForFinanceSuccess.gif"
import Footer from "../../components/Footer";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import "react-phone-number-input/style.css";
import {
  Grid,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useState } from "react";
import "../financePage/finStartUp.css";

import Header from "../../components/Header";
import { financialRegistrationForStartup } from "../../redux/slice/finStratupSlice";
import { useNavigate } from "react-router-dom";

const FinStartup = () => {
  const storedUser = localStorage.getItem("new_user");

  const gettingLoginNums = storedUser
    ? JSON.parse(storedUser).phoneNumber
    : null;

  const gettingLoginName = storedUser ? JSON.parse(storedUser).name : null;
  const [formValues, setFormValues] = useState({
    startupName: "",
    startupContactPersonName: gettingLoginName || "",
    startupContactPersonMobile: "",
    startupWebsiteURL: "",
    // startupFundingAmount: "",
    startupFinanceType: "",
  });
  const accessToken = localStorage.getItem("accessToken");

  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleRadioChange = (event) => {
    setFormValues({
      ...formValues,
      startupFinanceType: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      startupName,
      startupContactPersonName,
      startupContactPersonMobile,
      startupWebsiteURL,
      // startupFundingAmount,
      startupFinanceType,
    } = formValues;

    if (
      startupName === "" ||
      startupContactPersonName === "" ||
      startupWebsiteURL === ""
      // startupFundingAmount === ""
    ) {
      setError("Please fill in all required fields");
      return;
    }

    dispatch(
      financialRegistrationForStartup({
        formValues: {
          startupName,
          startupContactPersonName,
          startupContactPersonMobile,
          startupWebsiteURL,
          // startupFundingAmount,
          startupFinanceType,
        },
        accessToken,
        gettingLoginNums,
      })
    );
    setFormSubmitted(true);

    setFormValues({
      startupName: "",
      startupContactPersonName: "",
      startupContactPersonMobile: "", // Ensure this property is cleared
      startupWebsiteURL: "",
      // startupFundingAmount: "",
      startupFinanceType: "",
    });

    // Clear error state
    setError("");
  };
  const goToHome = () => {
    navigate(`/home`);
  };

  return (
    <>
      <Header />
      
      <Grid container mt={10}>
        <Grid item xs={12}  sm={10} md={8} lg={6} xl={4} mt={6} >
          <p className="finance_header_startup">
            Home / Apply for Finance/Showcase on BLEU
          </p>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12} mt={1} >
          <p className="startup_top">Apply for Finance/H@ART Membership</p>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12}>
          <p className="top_content_startup">
            We envision the future of real estate being shaped by innovative
            startups that provide intelligent solutions. With BLEU, your startup
            can not only gain exposure to a vast network of real estate
            visionaries but also access funding opportunities for collaboration.
            Complete the form below to allow us to connect with you for future
            discussions.
          </p>
        </Grid>
      </Grid>

      <div className="main_container_startup">
        <img src={finImg} alt="finImg" className="img_style_startup" />

        <div className="form_card_startup">
          {!formSubmitted ? (
            <CardContent>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label
                      htmlFor="username"
                      className="form-label all_lebel_startup"
                    >
                      Name of the Startup<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control custom_input_startup"
                      id="startupName"
                      name="startupName"
                      value={formValues.startupName}
                      onChange={handleChange}
                      type="text"
                      variant="outlined"
                      placeholder="Enter Name of Startup"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label
                      htmlFor="contact person"
                      className="form-label all_lebel_startup"
                    >
                      Name of the Contact Person
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control custom_input_startup"
                      id="startupContactPersonName"
                      name="startupContactPersonName"
                      value={formValues.startupContactPersonName}
                      onChange={handleChange}
                      type="text"
                      size="small"
                      variant="outlined"
                      placeholder="Enter Name of the Contact Person"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label
                      htmlFor="password"
                      className="form-label all_lebel_startup"
                    >
                      Mobile number of the Contact Person
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <PhoneInput
                      defaultCountry="IN"
                      placeholder="Enter mobile number"
                      international={true}
                      value={gettingLoginNums}
                      onChange={(value) =>
                        setFormValues({
                          ...formValues,
                          startupContactPersonMobile: value,
                        })
                      }
                      className="custom_input_startup"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label
                      htmlFor="contact person"
                      className="form-label all_lebel_startup"
                    >
                      Website URL<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control custom_input_startup"
                      id="startupWebsiteURL"
                      name="startupWebsiteURL"
                      value={formValues.startupWebsiteURL}
                      onChange={handleChange}
                      type="text"
                      size="small"
                      variant="outlined"
                      placeholder="http:// Enter website URL"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label
                      htmlFor="password"
                      className="form-label all_lebel_startup"
                    >
                      Type of Finance Required
                    </label>
                    <RadioGroup
                      value={formValues.startupFinanceType}
                      onChange={handleRadioChange}
                      row
                      // sx={{ borderBottom: "1.5px dashed #9CB5D3" }}
                    >
                      <FormControlLabel
                        value="Get Funded by HDFC Capital"
                        control={<Radio />}
                        label={
                          <div className="all_lebel_startup">
                            Get Funded by HDFC Capital
                          </div>
                        }
                      />
                      <FormControlLabel
                        value="Get Listed on BLEU"
                        control={<Radio />}
                        label={
                          <div className="all_lebel_startup">
                            H@ART Membership
                          </div>
                        }
                      />
                      <FormControlLabel
                        value="Both"
                        control={<Radio />}
                        label={<div className="all_lebel_startup">Both</div>}
                      />
                    </RadioGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="last_divider"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 btn_group_startup">
                    <button className="cancel_btn_startup" onClick={goToHome}>
                      Cancel
                    </button>
                    <button className="apply_btn_startup">Apply</button>
                  </div>
                </div>
              </form>
            </CardContent>
          ) : (
            <CardContent>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img
                    src={FinanceSuccsess}
                    alt="Your GIF"
                    height="200px"
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography className="thanks_styles">
                    Thank you for applying!
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography className="below_styles">
                    Please write to us at{" "}
                    <span style={{ color: "#0C4ACF" }}>
                      bleucircle@hdfccapital.com
                    </span>{" "}
                    for further communication.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={12}
                  mt={3}
                  display="flex"
                  justifyContent="center"
                >
                  <button className="go_to_btn" onClick={goToHome}>
                    Go to Home
                  </button>
                </Grid>
              </Grid>
            </CardContent>
          )}
        </div>
      </div>

      <div className="my_footer" style={{ marginTop: "auto" }}>
        <Footer />
      </div>
    </>
  );
};
export default FinStartup;
