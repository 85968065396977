import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './UserProfile.css';
import { Typography, Button } from '@mui/material';
import DeactivateAccount from '../components/DeactivateAccount';
import ProfileInfo from '../components/ProfileInfo';
import { useNavigate } from "react-router-dom";
import checkIcon from '../assets/images/userprofile/check_icon.svg';
import { submitEdit } from '../redux/slice/personaEditSlice';
import { useDispatch, useSelector } from "react-redux";

import Sidebar from "../components/Sidebar";
import Breadcrumbs from "../components/Breadcrumbs";
import NewLoginDialog from "../accounts/NewLoginDialog";
const styles = {
	outlinedButton: {
		display: "flex", width: "6.875rem", padding: "0.625rem 1rem", justifyContent: "center", alignItems: "center", gap: "0.5rem",
		borderRadius: "1.875rem", border: "1px solid var(--gray-300, #D0D5DD)", background: "var(--White, #FFF)", boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
	},
	outlinedText: {
		color: "var(--gray-700, #344054)", fontFamily: "Inter", fontSize: "0.875rem", fontStyle: "normal", fontWeight: "600", lineHeight: "1.25rem", textTransform: "none"
	},
	regualarText: {
		color: "var(--gray-500, #667085)", fontFamily: "Inter", fontSize: "1rem", fontStyle: "normal", fontWeight: "400", lineHeight: "1.5rem"
	},
	containedButton: {
		display: "flex", padding: "0.625rem 1rem", justifyContent: "center", alignItems: "center", gap: "0.5rem",
		borderRadius: "2.5rem", border: "1px solid #0C4ACF", background: "#0C4ACF", boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
	},
	containedText: {
		color: "var(--White, #FFF)", fontFamily: "Inter", fontSize: "0.875rem", fontStyle: "normal", fontWeight: "600", lineHeight: "1.25rem", textTransform: "none"
	}
}

const UserProfile = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('new_user')))

	const breadcrumbs = [['Home', 'home'], ['My Profile']];

	const options = [
		// { id: 1, option: 'General Information' },
		{ id: 2, option: 'Account Management' }
	]

	const [form, setForm] = useState({
		data: {},
		saved: false,
	})

	const [selectedOpt, setSelectedOpt] = useState(options[0])
	const handleSelectOpt = (opt) => {
		setSelectedOpt(opt)
	}

	const handleClick = (id) => {
		navigate(`/${id}`);
	}

	const handleSubmit = async () => {
		let data = {
			"name": form.data.name ?? '',
			"mobileNumber": userData.phoneNumber ?? '',
			"email": form.data.email ?? '',
			"orgName": form.data.firm ?? '',
			"personaSubtype": form.data.subrole ?? '',
			"deleteOrUpdatePersona": userData.personaSubtype,
		};
		try {
			let resp = await new Promise(async (resolve, reject) => {
				try {
				  let resp = dispatch(submitEdit({
					accessToken: form.data.authToken,
					formData: data
				  }));
				  resolve(resp);
				} catch (error) {
					console.log(error);
					reject(error)
				}
			}
			)
	
			console.log('resp: ', resp);
			if (resp?.meta?.requestStatus &&  resp?.payload?.status){
				localStorage.setItem(
					"user",
					JSON.stringify({
					  name: resp?.payload?.name,
					  persona: resp?.payload?.persona,
					  phoneNumber: userData?.phoneNumber,
					  personaSubtype: resp?.payload?.personaSubtype,
					  unsubscribedPersona: resp?.payload?.unsubscribedPersona,
					  email: resp?.payload?.email,
					  firm: resp?.payload?.orgName
					})
				);
				setUserData(JSON.parse(localStorage.getItem('user')))
				console.log('userData', userData)
				resp=null;
			}
		} catch (error) {
			console.log(error);
		}

		


		setForm({
			...form,
			saved: true
		});
	}

	const [isSidebarOpened, setIsSidebarOpened] = useState(false);
	function sidebarCallback(open) {
		setIsSidebarOpened(open);
	}

	return (<>
		<Header />
		
		<NewLoginDialog />
		<div className='profile-container'>
			<Breadcrumbs breadcrumbs={breadcrumbs} inverted/>
			<div className='profile-layout'>
				<div className="left-column">
					<Sidebar toggleCallback={sidebarCallback} items={options} action={handleSelectOpt} selected={selectedOpt?.id} />
				</div>
				<div className={`right-column ${isSidebarOpened ? 'faded' : ''}`}>
					{selectedOpt.option === 'General Information' && <ProfileInfo onChange={(formDet) => setForm({
						...form,
						data: formDet,
					})} />}
					{selectedOpt.option === 'Account Management' && <DeactivateAccount />}
					{selectedOpt.option === 'General Information' &&
						<div className='actions-container'>
							<div>
								{form.saved && <div className='actions-buttons'>
									<img src={checkIcon} alt='check-icon' className='check-image' />
									<Typography sx={styles.regualarText}>Changes Saved</Typography>
								</div>}
							</div>
							<div className='actions-buttons'>
								<div>
									<Button variant='outlined' sx={styles.outlinedButton}>
										<Typography sx={styles.outlinedText}>
											Cancel
										</Typography>
									</Button>
								</div>
								<div>
									<Button onClick={handleSubmit} variant='contained' sx={styles.containedButton}>
										<Typography sx={styles.containedText}>
											Save Changes
										</Typography>
									</Button>
								</div>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
		<Footer />
	</>)
}

export default UserProfile;