import React, { useEffect, useState } from "react";
import "../App.css";
import top10 from '../assets/images/top10.png';
import { useNavigate } from "react-router-dom";

function Top10Innovators({content}) {
    const navigate = useNavigate();

    const [companies, setCompanies] = useState([]);
    const [activeCard, setActiveCard] = useState(0);
    
    useEffect(() => {
        if (!content || !content.TopTechInnovators || !content.TopTechInnovators.length) return;
        var companyObj = {};
        content.TopTechInnovators.forEach(c => {
            companyObj[c.companyEntryNo] = {
                logo: c.companyLogo?.data?.attributes?.url,
                name: c.companyName,
                website: c.CompanyUrl,
                description: c.CompanyProfile,
            }
        });
        setCompanies(Object.values(companyObj));
        // console.log(Object.values(companyObj));
        const interval = setInterval(() => {
            setActiveCard(prev => prev + 1 >= Object.values(companyObj).length ? 0 : prev + 1);
        }, 5000);
        return () => clearInterval(interval);
    }, [content]);

    function shouldShow(i) {
        if (activeCard === 0 & [8,9,0,1,2].includes(i)) return [[8,9,0,1,2].indexOf(i)];
        if (activeCard === 1 & [9,0,1,2,3].includes(i)) return [[9,0,1,2,3].indexOf(i)];
        if (activeCard === 2 & [0,1,2,3,4].includes(i)) return [[0,1,2,3,4].indexOf(i)];
        if (activeCard === 3 & [1,2,3,4,5].includes(i)) return [[1,2,3,4,5].indexOf(i)];
        if (activeCard === 4 & [2,3,4,5,6].includes(i)) return [[2,3,4,5,6].indexOf(i)];
        if (activeCard === 5 & [3,4,5,6,7].includes(i)) return [[3,4,5,6,7].indexOf(i)];
        if (activeCard === 6 & [4,5,6,7,8].includes(i)) return [[4,5,6,7,8].indexOf(i)];
        if (activeCard === 7 & [5,6,7,8,9].includes(i)) return [[5,6,7,8,9].indexOf(i)];
        if (activeCard === 8 & [6,7,8,9,0].includes(i)) return [[6,7,8,9,0].indexOf(i)];
        if (activeCard === 9 & [7,8,9,0,1].includes(i)) return [[7,8,9,0,1].indexOf(i)];
        return false; 
    }
    
    return (
        <div className="top-10-bg">
            <div className="top-10-container">
                <div className="top-10-info">
                    <div className="top-10-title">
                        {content?.heading}
                    </div>
                    <div className="top-10-body">
                        {content?.descriptionOnPreLogin}
                    </div>
                    <div className="top-10-action">
                        <div className="action-button" onClick={_ => navigate('/project-lifecycle')}>Explore More</div>
                    </div>
                </div>
                <div className="top-10-media">
                    {companies && companies.length >= 5 && companies.map((c, i, a) => (
                        <div key={i} className={`top-company-card ${shouldShow(i) ? `top-company-card-${shouldShow(i)[0]}` : 'inactive'}`}>
                            <div className="top-company-card-logo" style={{backgroundImage: `url(${c.logo})`}} />
                            <div className="top-company-card-content">
                                <a href={'http://' + c.website} target="_blank" className="top-company-card-url">{c.website}</a>
                                <div className="top-company-card-description">{c.description}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Top10Innovators;
