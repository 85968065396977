import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getApiUrl } from "../../getApiUrl";

const initialState = {
    formData: {}, // Your form data state
    errors: null,
    loading: false,
};

export const submitEdit = createAsyncThunk(
    "edit/submitEditUnsubscribe",
    async ({ formData, accessToken }, { rejectWithValue }) => {
        const data = {...formData};
        const apiUrl = getApiUrl();
        try {
            const response = await axios.post(
                `${apiUrl}/services/apexrest/registration`,
                {
                    data,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


const personaEditSlice = createSlice({
    name: "form",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(submitEdit.pending, (state) => {
                state.loading = true;
            })
            .addCase(submitEdit.fulfilled, (state, action) => {
                state.loading = false;
                state.formData = action.payload; // Update form data on success
                state.errors = null; // Reset errors on success
            })
            .addCase(submitEdit.rejected, (state, action) => {
                state.loading = false;
                state.errors = action.payload; // Set errors on failure
            });
    },
});

export default personaEditSlice.reducer;
