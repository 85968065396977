import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../financePage/finance.css";
import finImg from "../../assets/images/finance.png";
import FinanceSuccsess from "../../assets/images/appliedForFinanceSuccess.gif";
import Footer from "../../components/Footer";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import {
  Grid,
  Card,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  TextField,
  IconButton,
  Button,
  Box,
  Stack,
  InputAdornment,
} from "@mui/material";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { useState } from "react";
import { indianStates } from "./stateData";
import Header from "../../components/Header";
import { MultiSelect } from "react-multi-select-component";
import { useEffect } from "react";
import { financialRegistrationForDeveloperSupplierEco } from "../../redux/slice/finDeveloperSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { financialRegistrationForDeveloperSelfFunding } from "../../redux/slice/finDeveloperSelfSlice";
import NewLoginDialog from "../../accounts/NewLoginDialog";
import { getStrapiApis } from "../../getStrapiApis";
import axios from "axios";

const Finance = () => {
  const storedUser = localStorage.getItem("new_user");
  const accessToken = localStorage.getItem("accessToken");
  const [footerOptions, setFooterOptions] = useState([]);
  const API_BASE_URL = getStrapiApis();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/policy-document?populate=policyDocuments&populate=policyDocuments.reportFile`
        );
        const footerData =
          response?.data?.data?.attributes?.policyDocuments?.map((docs) => {
            return {
              title: docs.reportName,
              id: docs.entryNo,
              reportUrl: docs.reportFile?.data?.attributes?.url,
            };
          });
        setFooterOptions(footerData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const dispatch = useDispatch();
  const gettingLoginNums = storedUser
    ? JSON.parse(storedUser).phoneNumber
    : null;

  const gettingLoginName = storedUser ? JSON.parse(storedUser).name : null;
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [error, setError] = useState("");
  const navigate = useNavigate();

  const openPDF = (url) => {
    window.open(url, "_blank");
  };

  const [formValues, setFormValues] = useState({
    contactPersonSelf: gettingLoginName || "",
    developerGroupSelfSelf: "",
    developerPhoneNumSelf: gettingLoginNums || "",
    websiteURLSelf: "",
    devPresenceInStateSelf: "",
    fundingAmountSelf: "",
    resDeliveryExpSelf: "",
    devFinanceTypeSelf: "",
  });
  const [formValuesSecond, setFormValuesSecond] = useState({
    developerGroup: "",
    contactPerson: gettingLoginName || "",
    developerPhoneNum: gettingLoginNums || "",
    numberOfVendors: "",
    valueOfMonthlyPurchase: "",
    typicalPaymentPeriod: "",
    submitInterestFor: "",
  });
  const [selectedForm, setSelectedForm] = useState("Funding for Self");

  const handleRadioChange = (event) => {
    setSelectedForm(event.target.value);
  };

  const options = indianStates.map((state) => ({ label: state, value: state }));

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleChangeSecond = (e) => {
    const { name, value } = e.target;
    setFormValuesSecond({
      ...formValuesSecond,
      [name]: value,
    });
  };

  const handleChangeOfDeliveryExperience = (e) => {
    setFormValues({
      ...formValues,
      resDeliveryExpSelf: e.target.value,
    });
  };
  const handleVendorNoChange = (e) => {
    setFormValuesSecond({
      ...formValuesSecond,
      numberOfVendors: e.target.value,
    });
  };
  const handleMonthlyPurchesChange = (e) => {
    setFormValuesSecond({
      ...formValuesSecond,
      valueOfMonthlyPurchase: e.target.value,
    });
  };
  const handlePaymentPeriodChange = (e) => {
    setFormValuesSecond({
      ...formValuesSecond,
      typicalPaymentPeriod: e.target.value,
    });
  };
  const handleSubmitInterestForChange = (e) => {
    setFormValuesSecond({
      ...formValuesSecond,
      submitInterestFor: e.target.value,
    });
  };

  const preSelectedOptions = options.filter((option) =>
    indianStates.includes(option.value)
  );
  const handleStateChange = (selected) => {
    setSelectedOptions(selected);
    const selectedStates = selected.map((option) => option.value);
    setFormValues({
      ...formValues,
      devPresenceInStateSelf: selectedStates.join(", "),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      contactPersonSelf,
      developerGroupSelfSelf,
      developerPhoneNumSelf,
      websiteURLSelf,
      devPresenceInStateSelf,
      fundingAmountSelf,
      resDeliveryExpSelf,
      devFinanceTypeSelf,
    } = formValues;

    if (developerGroupSelfSelf === "" || contactPersonSelf === "") {
      setError("Please fill in all required fields");
      return;
    }

    dispatch(
      financialRegistrationForDeveloperSelfFunding({
        formValues: {
          contactPersonSelf,
          developerGroupSelfSelf,
          developerPhoneNumSelf,
          websiteURLSelf,
          devPresenceInStateSelf,
          fundingAmountSelf,
          resDeliveryExpSelf,
        },
        accessToken,
        gettingLoginNums
      })
    );
    setFormSubmitted(true);

    setFormValues({
      contactPersonSelf: gettingLoginName || "",
      developerGroupSelfSelf: "",
      developerPhoneNumSelf: gettingLoginNums || "",
      websiteURLSelf: "",
      devPresenceInStateSelf: "",
      fundingAmountSelf: "",
      resDeliveryExpSelf: "",
      devFinanceTypeSelf: "",
    });

    // Clear error state
    setError("");
  };
  const handleSubmitSecond = (e) => {
    e.preventDefault();
    const {
      developerGroup,
      contactPerson,
      developerPhoneNum,
      numberOfVendors,
      valueOfMonthlyPurchase,
      typicalPaymentPeriod,
      submitInterestFor,
    } = formValuesSecond;

    if (developerGroup === "" || contactPerson === "") {
      setError("Please fill in all required fields");
      return;
    }

    dispatch(
      financialRegistrationForDeveloperSupplierEco({
        formValuesSecond: {
          developerGroup,
          contactPerson,
          developerPhoneNum,
          numberOfVendors,
          valueOfMonthlyPurchase,
          typicalPaymentPeriod,
          submitInterestFor,
        },
        accessToken,
        gettingLoginNums,
      })
    );
    setFormSubmitted(true);

    setFormValuesSecond({
      developerGroup: "",
      contactPerson: "",
      numberOfVendors: "",
      valueOfMonthlyPurchase: "",
      typicalPaymentPeriod: "",
      submitInterestFor: "",
    });

    // Clear error state
    setError("");
  };

  const goToHome = () => {
    navigate(`/home`);
  };
  const handleClick = (id) => {
    navigate(`/${id}`);
  };
  return (
    <>
      <Header />
      <NewLoginDialog />
      <Grid container mt={10}>
        <Grid item xs={12} mt={6}>
          <p variant="body1" className="finance_header_dev">
            <a onClick={() => handleClick("home")}>Home/&nbsp;</a> Apply for
            Finance
          </p>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12} mt={2}>
          <p className="top_dev_content">Apply for Finance</p>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12}>
          <div className="top_content_dev">
            <ul>
              <li>
                {" "}
                Apply to HDFC Capital to get funding for your affordable housing
                projects.
              </li>
              <li>
                Express your interest in providing financing solutions to your
                trusted suppliers.
              </li>
            </ul>
          </div>
        </Grid>
      </Grid>
      <div className="main_container_dev">
        <img src={finImg} alt="finImg" className="img_style_developer" />

        <div className="form_card_main_dev">
          {formSubmitted ? null : (
            <>
              <RadioGroup
                value={selectedForm}
                onChange={handleRadioChange}
                sx={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  value="Funding for Self"
                  control={<Radio />}
                  label={
                    <div>
                      <IconButton>
                        <PeopleAltOutlinedIcon sx={{ color: "#0C4ACF" }} />
                      </IconButton>
                      <span className="all_lebel_dev"> Funding for Self</span>
                    </div>
                  }
                  //   label="Funding for Self"
                />
                <FormControlLabel
                  value=" Explore financing for your supply ecosystem"
                  control={<Radio />}
                  label={
                    <div>
                      <IconButton>
                        <LocalShippingOutlinedIcon sx={{ color: "#0C4ACF" }} />
                      </IconButton>
                      <span className="all_lebel_dev">
                        {" "}
                        Explore financing for your supply ecosystem
                      </span>
                    </div>
                  }
                />
              </RadioGroup>
              <div className="radio_group"></div>
            </>
          )}

          {selectedForm === "Funding for Self" ? (
            !formSubmitted ? (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 mb-3 my-3">
                    <label
                      htmlFor="username"
                      className="form-label all_lebel_dev"
                    >
                      Name of the Developer Group
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control custom_input_dev"
                      id="developerGroupSelfSelf"
                      name="developerGroupSelfSelf"
                      value={formValues.developerGroupSelfSelf}
                      onChange={handleChange}
                      placeholder="Enter Name of the Developer Group"
                    />
                  </div>
                  <div className="col-md-6 mb-3 my-3">
                    <label
                      htmlFor="contact person"
                      className="form-label all_lebel_dev"
                    >
                      Name of the Contact Person
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control custom_input_dev"
                      name="contactPersonSelf"
                      value={formValues.contactPersonSelf}
                      onChange={handleChange}
                      variant="outlined"
                      placeholder="Enter Name of the Contact Person"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3 my-2">
                    <label
                      htmlFor="username"
                      className="form-label all_lebel_dev"
                    >
                      Mobile number of the Contact Person
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <PhoneInput
                      defaultCountry="IN"
                      country={gettingLoginNums ? "IN" : undefined}
                      placeholder="Enter mobile number"
                      international={true}
                      value={gettingLoginNums}
                      onChange={(value) =>
                        setFormValues({
                          ...formValues,
                          mobileNumberOfContact: value,
                        })
                      }
                      className="custom_input_dev"
                    />
                  </div>
                  <div className="col-md-6 mb-3 my-2">
                    <label
                      htmlFor="contact person"
                      className="form-label all_lebel_dev"
                    >
                      Website URL
                    </label>
                    <input
                      type="text"
                      className="form-control custom_input_dev"
                      id="websiteURL"
                      name="websiteURLSelf"
                      value={formValues.websiteURLSelf}
                      onChange={handleChange}
                      variant="outlined"
                      placeholder="http://  Enter Website URL"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3 my-3">
                    <label className="all_lebel_dev">
                      Developer Presence in States(s)
                      <span style={{ color: "red" }}>*</span>
                    </label>{" "}
                    {/* Adjust the width as needed */}
                    <MultiSelect
                      options={options}
                      value={selectedOptions}
                      onChange={handleStateChange}
                      labelledBy={"Select States(s)"}
                    />
                  </div>
                  <div className="col-md-6 mb-3 my-2">
                    <label
                      htmlFor="contact person"
                      className="form-label all_lebel_dev"
                    >
                      Residential Delivery Experience
                    </label>

                    <select
                      className="form-select custom_input_dev"
                      id="deliveryExperience"
                      aria-label="Select Delivery Experience"
                      onChange={handleChangeOfDeliveryExperience}
                      value={formValues.resDeliveryExpSelf}
                    >
                      <option defaultValue="">
                        Select Delivery Experience
                      </option>
                      <option value="Less than 1 mn SF">
                        Less than 1 mn SF
                      </option>
                      <option value="1-3 mn SF">1-3 mn SF</option>
                      <option value="More than 3 mn SF">
                        More than 3 mn SF
                      </option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3 my-2">
                    <label
                      htmlFor="username"
                      className="form-label all_lebel_dev"
                    >
                      Funding Amount Required (INR Crores)
                    </label>
                    <input
                      type="text"
                      className="form-control custom_input_dev"
                      id="fundingAmountSelf"
                      name="fundingAmountSelf"
                      value={formValues.fundingAmountSelf}
                      onChange={handleChange}
                      variant="outlined"
                      placeholder="Enter Funding Amount Required "
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="consent"
                        onChange={(e) => setDisableSubmit(!e.target.checked)}
                      />
                      <label className="form-check-label" htmlFor="consent">
                        Please note that filling this form and providing any
                        information requested herein is purely at your option
                        and choice. The use of the form and provision of
                        information herein is subject to the{" "}
                        <a
                          href={footerOptions[2]?.reportUrl}
                          target="_blank"
                        >
                          Terms of Use
                        </a>
                        ,{" "}
                        <a
                          href={footerOptions[0]?.reportUrl}
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                        ,{" "}
                        <a
                          href={footerOptions[3]?.reportUrl}
                          target="_blank"
                        >
                          Cookie Policy
                        </a>{" "}
                        and{" "}
                        <a
                          href={footerOptions[1]?.reportUrl}
                          target="_blank"
                        >
                          Legal Disclaimer
                        </a>
                        , which you have accepted, at the time of login.
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="last_divider"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 btn_group_dev">
                    <button className="cancel_btn_dev" onClick={goToHome}>
                      Cancel
                    </button>
                    <button
                      disabled={disableSubmit ? true : false}
                      className="apply_btn_dev"
                      type="submit"
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </form>
            ) : (
              <CardContent>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img src={FinanceSuccsess} alt="Your GIF" height="200px" />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography className="thanks_styles">
                      Thank you for applying!
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography className="below_styles">
                      Please write to us at{" "}
                      <span style={{ color: "#0C4ACF" }}>
                        bleucircle@hdfccapital.com
                      </span>{" "}
                      for further communication.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    mt={3}
                    display="flex"
                    justifyContent="center"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        borderRadius: "20px",
                        backgroundColor: "#0C4ACF",
                        textTransform: "none",
                        fontFamily: "Inter",
                      }}
                      onClick={goToHome}
                    >
                      Go to Home
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            )
          ) : !formSubmitted ? (
            <form onSubmit={handleSubmitSecond}>
              <div className="row">
                <div className="col-md-6 mb-3 my-3">
                  <label
                    htmlFor="username"
                    className="form-label all_lebel_dev"
                  >
                    Name of the Developer Group
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control custom_input_dev"
                    id="developerGroup"
                    name="developerGroup"
                    value={formValuesSecond.developerGroup}
                    onChange={handleChangeSecond}
                    variant="outlined"
                    placeholder="Enter Name of the Developer Group"
                  />
                </div>
                <div className="col-md-6 mb-3 my-3">
                  <label
                    htmlFor="contact person"
                    className="form-label all_lebel_dev"
                  >
                    Name of the Contact Person
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control custom_input_dev"
                    name="contactPerson"
                    value={formValuesSecond.contactPerson}
                    onChange={handleChangeSecond}
                    size="small"
                    variant="outlined"
                    placeholder="Enter Name of the Contact Person"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3 my-1">
                  <label
                    htmlFor="username"
                    className="form-label all_lebel_dev"
                  >
                    Mobile number of the Contact Person
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <PhoneInput
                    defaultCountry="IN"
                    placeholder="+91 Enter mobile number"
                    country={"in"}
                    value={gettingLoginNums}
                    onChange={(value) =>
                      setFormValuesSecond({
                        ...formValuesSecond,
                        developerPhoneNum: value,
                      })
                    }
                    className="custom_input_dev"
                  />
                </div>
                <div className="col-md-6 mb-3 my-1 ">
                  <label
                    htmlFor="contact person"
                    className="form-label all_lebel_dev"
                  >
                    Numbers of Vendor(s)
                  </label>
                  <select
                    className="form-select custom_input_dev"
                    id="deliveryExperience"
                    aria-label="Select Delivery Experience"
                    onChange={handleVendorNoChange}
                    value={formValuesSecond.numberOfVendors}
                  >
                    <option defaultValue="">Select Number of Vendor(s)</option>
                    <option value="Less than 100">Less than 100</option>
                    <option value="100-500">100-500</option>
                    <option value="500-1000">500-1000</option>
                    <option value="More than 1000">More than 1000</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3 my-2">
                  <label
                    htmlFor="username"
                    className="form-label all_lebel_dev"
                  >
                    Value of Monthly Purchase
                  </label>

                  <select
                    className="form-select custom_input_dev"
                    id="deliveryExperience"
                    aria-label="Select Value of Monthly Purchase"
                    onChange={handleMonthlyPurchesChange}
                    value={formValuesSecond.valueOfMonthlyPurchase}
                  >
                    <option defaultValue="">
                      Select Value of Monthly Purchase
                    </option>
                    <option value="Less than 1 crore">Less than 1 crore</option>
                    <option value="1-5 crores">1-5 crores</option>
                    <option value="5-10 crores">5-10 crores</option>
                    <option value="More than 10 crores">
                      More than 10 crores
                    </option>
                  </select>
                </div>
                <div className="col-md-6 mb-3 my-2">
                  <label
                    htmlFor="contact person"
                    className="form-label all_lebel_dev"
                  >
                    Typical Payment Period
                  </label>

                  <select
                    className="form-select custom_input_dev"
                    id="deliveryExperience"
                    aria-label="Select Delivery Experience"
                    onChange={handlePaymentPeriodChange}
                    value={formValuesSecond.typicalPaymentPeriod}
                  >
                    <option defaultValue="">
                      Select Typical Payment Period
                    </option>
                    <option value="Less than 30 days">Less than 30 days</option>
                    <option value="30-60 days">30-60 days</option>
                    <option value="60-90 days">60-90 days</option>
                    <option value="More than 90 days">More than 90 days</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3 my-2">
                  <label
                    htmlFor="username"
                    className="form-label all_lebel_dev"
                  >
                    Submit interest for
                  </label>

                  <select
                    className="form-select custom_input_dev"
                    id="deliveryExperience"
                    aria-label="Select Delivery Experience"
                    onChange={handleSubmitInterestForChange}
                    value={formValuesSecond.submitInterestFor}
                  >
                    <option defaultValue="">Select Category of Funding</option>
                    <option value="Invoice Discounting">
                      Invoice Discounting
                    </option>
                    <option value="Working Capital Debt">
                      Working Capital Debt
                    </option>
                    <option value="Term Loan">Term Loan</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="last_divider"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 btn_group_dev">
                  <button className="cancel_btn_dev" onClick={goToHome}>
                    Cancel
                  </button>
                  <button className="apply_btn_dev" type="submit">
                    Apply
                  </button>
                </div>
              </div>
            </form>
          ) : (
            <CardContent>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img src={FinanceSuccsess} alt="Your GIF" height="200px" />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography className="thanks_styles">
                    Thank you for applying!
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography className="below_styles">
                    Please write to us at{" "}
                    <span style={{ color: "#0C4ACF" }}>
                      bleucircle@hdfccapital.com
                    </span>{" "}
                    for further communication.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={12}
                  mt={3}
                  display="flex"
                  justifyContent="center"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      borderRadius: "20px",
                      backgroundColor: "#0C4ACF",
                      textTransform: "none",
                      fontWeight: "Inter",
                    }}
                    onClick={goToHome}
                  >
                    Go to Home
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          )}
        </div>
      </div>

      <Grid container>
        <Grid item xs={12} mt={4}>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
};
export default Finance;
