import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/banner.css";

export default function Breadcrumbs(props) {
    const defaultBreadcrumbs = [['Home', 'home']];
    const {breadcrumbs = defaultBreadcrumbs, inverted} = props;
    const navigate = useNavigate();

    const handleClick = (id) => {
		navigate(`/${id}`);
	}
    return (
        <div className={`breadcrumbs ${inverted ? 'inverted' : ''}`}>
          {breadcrumbs.map((crumb, i) => {
            if (!crumb || crumb.length === 0) return;
            return (
              <div key={i} className={`crumb ${crumb.length === 2 ? 'hasUrl' : ''}`}  onClick={() => crumb[1] && handleClick(crumb[1])}>
                {i != 0 && <span className="seperator"></span>}
                {(crumb)[0]}
              </div>
            )
          })}
        </div>
    )
}