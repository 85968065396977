// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.truncate-content {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
}
.truncate-content.clamped {
    -webkit-line-clamp: 7;
}
.truncate-content.toasted {
    -webkit-line-clamp: 4;
}

.read-more {
    font-family: Inter;
    font-size: 1.25rem;
    line-height: 1.5;
    color: #0C4ACF;
    padding: .25rem 0;
    background: none;
    border: none;
    outline: none;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 1rem;
}

.truncate-toast-header {
    margin-top: .5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}
.truncate-toast-title {
    width: calc(100% - 2rem);
    color: #0C4ACF;
    font-family: Syne;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -.32px;
}
.truncate-toast-toggle {
    padding: .25rem;
    cursor: pointer;
}
.truncate-text-toggle img {
    width: 1.5rem;
    height: 1.5rem;
}
.truncate-toast-body {
    font-family: Inter;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 40px;
    padding: 0 1rem;
    max-height: 70vh;
    overflow-y: auto;
}


`, "",{"version":3,"sources":["webpack://./src/styles/truncate.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,gBAAgB;IAChB,4BAA4B;AAChC;AACA;IACI,qBAAqB;AACzB;AACA;IACI,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;IACd,iBAAiB;IACjB,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;AACjB;AACA;IACI,wBAAwB;IACxB,cAAc;IACd,iBAAiB;IACjB,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;IAChB,sBAAsB;AAC1B;AACA;IACI,eAAe;IACf,eAAe;AACnB;AACA;IACI,aAAa;IACb,cAAc;AAClB;AACA;IACI,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".truncate-content {\n    display: -webkit-box;\n    overflow: hidden;\n    -webkit-box-orient: vertical;\n}\n.truncate-content.clamped {\n    -webkit-line-clamp: 7;\n}\n.truncate-content.toasted {\n    -webkit-line-clamp: 4;\n}\n\n.read-more {\n    font-family: Inter;\n    font-size: 1.25rem;\n    line-height: 1.5;\n    color: #0C4ACF;\n    padding: .25rem 0;\n    background: none;\n    border: none;\n    outline: none;\n    font-weight: 500;\n    cursor: pointer;\n    margin-bottom: 1rem;\n}\n\n.truncate-toast-header {\n    margin-top: .5rem;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 1rem;\n}\n.truncate-toast-title {\n    width: calc(100% - 2rem);\n    color: #0C4ACF;\n    font-family: Syne;\n    font-size: 1.5rem;\n    font-weight: 500;\n    line-height: 1.2;\n    letter-spacing: -.32px;\n}\n.truncate-toast-toggle {\n    padding: .25rem;\n    cursor: pointer;\n}\n.truncate-text-toggle img {\n    width: 1.5rem;\n    height: 1.5rem;\n}\n.truncate-toast-body {\n    font-family: Inter;\n    font-size: 18px;\n    line-height: 28px;\n    margin-bottom: 40px;\n    padding: 0 1rem;\n    max-height: 70vh;\n    overflow-y: auto;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
