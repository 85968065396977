import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import authReducer from './slice/authSlice';
import otpVarifySlice from './slice/otpVarifySlice';
import otpVerificationSlice from './slice/otpVerificationSlice';
import personaRegSlice from './slice/personaRegSlice';
import finStratupSlice from './slice/finStratupSlice';
import finSupplierSlice from './slice/finSupplierSlice';
import finDeveloperSlice from './slice/finDeveloperSlice';
import submitUnsubscribe from './slice/unsubscribeSlice';
import submitDelete from './slice/personaDelSlice';
import submitEdit from './slice/personaEditSlice';
import finDeveloperSelfSlice from './slice/finDeveloperSelfSlice';
import newLoginSlice from "./slice/newLoginSlice";



const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
};

const reducer = combineReducers({
  auth: authReducer,
  login: newLoginSlice,
  subscriber:otpVarifySlice,
  otpVerification:otpVerificationSlice,
  form:personaRegSlice,
  startups:finStratupSlice,
  supplier:finSupplierSlice,
  developerSelf:finDeveloperSlice,
  developerSupply:finDeveloperSlice,
  developerSelfFunding:finDeveloperSelfSlice,
  unsubscribe: submitUnsubscribe,
  delete: submitDelete,
  edit: submitEdit,
  
});

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,

});

export const persistor = persistStore(store);

export default store; 
