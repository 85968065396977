import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getApiUrl } from "../../getApiUrl";

export const sendOTP = createAsyncThunk(
  "subscriber/sendOTP",
  async ({ phoneNumber, accessToken }, { rejectWithValue }) => {
    const phoneNumberNew = phoneNumber.slice(1);
   
    const apiUrl = getApiUrl();
  
    try {
      const response = await axios.post(
        `${apiUrl}/services/apexrest/subscriber`,
        {
          data: {
            phoneNumber: phoneNumberNew,
            consent: true,
          },
          action: "insert",
        },
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${accessToken}`
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const savePhoneNumberFunc = (phoneNumber) => (dispatch) => {
  dispatch(savePhoneNumber(phoneNumber));
};

const otpVerifySlice = createSlice({
  name: "subscriber",
  initialState: {
    otpResponse: null,
    phoneNumber: null, 
    persona: null,
    userName: "",
    personaSubtype: null,
    unsubscribedPersona: false,
    email: null,
    firm: null,
  },
  reducers: {
    setOTPResponse: (state, action) => {
      state.otpResponse = action.payload;
    },
    savePhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    savePersonaData: (state, action) => {
      state.persona = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendOTP.fulfilled, (state, action) => {
      state.otpResponse = action.payload;
      state.persona = action.payload?.persona;
      state.userName = action.payload?.name;
      state.personaSubtype = action.payload?.personaSubtype;
      state.unsubscribedPersona = action.payload?.unsubscribedPersona
      state.email = action.payload?.email
      state.firm = action.payload?.orgName
    });
  },
});

export const { setOTPResponse, savePhoneNumber } = otpVerifySlice.actions;

export default otpVerifySlice.reducer;
