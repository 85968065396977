import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getApiUrl } from "../../getApiUrl";

const initialState = {
  loading: false,
  error: null,
  success: null,
  loggedIn: false
};

const apiUrl = getApiUrl();

export const newLogin = createAsyncThunk('auth/login', async ({userName, password, accessToken, mobileNumber}, {rejectWithValue}) => {
  try {
    const response = await axios.post(
        `${apiUrl}/services/apexrest/verifyusernamepassword`, 
        {data: { userName, password, mobileNumber }},
        {headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        }}
    );
    return response.data; // Assuming the API returns data to be stored in the state
  } catch (error) {
    return rejectWithValue(error.response.data); // Pass the error response data to the reducer
  }
});

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    isLoggedin(state, action) {
      state.loggedIn = action.payload;
    },
    setStatus(state, action) {
      state.success = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(newLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(newLogin.fulfilled, (state, action) => {
        if(action.payload?.status) {
          let user = {
            name: action.meta.arg.userName,
            phoneNumber: action.meta.arg.mobileNumber
          }
          let username = user.name?.toLowerCase();
          if (username.indexOf('developer') > -1) user.persona = 'developers';
          if (username.indexOf('supplier') > -1) user.persona = 'suppliers';
          if (username.indexOf('startup') > -1) user.persona = 'startups';
          if (username.indexOf('financier') > -1) user.persona = 'financiers';
          
          localStorage.setItem('new_user', JSON.stringify(user));
          state.loggedIn = true;
        }
        state.loading = false;
        state.error = null;
      
        state.success = action.payload;
      })
      .addCase(newLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // error.response.data from rejectWithValue
        state.success = null;
      });
  },
});
export const { isLoggedin, setStatus } = loginSlice.actions
export default loginSlice.reducer;
