import React from 'react';
import Markdown from 'react-markdown';

const CustomLink = ({href, children}) => {
  const handleClick = (event) => {
    event.preventDefault();
    window.open(href, '_blank');
  };
  return <a href={href} onClick={handleClick}>{children}</a>;
};

const CustomMarkdown = ({children}) => {
  return (
    <Markdown components={{a: CustomLink}}>
      {children}
    </Markdown>
  );
};

export default CustomMarkdown;
