import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getApiUrl } from "../../getApiUrl";

const initialState = {
    formData: {}, // Your form data state
    errors: null,
    loading: false,
};

export const submitUnsubscribe = createAsyncThunk(
    "delete/submitUnsubscribe",
    async ({ formData, accessToken }, { rejectWithValue }) => {
        const { phoneNumber, subPersona, unsubscribePersona } = formData;
        // const processedPhoneNumber = phoneNumber.replace(/^(\+)/, "");
        const apiUrl = getApiUrl();
        try {
            const user = JSON.parse(localStorage.getItem('new_user'));
            const data = {
                userName: user?.name,
                mobileNumber: user?.phoneNumber,
                unsubscribe: unsubscribePersona,
            };
            const response = await axios.post(
                `${apiUrl}/services/apexrest/verifyusernamepassword`,
                {
                    data,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            if (response.status === 200){
                localStorage.setItem('unsubscribed', JSON.stringify(unsubscribePersona));
            }
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


const personaUnSubSlice = createSlice({
    name: "form",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(submitUnsubscribe.pending, (state) => {
                state.loading = true;
            })
            .addCase(submitUnsubscribe.fulfilled, (state, action) => {
                // debugger;
                // localStorage.setItem("unsubscribedPersona", action.payload?.unsubscribePersona)
                state.loading = false;
                state.formData = action.payload; // Update form data on success
                state.errors = null; // Reset errors on success
            })
            .addCase(submitUnsubscribe.rejected, (state, action) => {
                state.loading = false;
                state.errors = action.payload; // Set errors on failure
            });
    },
});

export default personaUnSubSlice.reducer;
