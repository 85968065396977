import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getApiUrl } from "../../getApiUrl";

const initialState = {
    formData: {}, // Your form data state
    errors: null,
    loading: false,
};

export const submitDelete = createAsyncThunk(
    "delete/submitUnsubscribe",
    async ({ formData, accessToken }, { rejectWithValue }) => {
        const apiUrl = getApiUrl();
        try {
            const user = JSON.parse(localStorage.getItem('new_user'));
            const data = {
                userName: user?.name,
                mobileNumber: user?.phoneNumber,
                deleteUser: true,
            };
            const response = await axios.post(
                `${apiUrl}/services/apexrest/verifyusernamepassword`,
                {
                    data,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


const personaDeleteSlice = createSlice({
    name: "form",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(submitDelete.pending, (state) => {
                state.loading = true;
            })
            .addCase(submitDelete.fulfilled, (state, action) => {
                state.loading = false;
                state.formData = action.payload; // Update form data on success
                state.errors = null; // Reset errors on success
            })
            .addCase(submitDelete.rejected, (state, action) => {
                state.loading = false;
                state.errors = action.payload; // Set errors on failure
            });
    },
});

export default personaDeleteSlice.reducer;
