import React, { useState } from "react";
import arrow from "../assets/images/arrow-right.svg";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import CustomMarkdown from "./CustomMarkdown";

function SolutionInfographics({infographic}) {
    const [isOpen, setIsOpen] = useState(false);
    return (<>
        <div className="infographic-container" >
            {infographic?.subHeading && <div className="section-heading">{infographic?.subHeading}</div>}
            {infographic?.description && (
                <div className="description"> 
                <CustomMarkdown>{infographic?.description}</CustomMarkdown>
                </div>
            )}
            {infographic?.url?.data?.attributes?.url && (<>
                <img onClick={_ => setIsOpen(true)} className="infographic-image" src={infographic?.url?.data?.attributes?.url} />
                <Lightbox
                    open={isOpen}
                    close={_ => setIsOpen(false)}
                    slides={[{src: infographic?.url?.data?.attributes?.url}]}
                    carousel={{finite: true}}
                    controller={{closeOnPullUp: true, closeOnPullDown: true, closeOnBackdropClick: true, touchAction: 'none'}}
                    plugins={[Zoom]}
                />
            </>)}
            {infographic?.imageCredit && (
                <CustomMarkdown>{infographic?.imageCredit}</CustomMarkdown>
            )}
        </div>
    </>);
}

export default SolutionInfographics;
