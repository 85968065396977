export const getStrapiApis =() =>{
  return "https://cms-hdfc-uat-app01.azurewebsites.net/api";
    if (process.env.REACT_APP_ENV === "DEV") {
      return process.env.REACT_APP_API_URL_DEV_STRAPPI;
    } else if (process.env.REACT_APP_ENV === "PRODUCTION") {
      return process.env.REACT_APP_API_URL_PROD_STRAPPI;
    } else if (process.env.REACT_APP_ENV === "UAT") {
      return process.env.REACT_APP_API_URL_UAT_STRAPPI;
    } else {
      // Default API URL or error handling if necessary
      return process.env.REACT_APP_API_URL_DEV_STRAPPI;
    }
  }
  