import React, { useEffect, useState } from 'react'
import arrow from '../assets/images/chevron-down-blue.svg';
import '../styles/sidebar.css';

export default function Sidebar({title, items, selected, action, toggleCallback}) {
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const displayKeys = ['leftNavTitle', 'option'];
    const idKeys = ['subCategoryID', 'id'];
    const findLabel = item => {
        if (!item) return undefined;
        if (item.leftNavTitle && item.leftNavTitle.length) return 'leftNavTitle';
        if (item.subCategoryName && item.subCategoryName.length) return 'subCategoryName';
        if (item.option && item.option.length) return 'option';
        return undefined;
        // item && Object.keys(item).find(key => displayKeys.includes(key))
    };
    const findId = item => item && Object.keys(item).find(key => idKeys.includes(key));

    let selectedItem = {};
    if (items && items.length && typeof selected !== undefined) {
        selectedItem = items.find(item => item?.subCategoryID === selected || item?.id === selected);
    }
    // console.log({selectedItem}, {items}, {selected});
    function mobileItemSelected(item) {
        action(item);
        setDrawerOpen(false);
    }
    useEffect(() => {
        if (toggleCallback) toggleCallback(isDrawerOpen);
    }, [isDrawerOpen]);

    return (<>
        <div className="sidebar">
            <div className="sidebar-item sidebar-title">
                <div className="sidebar-title">{title}</div>
            </div>
            {items && items.map((item, i) => (
                <div key={i} onClick={_ => action(item)} className={`sidebar-item ${selectedItem?.[findId(item)] === item[findId(item)] ? 'selected' : ''}`}>
                    <div className="sidebar-label">{item[findLabel(item)]}</div>
                </div>
            ))}
        </div>
        <div className="sidebar-mobile">
            <div className={`sidebar-mobile-top ${isDrawerOpen ? 'is-open' : ''}`}>
                <div className="sidebar-mobile-item" onClick={_ => setDrawerOpen(!isDrawerOpen)}>
                    <div>
                        {isDrawerOpen && title ? title : selectedItem?.[findLabel(selectedItem)]}
                    </div>
                    <div className="sidebar-mobile-arrow">
                        <img src={arrow} alt="Open sidebar" />
                    </div>
                </div>
            </div>
            <div className={`sidebar-mobile-bottom ${isDrawerOpen ? 'is-open' : false}`}>
                {items && items.map((item, i) => (
                    <div key={i} onClick={_ => mobileItemSelected(item)} className={`sidebar-mobile-item ${selectedItem?.[findId(item)] === item[findId(item)] ? 'selected' : ''}`}>
                        {item[findLabel(item)]}
                    </div>
                ))}
            </div>
        </div>
    </>)
}
