import React, {useEffect, useState} from "react";
import Markdown from 'react-markdown';
import TruncateText from "./TruncateText";
import SolutionBlogsReports from "./SolutionBlogsReports";
import GeneralSlider from "./GeneralSlider";
import CustomMarkdown from "./CustomMarkdown";

function SolutionAbout({solution = {}, heading, imageSectionNumber, subSectionNumber, hideHeading = false}) {
  const {category, categoryContent, advantages, keyConstrains, innovationType, subSection1, sectionWithImages, aboutLogo, catAbout} = solution;
  const [descriptiveSection, setDescriptiveSection] = useState(null)

  useEffect(()=>{
    if (imageSectionNumber !== undefined && sectionWithImages) {
      setDescriptiveSection(sectionWithImages[imageSectionNumber]);
    } else if (subSectionNumber !== undefined && subSection1) {
      setDescriptiveSection(subSection1[subSectionNumber]);
    } 
    return () => setDescriptiveSection(null);
  }, [solution]);
  return (<>
    {innovationType === 'Academia' && <>
        {descriptiveSection ? <>
          <div className="section-heading">
            <div className="academia-subheading">
              {descriptiveSection?.sectionImage?.data?.attributes?.url && <img style={{width: '78px', height: '52px'}} src={descriptiveSection?.sectionImage?.data?.attributes?.url} />}
              <div className="text">{descriptiveSection?.sectionHeader}</div>
            </div>
          </div>
          <div className="solution-about-body">
            <CustomMarkdown>{descriptiveSection.sectionDescription}</CustomMarkdown>
          </div>
          {descriptiveSection.sectionReports && descriptiveSection.sectionReports.length > 0 && (<>
            <div className="solution-about-brochures">
              <SolutionBlogsReports type="brochure" data={descriptiveSection.sectionReports} />
            </div>
          </>)}
          {descriptiveSection.sectionImages && descriptiveSection.sectionImages.length > 0 && (<>
            <div style={{paddingTop: '1rem'}}>
              <GeneralSlider items={descriptiveSection.sectionImages} show={2} section={'images'} />
            </div>
          </>)}
        </> : <>
          <div className="section-heading">
            <div className="academia-heading">
              { aboutLogo?.data?.attributes?.url && <img style={{ height: '62px'}} src={aboutLogo?.data?.attributes?.url} />}
              <div className="text">{category}</div>
            </div>
          </div>
          <div className="solution-about-body">
            <CustomMarkdown>{categoryContent}</CustomMarkdown>
          </div>
          {solution?.aboutSectionImages && solution?.aboutSectionImages?.length > 0 && 
              <GeneralSlider items={solution?.aboutSectionImages} show={2} section={'images'} />
          }
        </>}
      </>
    }
    {innovationType !== 'Academia' && <>
      {descriptiveSection ? <>
        <div className="section-heading">
            <div className="academia-subheading">
              {descriptiveSection?.sectionImage?.data?.attributes?.url && <img style={{width: '78px', height: '52px'}} src={descriptiveSection?.sectionImage?.data?.attributes?.url} />}
              <div className="text">{descriptiveSection?.sectionHeader}</div>
            </div>
          </div>
          <div className="solution-about-body">
            <CustomMarkdown>{descriptiveSection.sectionDescription}</CustomMarkdown>
          </div>
          {descriptiveSection?.sectionImages && descriptiveSection?.sectionImages.length > 0 && (<>
            <div style={{paddingTop: '1rem'}}>
              <GeneralSlider items={descriptiveSection?.sectionImages} show={2} section={'images'} />
            </div>
          </>)}
      </> : <>
        {!hideHeading && (
          <div className="section-heading">
            {(innovationType === 'HDFC Tech Innovators' && ['2022', '2019'].includes(category) ? `HDFC Tech Innovators ${category}` : `${heading || category}`)}
          </div>
        )}
        <TruncateText>
          {categoryContent && <div className="solution-about-body">
            <CustomMarkdown>{categoryContent}</CustomMarkdown>
          </div>}
          {catAbout && <div className="solution-about-body">
            <CustomMarkdown>{catAbout}</CustomMarkdown>
          </div>}
        </TruncateText>
        <TruncateText>
          {keyConstrains && keyConstrains.length > 0 && (
          <TruncateText>
          <>
            <h4 className="about-subheading">Key Constraints / Barriers to Adoption</h4>
            <div className="solution-about-body">
              <CustomMarkdown>{keyConstrains}</CustomMarkdown>
            </div>
          </>
          </TruncateText>
          )}
        </TruncateText>
       
          {advantages && advantages.length > 0 && (
           <TruncateText>
          <>
            <h4 className="about-subheading">Advantages</h4>
            <div className="solution-about-body">
              <CustomMarkdown>{advantages}</CustomMarkdown>
            </div>
           
          </>
          </TruncateText>
          )}
        
        {solution?.aboutSectionImages && solution?.aboutSectionImages?.length > 0 && 
          <GeneralSlider items={solution?.aboutSectionImages} show={2} section={'images'} />
        }
      </>}
    </>}
  </>);
}

export default SolutionAbout;
