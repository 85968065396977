import React, { useState, useEffect } from "react";
import axios from "axios";
import "../innovationPage/innovation.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Breadcrumbs from "../../components/Breadcrumbs";
import TruncateText from "../../components/TruncateText";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useNavigate } from "react-router-dom";

import { getStrapiApis } from "../../getStrapiApis";
import NewLoginDialog from "../../accounts/NewLoginDialog";

// const API_BASE_URL = "https://strapi-hdfc.azurewebsites.net/api";
// const API_BASE_URL = "http://localhost:4000/cms-api";
const API_BASE_URL = getStrapiApis();


const Innovation = () => {

  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [redirection, setRedirection] = useState(false);
  const [inno, setInno] = useState(null);
  const [innovations, setInnovations] = useState([]);
  const [content, setcontent] = useState([]);
  const [techInnovators, setTechInnovators] = useState([]);
  const crumbs = [['Home', 'home'], ['Project Lifecycle']];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [contentResponse, innovationsResponse, companiesData] =
          await Promise.all([
            axios.get(`${API_BASE_URL}/innovative-solution?populate=*`),
            axios.get(
              `${API_BASE_URL}/prelogin-innovations?sort=imageId&populate=*`
            ),
            axios.get(
              `${API_BASE_URL}/top-tech-innovators-in-housing?populate=TopTechInnovators.companyLogo&populate=TopTechInnovators.videoFile`
            ),
          ]);
        setcontent(contentResponse.data?.data?.attributes?.content);
        setInnovations(innovationsResponse.data.data);
        setTechInnovators(companiesData?.data?.data?.attributes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  function makeLower(text) {
    if (!text?.length || !text.toLowerCase) return ''
    return text.toLowerCase().replaceAll(' ', '-').replaceAll(',', '');
}

  const handleClick = (item) => {
    navigate(`/project-lifecycle/${makeLower(item?.attributes?.imageTitle)}`)
  };

  return (
    <>
      <Header />
      <NewLoginDialog />
      <div className="pl-overview-container">
        <Breadcrumbs breadcrumbs={crumbs} inverted/>
        <div className="pl-overview-title">
          Project Lifecycle
        </div>
        <div className="pl-overview-body">
          <TruncateText>
            {content}
          </TruncateText>
        </div>
        <div className="pl-overview-subtitle">
          Segments of Project Lifecycle
        </div>
        <div className="pl-card-container">
          {innovations && innovations.filter(i => i?.attributes?.heading === 'Project Lifecycle' && !!i.attributes.imageId).map((item, i) => (
              <div key={i} className="pl-card" onClick={(_) => handleClick(item)}>
                <div className="pl-card-image" style={{backgroundImage: `url(${item.attributes?.image?.data?.attributes?.url})`}} alt={item?.attributes?.heading} />
                <div className="pl-card-info">
                  <div>
                    <div className="pl-card-title">{item?.attributes?.imageTitle}</div>
                    <div className="pl-card-body">{item?.attributes?.imageSubtitle}</div>
                  </div>
                  <div className="pl-card-arrow">
                    <ArrowOutwardIcon />
                  </div>
                </div>
              </div>
          ))}
          <div className="pl-card" />
        </div>
      </div>
      <div className="tech-innovators-bg">
        <div className="pl-overview-container">
          <div className="pl-overview-subtitle">
            {techInnovators?.heading}
          </div>
          <div className="pl-overview-body">
            {techInnovators?.description}
          </div>
        </div>
        <div className="tech-innovators-container">
          {techInnovators?.TopTechInnovators?.map((card, i) => (
            <div key={i} className="tech-innovator-card">
              <div className="tech-innovator-media">
                <div className="tech-innovator-logo" style={{backgroundImage: `url(${card.companyLogo?.data?.attributes?.url})`}} />
                <div className="tech-innovator-arrow">
                  <ArrowOutwardIcon />
                </div>
              </div>
              <div className="tech-innovator-info">
                <a target="_blank" href={"http://" + card?.CompanyUrl} className="tech-innovator-url">{card?.CompanyUrl?.replace("https://", "")}</a>
                <div className="tech-innovator-description">{card?.CompanyProfile}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Innovation;
