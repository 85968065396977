import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import arrow from "../assets/images/companiesUrl.svg";

// const styles = {
//   container: {
//     display: "flex",
//     position: "relative",
//     flexDirection: "row",
//     gap: "24px",
//     overflowX: "hidden",
//     flexWrap: "nowrap",
//     height: "100%",

//   },
//   subContainer: {
//     display: "flex",
//     flexDirection: "column",
//     padding: "16px",
//     gap: "12px",
//     width: "300px", /* Set a fixed width */
//     height: "200px", /* Set a fixed height */
//     background: "#F2F4F7",
//     borderRadius: "0px 30px",
//     boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
//   }

// };

function SolutionCompanies({ companies }) {
  const [targetUrl, setTargetUrl] = useState(null);
  const handleClick = (url) => {
    // return (<a href={url} target="_blank"></a>)
    setTargetUrl(url);
  };
  function stripUrl(url) {
    if (!url || !url.length) return '';
    url = url.replace('https://', '');
    url = url.replace('http://', '');
    return url;
  }

  useEffect(() => {
    if (targetUrl) {
      window.open(targetUrl, "_blank");
    }
  }, [targetUrl]);
  return (
    <>
      <div className="company-container">
        {companies &&
          companies.map((comp, i) => (<>
            <div key={i} className="company-card">
              <div className="company-card-header">
                <div className="company-card-name no-wrap">{comp.companyName}</div>
                <div className="company-card-arrow">
                  <img src={arrow} alt="arrow" />
                </div>
              </div>
              <div className="company-card-body">
                <a className="company-card-url no-wrap" target="_blank" href={comp.CompanyUrl || 'https://www.google.com'}>
                  {comp.CompanyUrl && comp.CompanyUrl.length ? stripUrl(comp.CompanyUrl) : 'www.company-website.com'}
                </a>
                <div className="company-contact">
                  <div className="company-phone">+91 12345 67890</div>
                  <div className="company-email">contact@company.com</div>
                </div>
                <div className="company-card-profile">{comp.CompanyProfile}</div>
              </div>
            </div>
            </>))}
      </div>
    </>
  );
}

export default SolutionCompanies;
