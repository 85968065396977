import React from 'react';
import { div, Typography, Button } from '@mui/material';
import arrow from '../assets/images/arrow-right.svg';
import InnovationScroll from './InnovationScroll';
import { useNavigate } from "react-router-dom";
import '../App.css';

function HomeInnovativeSolutions({innoData}) {
  const [headElement, ..._] = innoData;
  const {heading, subHeading} = headElement.attributes
  const navigate = useNavigate();
  return (
    <>
      <div className="home-innovativesols-container">
        <div className="home-innovativesols-header">
          <div className='home-innovativesols-heading gradient-header'>
            {heading}
          </div>
          <div className="viewall-button" onClick={() =>  navigate("/innovations")}>
            <span className='viewall-text'>View All</span>
            <img src={arrow} alt='view all innovative solutions' />
          </div>
        </div>
        <div className='home-innovativesols-subheading'>
          {subHeading}
        </div>
        
      </div>
      <div>
        {_?.length && <InnovationScroll innoSols={_} />}
      </div>
    </>
  )
}
  
export default HomeInnovativeSolutions