import React, { useState, useEffect, useRef } from "react";
import arrow from "../assets/images/arrow-right.svg";

function SolutionBlogsReports({type, data, sectionTitle = ''}) {
  data = !data ? [] : data.map((d) => {
    var obj = {action: 'Read'};
    if (type === 'report') {
      obj.source = '';
      obj.title = d.reportName  || '';
      obj.url = d.reportUrl;
    } else if (['blog', 'working_papers', 'published_reports'].includes(type)) {
      obj.source = d.authorName;
      obj.title = d.blogTitle  || '';
      obj.url = d.blogUrl;
    } else if (type === 'brochure') {
      obj.title = d.reportName || '';
      obj.url = d.reportUrl;
      obj.action = 'View Details';
      if(d.reportFile) {
        obj.url = d.reportFile?.data?.attributes?.url;
      }
    }
    
    return obj;
  });
  const sectionHeading = type === 'blog' ? 'Blogs' : 
                         type === 'working_papers' ? 'Working Papers' : 
                         type === 'published_reports' ? 'Published Reports' : 
                         type === 'report' ? 'Reports' : 
                         undefined;

  const handleClick = (url) => {
    window.open(url, "_blank");
  };

  return (<>
    <div>
      {sectionHeading && <div className="section-heading">
        {sectionHeading}
        <span>{sectionTitle}</span>
      </div>}
      <div className="blogs-reports-container">
        {data.map((d,i) => (
          <div key={i} className="blog-report-card" >
            <div>
            {d?.source && <div className="blog-report-author no-wrap" title={d?.source}>{d?.source || ' '}</div>}
              <div className="blog-report-title">{d?.title || 'Report Title'}</div>
            </div>
            <div className="blog-report-action" onClick={() => handleClick(d?.url)}>
              <span>{d?.action}</span> <img src={arrow} alt="read arrow" />
            </div>
          </div>
        ))}
      </div>
    </div>
  </>);
}

export default SolutionBlogsReports;
