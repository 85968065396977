import React, { useState } from "react";
import "../App.css";
import developerIc from "../assets/images/users.svg";
import supplierIc from "../assets/images/truck.svg";
import startupIc from "../assets/images/rocket.svg";
import coinstackIc from "../assets/images/coin-stack.svg";
import featuredArrow from "../assets/images/featured-arrow.svg";
import { useNavigate } from "react-router-dom";

function Personas() {
  const navigate = useNavigate();
  const personas = [
    {
      id: "developers",
      icon: developerIc,
      title: "Developers & Builders",
    },
    {
      id: "suppliers",
      icon: supplierIc,
      title: "Supply Ecosystem",
    },
    {
      id: "startups",
      icon: startupIc,
      title: "Startups & Innovators",
    },
    {
      id: "financiers",
      icon: coinstackIc,
      title: "Financiers & Corporates",
    },
  ];

  const handleClick = (persona) => {
    if (!localStorage.getItem('new_user')) return;
    let new_user = JSON.parse(localStorage.getItem('new_user'));
    new_user.persona = persona;
    localStorage.setItem('new_user', JSON.stringify(new_user));
    navigate('/home');
  }

  return (
    <>
      <div className="personas-container" >
        {personas.map((p, i) => (
            <div key={i} className="persona" onClick={_ => handleClick(p.id)}>
              <div className="featured-heading">
                <div className="featured-icon" >
                  <img src={p.icon} alt={p.title} className="featured-icon-img" />
                </div>
                <div className="featured-arrow">
                  <img src={featuredArrow} alt="open" />
                </div>
              </div>
              <div className="persona-text">
                <div className="persona-heading render-new-lines">
                  {p.title}
                </div>
              </div>
            </div>
        ))}
      </div>
    </>
  );
}

export default Personas;
