import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';
import featuredArrow from "../assets/images/featured-arrow.svg";
import LoginDialog from "../accounts/LoginDialog";

const AUTOPLAY_ON = true;
const InnovationScroll = ({innoSols}) => {
  const mobileModifier = window.innerWidth > 700 ? 1 : 0;
  const [timelineActive, setTimelineActive] = useState(mobileModifier);
  const [slideActive, setSlideActive] = useState(mobileModifier);
  const [isUserInteracting, setIsUserInteracting] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [redirection, setRedirection] = useState(false);
  const [inno, setInno] = useState(null);
  const [shouldSlide, setShouldSlide] = useState(false);

  let autoplayTimer;
  const sliderRef = useRef(null);
  const timelineRef = useRef(null);
  const solutions = innoSols !== null ? (
    innoSols
      .filter(a => a.attributes && a.attributes.heading && a.attributes.heading === 'Project Lifecycle')
      .sort((a, b) => a.attributes.imageId - b.attributes.imageId)
      .map((sol, i) => ({
        id: i, 
        title: sol.attributes?.subHeading,
        subtitle: sol.attributes?.imageSubtitle || '',
        imageUrl: sol.attributes?.image?.data?.attributes?.url,
        solutionData: sol
      }))
    ) : [];
    // const solutionsFraction = solutions.length ? 100 / (solutions.length) : 0;

  useEffect(() => {
    clearTimeout(autoplayTimer);
    if (AUTOPLAY_ON || isUserInteracting || !sliderRef) return;
    autoplayTimer = setTimeout(() => {
      if (sliderRef && sliderRef.current && sliderRef.current.slickPlay)
        sliderRef.current.slickPlay();
    }, 2000);
  }, [isUserInteracting]);

  const settings = {
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: AUTOPLAY_ON,
    autoplaySpeed: AUTOPLAY_ON ? 3000 : 0,
    beforeChange: (current, next) => {
      if (!isUserInteracting) setSlideActive(null);
      // let nextTI = document.getElementById(`timeline_${next}`);
      // if (nextTI) nextTI.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    },
    afterChange: (current) => {
      if (!isUserInteracting) {
        setTimelineActive((current + mobileModifier) % solutions.length);
        setSlideActive((current + mobileModifier) % solutions.length);
        if (shouldSlide) {
          let currentTI = document.getElementById(`timeline_${current}`);
          if (currentTI) currentTI.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
      }
    },
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const navigate = useNavigate();

  function makeLower(text) {
    if (!text?.length || !text.toLowerCase) return ''
    return text.toLowerCase().replaceAll(' ', '-').replaceAll(',', '');
}

  const handleClick = (e) => {
    navigate(`/project-lifecycle/${makeLower(e?.attributes?.imageTitle)}`);
  };

  const makeItemActive = (next) => {
    setIsUserInteracting(true);
    setTimelineActive(next);
    setSlideActive(next);
    sliderRef.current.slickGoTo(next);
    sliderRef.current.slickPause();
  }

  const handleHoverIn = (s) => {
    setTimelineActive(s.id);
    setSlideActive(s.id);
    setIsUserInteracting(true);
  };

  const handleHoverOut = (s) => {
    setTimelineActive(null);
    setSlideActive(null);
    setIsUserInteracting(false);
  };

  const checkIfTimelineIsInView = () => {
    if (timelineRef && timelineRef.current) {
      const boundingBox = timelineRef.current.getBoundingClientRect();
      const isInView = boundingBox.top >= 0 && boundingBox.bottom <= window.innerHeight;
      setShouldSlide(isInView);
    }
  }
  useEffect(() => {
    checkIfTimelineIsInView();

    const check = () => {checkIfTimelineIsInView()}
    window.addEventListener('resize', check);
    window.addEventListener('scroll', check);
    return () => {
      window.removeEventListener('resize', check);
      window.removeEventListener('scroll', check);
    }
  }, [])

  // Add class .focused to .slide to simulate css :hover state
  // console.log(innoSols, solutions);
  return (
    <>

      <div className='innovation-timeline' ref={timelineRef}>
        <div className="timeline-line" style={{backgroundImage: `linear-gradient(90deg, #475467 0%, #475467 ${0}%, #0000001a ${0}%, #0000001a 100%)`}}/>
        {solutions.map(s => (
          <div key={s.id} id={`timeline_${s.id}`} className={`timeline-item ${s.id === timelineActive ? 'active' : ''} ${s.id < timelineActive ? 'done' : ''}`} onClick={_ => makeItemActive(s.id)}>
            <div className='timeline-indicator'></div>
            <div className='timeline-label'>{s.title}</div>
          </div>
        ))}
      </div>
      <div className="innovation-slider">
        <Slider ref={sliderRef} {...settings} >
          {solutions.map(s => (
            <div 
              id={`slide_${s.id}`} 
              key={s.id} 
              className={`slide ${s.id === slideActive ? 'focused' : ''}`}
              onClick={_ => handleClick(s.solutionData)} 
              onMouseEnter={_ => handleHoverIn(s)} 
              onMouseLeave={_ => handleHoverOut(s)}
              onTouchStart={_ => handleHoverIn(s)} 
              onTouchEnd={_ => handleHoverOut(s)}
            >
              <div
                className="slide-bg-container"
                style={{
                  backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, ${
                    s.id === slideActive ? "0.6" : "0.3"
                  }) 75%), url(${s.imageUrl})`,
                }}
              ></div>
              <div className="slide-info-container">
                <div className="slide-info-header">
                  <div className="slide-info-title">{s.title}</div>
                  <div className="slide-info-arrow">
                    <img src={featuredArrow} alt="Open" />
                  </div>
                </div>
                <div className="slide-info-body">{s.subtitle}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default InnovationScroll;
