import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "./search.css";
import Breadcrumbs from "../components/Breadcrumbs";
function Search() {
  const breadcrumbs = [["Home", "home"], ["Search"]];
  const script = document.createElement("script");
  script.src = "https://cse.google.com/cse.js?cx=155f74cccc99b4234";
  script.async = true;
  document.body.appendChild(script);
  return (
    <div>
      <Header />
      <div
        className="search-container"
      >
        <Breadcrumbs breadcrumbs={breadcrumbs} inverted />
        <div className="search-title">
          Search Results
        </div>
        <div
          id="my-search"
          className="gcse-search"
        ></div>
      </div>
      <Footer />
    </div>
  );
}
export default Search;