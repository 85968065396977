import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getApiUrl } from "../../getApiUrl";





export const financialRegistrationForDeveloperSelfFunding = createAsyncThunk(
  "startups/financialRegistrationForDeveloperSelfFunding",
  async (
    { formValues, accessToken, gettingLoginNums },
    { rejectWithValue }
  ) => {
    const {
      contactPersonSelf,
      developerGroupSelfSelf,
      websiteURLSelf,
      devPresenceInStateSelf,
      fundingAmountSelf,
      resDeliveryExpSelf,
    } = formValues;

    const processedPhoneNumber1 = gettingLoginNums.replace(/^(\+91)/, "");

  
    const apiUrl = getApiUrl();
    try {
      const url = `${apiUrl}/services/apexrest/financialregistrationForSelfFunding`;
      const paramData = {
        data: {
          developerGroup:developerGroupSelfSelf,
          contactPerson: contactPersonSelf,
          mobileNumberOfContact: processedPhoneNumber1,
          websiteURL:websiteURLSelf,
          devPresenceInState:devPresenceInStateSelf,
          resDeliveryExp:resDeliveryExpSelf,
          fundingAmount:fundingAmountSelf,
          financeType:"Funding for Self",
          userName: JSON.parse(localStorage.getItem("new_user")).name
        },
      };
   
      console.log('paramData',paramData);
    
      const apiResp = await axios.post(url, JSON.stringify(paramData), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return apiResp;
     
    } catch (error) {

      return rejectWithValue(error.response.data);
    }
  }
);

const apiSlice = createSlice({
  name: "developerSelfFunding",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
  
    builder.addCase(financialRegistrationForDeveloperSelfFunding.pending, (state) => {
      // Handle pending state
   
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      financialRegistrationForDeveloperSelfFunding.fulfilled,
      (state, action) => {
      
        // Handle success state
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(
      financialRegistrationForDeveloperSelfFunding.rejected,
      (state, action) => {
   
        // Handle error state
        state.loading = false;
        state.error = action.payload;
      }
    );
  },
});

export default apiSlice.reducer;
