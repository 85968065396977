import React, {useState, useEffect} from 'react'
import axios from "axios";
import Header from '../components/Header';
import Footer from '../components/Footer';
import './About.css';
import Rect1 from '../assets/images/temp/Rectangle1.png';
import Rect2 from '../assets/images/temp/Rectangle2.png';
import Rect3 from '../assets/images/temp/Rectangle3.png';
import Rect4 from '../assets/images/temp/Rectangle4.png';
import Rect5 from '../assets/images/temp/Rectangle5.png';
import Rect6 from '../assets/images/temp/Rectangle6.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate, useLocation } from "react-router-dom";
import CountUp from 'react-countup';

import { getStrapiApis } from '../getStrapiApis';
import NewLoginDialog from "../accounts/NewLoginDialog";
import ReactGA from 'react-ga';

// const API_BASE_URL = "https://strapi-hdfc.azurewebsites.net/api";
// const API_BASE_URL = "http://localhost:4000/cms-api";
const API_BASE_URL = getStrapiApis();
function About() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
	const navigate = useNavigate();
  const [content, setContent] = useState(null)
  const [quantityBlock, setQuantityBlock] = useState({})
	const infoBlocks = [
		{id: 1, pretext: '', num: 1.6 ,text: ' Billion +', subText: 'People are expected to be affected by global housing shortage'},
		{id: 2, pretext: '', num: 10 ,text: ' Million', subText: 'Estimated current shortage of housing in urban areas'},
		{id: 3, pretext: '', num: 25 ,text: ' Million', subText: 'Required by 2030 to meet the urban population growth in the country'},
		{id: 4, pretext: 'US$ ', num: 5.8 ,text: ' Trillion', subText: 'Expected growth of India’s real estate sector by 2047'},
		{id: 5, pretext: '', num: 50 ,text: ' Thousand +', subText: 'Estimated number of developers in India'},
		{id: 6, pretext: '', num: 10 ,text: ' Million +', subText: 'Estimated number of real estate vendors in India'}
	]

	const challenges = [
		{id: 0, image: Rect3, text: 'Inefficiencies in supply chain'},
		{id: 1, image: Rect4, text: 'Low tech adoption'},
		{id: 2, image: Rect5, text: 'Opaque systems and process'},
		{id: 3, image: Rect6, text: 'Lack of uniform data availability'},
		{id: 4, image: Rect1, text: 'Limited access to innovative fintech solutions'},
		{id: 5, image: Rect2, text: 'Credibility issues'},
	]
	
	const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

	const handleClick = (id) => {
      if (localStorage?.accessToken) {
        navigate(`/${id}`);
      } else {
        navigate('/')
      }
	}

  useEffect(()=> {
    const fetchData = async () => {
      try {
        const [aboutContent] = await Promise.all([
          axios.get(
            `${API_BASE_URL}/about-bleu?populate[quantityParams][populate]=*&populate[imageText][populate]=*`
          ),
        ]);
        setContent(aboutContent.data.data);
        if (aboutContent.data.data.attributes?.quantityParams?.length>0) {
          const res={}
          await aboutContent.data.data.attributes.quantityParams.map((qp) => {
            res[qp.id] = breakWord(qp.paramValue)
          })
          setQuantityBlock(res)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [])
  
  const formattedText = (textWithNewlines) => {
    return { __html: textWithNewlines.replace(/\n/g, '<br /> <br />') };
  };

  const breakWord = (input) => {
    const regex = /^([A-Za-z$]+)?\s*([0-9.]+)\s*([A-Za-z]+[+]?)?\s*(.*)$/;

    const match = input.match(regex);

    if (!match) {
      return null;
    }

    const [, prefix, digits, suffix, last] = match;

    const parsedDigits = digits.includes('.') ? parseFloat(digits) : parseInt(digits, 10);

    const result = {
      prefix: prefix || '',
      digits: parsedDigits,
      suffix: suffix || '',
      last: last || '',
    };
    // setQuantityBlock(result)
    return result;
  }

  return (
    <div>
        <Header />
        <NewLoginDialog />
        
        {content!==null && 
          <div className='about-container' style={{display: 'flex', flexDirection: 'column', maxWidth: '1200px', margin: '0 auto'}}>
            <div className='nav-text padding-sides flex-direction-row' style={{paddingBottom: '1.5rem'}}>
                <p className='text-margin'><a onClick={()=>handleClick('home')}>Home /&nbsp;</a></p> <p className='text-margin' style={{color: 'var(--gray-500, #667085)'}}> About BLEU Circle </p>
            </div>
            <div className='padding-sides flex-direction-column' style={{paddingBottom: '2.5rem'}}>
                <div style={{paddingBottom: '0.5rem'}}>
                    <p className='about-bleu-text text-margin'>{content?.attributes?.heading}</p>
                </div>
                <div>
                    <p className='bleu-desc-text text-margin'>
                      <div dangerouslySetInnerHTML={formattedText(content?.attributes.content)}>
                      </div>
                    </p>
                </div>
            </div>

            <div className='flex-direction-row padding-sides' style={{gap:'1.5rem', flexWrap: 'wrap'}}>
								{ content?.attributes?.quantityParams?.length>0 &&
									content.attributes.quantityParams
                  .map((ib)=>(
										<div key={ib.id} className='flex-direction-column info-blocks' style={{padding: '6rem 1.5rem 1.5rem 1.5rem'}} >
											<div>
                        <div className='info-text text-margin'>
                          {quantityBlock[ib.id].prefix}&nbsp;
                          <CountUp end={quantityBlock[ib.id].digits} duration={5} decimals={quantityBlock[ib.id].digits%1 === 0 ? 0 : 1} />
                          &nbsp;{quantityBlock[ib.id].suffix}
                          &nbsp;{quantityBlock[ib.id].last}
                        </div>
                        <div>
                          <hr className='info-hr' />
                          <p className='info-subText text-margin'>
                            {ib.paramText}
                          </p>
                        </div>
                      </div>
										</div>
									))
								}
            </div>

            <div className='' style={{maxWidth: '1200px', margin: '0 auto', width: '100%', padding: '3.75rem 1rem'}}>
								<div style={{paddingBottom: '1.5rem'}}>
									<p className='challenges text-margin'>Challenges</p>
								</div>
								<div >
									<div className='challenge-slide-container'>
											{ content?.attributes?.imageText?.length>0 &&
                      content.attributes.imageText.map((ch) =>(
												<div key={ch.id} className='challenge' style={{backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.5) 100%), url(${ch?.imageFile?.data?.attributes?.url})`}}>
                          <div className='challenge-text'>
                            {ch.imageTitle}
                          </div>
												</div>
											))}
									</div>
								</div>
            </div>

            <div className='flex-direction-row padding-sides' style={{paddingTop: '2.59rem'}}>
							<p className='bleu-desc-text text-margin'>
              {content?.attributes?.footerText}
							</p>
            </div>
        </div>}
        <Footer />
    </div>
  )
}

export default About;