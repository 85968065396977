import React, { useState, useEffect } from "react";
import axios from "axios";
import "../App.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import SolutionNews from "../components/SolutionNews";

import GeneralSlider from "../components/GeneralSlider";
import { getStrapiApis } from "../getStrapiApis";
import NewLoginDialog from "../accounts/NewLoginDialog";
const API_BASE_URL = getStrapiApis();
function ResearchReports({ action }) {
  const [heroTexts, setHeroTexts] = useState(null);
  const crumbs = {
    "research-report": [
      ["Home", "home"],
      ["Research Reports", "research-reports"],
    ],
    "case-study": [
      ["Home", "home"],
      ["Case Studies", "case-studies"],
    ],
  };
  const breadcrumbs = crumbs[action];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [heroTextsResponse] = await Promise.all([
          axios.get(
            `${API_BASE_URL}/${action}?populate=Reportssection.sectionReports.reportFile&populate=Reportssection.sectionReports.coverImage&populate=Reportssection.sectionImage&populate=headerImage&populate=NewsArticle`
          ),
        ]);

        setHeroTexts(heroTextsResponse.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [action]);

  return (
    <div className="prelogin">
      <div className="hero">
        <Header />
      </div>

      <NewLoginDialog />
      {heroTexts && (
        <Banner
          url={heroTexts.attributes?.headerImage?.data?.attributes?.url}
          heading={heroTexts.attributes.heading}
          subheading={heroTexts.attributes.contentOnTop}
          breadcrumbs={breadcrumbs}
        />
      )}
      <div className="rr-overview-container">
        { 
          action === 'research-report' && heroTexts?.attributes?.Reportssection?.length > 0 &&
          heroTexts.attributes.Reportssection.map((item, i)=>{
            if (item?.sectionHeader === 'ESG Reports') return; // hiding this for now
            return (
              <div key={i} >
                <div className="rrreports-heading">{item?.sectionHeader}</div>
                <GeneralSlider items={item.sectionReports} show={3} section={'reports'}/>
              </div>
            )
          })
        }
        {heroTexts?.attributes?.NewsArticle?.length > 0 && (
          <SolutionNews
            data={heroTexts?.attributes?.NewsArticle?.slice(0, 3)}

            sectionHeader="Relata"
          />
        )}
        {heroTexts?.attributes?.NewsArticle?.length > 0 && (
          <SolutionNews
            data={heroTexts?.attributes?.NewsArticle?.slice(3, 9)}

            sectionHeader="WeGot"
          />
        )}
        {heroTexts?.attributes?.NewsArticle?.length > 0 && (
          <SolutionNews
            data={heroTexts?.attributes?.NewsArticle?.slice(9, 11)}

            sectionHeader="Reloy"
          />
        )}
      </div>
      <Footer />
    </div>
  );
}

export default ResearchReports;
