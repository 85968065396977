import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import SolutionAbout from "../components/SolutionAbout";
import SolutionBlogsReports from "../components/SolutionBlogsReports";
import SolutionCompanies from "../components/SolutionCompanies";
import "../styles/solution.css";
import Banner from "../components/Banner";

import Sidebar from "../components/Sidebar";
import SolutionSubcategories from "../components/SolutionSubcategories";
import GeneralSlider from "../components/GeneralSlider";
import { getStrapiApis } from "../getStrapiApis";
import SolutionTechInnovators from "../components/SolutionTechInnovators"
import SolutionNews from "../components/SolutionNews";
import NewLoginDialog from "../accounts/NewLoginDialog";
import SolutionInfographics from "../components/SolutionInfographics";
import L5 from "../components/L5";

// const API_BASE_URL = "https://strapi-hdfc.azurewebsites.net/api"
const API_BASE_URL = getStrapiApis();

const FILTER_URL = "/innovation-types?populate=aboutSectionImages.image&populate=aboutLogo&populate=workingPapers&populate=programList.programImage&populate=subSection1.sectionImage&populate=subSection1.sectionReports.reportFile&populate=sectionWithImages.sectionImages.image&populate=publishReports&populate=youtubeVideos&populate=ExibitEntries.image&populate=NewsArticle.newsLink&populate=trending.reportURL&populate=referenceReports.reportUrl&populate=referenceReports.reportFile&populate=blogs.blogUrl&populate=verticalInfographics.url&populate=verticalInfographics2.url&populate=l5Data.verticalInfographics.url&populate=l5Data.verticalInfographics2.url&populate=l5Data.youtubeVideos&populate=l5Data.ExibitEntries.image&populate=l5Data.NewsArticle.newsLink&populate=l5Data.trending.reportURL&populate=l5Data.referenceReports.reportUrl&populate=l5Data.referenceReports.reportFile&populate=l5Data.blogs.blogUrl&populate=sectionWithImages.sectionImages&[filters][innovationType][$eq]=";
const ACADEMIA_URL =  "/innovation-types?populate=aboutSectionImages.image&populate=aboutLogo&populate=workingPapers&populate=programList.programImage&populate=subSection1.sectionImage&populate=subSection1.sectionReports.reportFile&populate=sectionWithImages.sectionImages.image&populate=publishReports&populate=youtubeVideos&populate=ExibitEntries.image&[filters][innovationType][$eq]=Academia&[filters][category][$eq]="

function Solution() {
  const location = useLocation();
  const navigate = useNavigate();
  // const { attributes, headerSelectedCategory, headerSelectedSubCategoryName2, l5Name } = location.state;
  const attributes = location?.state?.attributes;
  const headerSelectedCategory = location?.state?.headerSelectedCategory;
  const headerSelectedSubCategoryName2 = location?.state?.headerSelectedSubCategoryName2;
  const l5Name = location?.state?.l5Name;
  const [categories, setCategories] = useState(null);
  const [subCategories, setSubCategories] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [solution, setSolution] = useState(null);
  const [techInnovators, setTechInnovators] = useState([]);

  let sideBarHeading = 'Popular Categories';
  let breadcrumbs = [['Home', 'home'], ['Project Lifecycle', 'innovations'], [attributes?.imageTitle]];
  if (attributes?.heading === 'Academia') {
    sideBarHeading = 'Academic Institutions';
    breadcrumbs = [['Home', 'home'], [attributes?.imageTitle]];
  }
  if (attributes?.heading === 'HDFC Tech Innovators') {
    sideBarHeading = 'Conferences';
    breadcrumbs = [['Home', 'home'], [attributes?.imageTitle]];
  }

  const getFirstSubCategory2 = async (subCategory2, subCategoryName) => {
    if (subCategory2 && subCategory2?.length) {
      setSubCategories(subCategory2);
      const { subCategoryId2, subCategoryName2 } = subCategory2[0];
      setSelectedSubCategory({ subCategoryId2, subCategoryName2 });

      const solutionData = await axios.get(`${API_BASE_URL}${FILTER_URL}${attributes?.imageTitle}&[filters][category][$eq]=${subCategoryName}&[filters][subCategoryName2][$eq]=${subCategoryName2}`);
      if (solutionData) setSolution(solutionData?.data?.data[0]?.attributes);
      else setSolution(null);
    } else {
      setSubCategories(null);
      setSelectedSubCategory(null);
      const solutionData = await axios.get(`${API_BASE_URL}${FILTER_URL}${attributes?.imageTitle}&[filters][category][$eq]=${subCategoryName}`);
      if (solutionData) setSolution(solutionData?.data?.data[0]?.attributes);
      else setSolution(null);
    }
  };
  const getSubCartegory = async (sub, subCategoryName) => {
    if (subCategoryName) {
      const solutionData = await axios.get(`${API_BASE_URL}${FILTER_URL}${attributes?.imageTitle}&[filters][category][$eq]=${subCategoryName}&[filters][subCategoryName2][$eq]=${sub.subCategoryName2}`);
      console.log({event: 'Subcategory selected', params:{sub, subCategoryName}, data: solutionData?.data?.data[0]?.attributes});
      if (solutionData) setSolution(solutionData?.data?.data[0]?.attributes);
      else setSolution(null);
    } else if (selectedCategory?.subCategoryName) {
      const solutionData = await axios.get(`${API_BASE_URL}${FILTER_URL}${attributes?.imageTitle}&[filters][category][$eq]=${selectedCategory.subCategoryName}&[filters][subCategoryName2][$eq]=${sub.subCategoryName2}`);
      console.log({event: 'Subcategory selected', params: {category: selectedCategory?.subCategoryName, sub: sub.subCategoryName2}, data: solutionData?.data?.data[0]?.attributes});
      if (solutionData) setSolution(solutionData?.data?.data[0]?.attributes);
      else setSolution(null);
    }
  };

  const fetchCategories = async () => {
    try {
      let categoriesData = await axios.get(`${API_BASE_URL}/innovation-sub-categories?populate=subCategory.subCategory2&[filters][categoryName][$eq]=${attributes?.imageTitle}`);
      categoriesData = await categoriesData.data?.data[0]?.attributes?.subCategory.sort((a, b) => a.subCategoryID - b.subCategoryID);
      setCategories(categoriesData);
      return categoriesData;
    } catch (error) {
      console.error("Error fetching Categories:", error);
    }
  };

  const setSubCategories2 = async ({subCategoryName, subCategoryID, subCategory2}) => {
    setSelectedCategory((prevObj) => ({
      ...prevObj,
      subCategoryID: subCategoryID,
      subCategoryName: subCategoryName,
    }));
    setSubCategories(subCategory2);
    const [filteredSubCategoryName2] = await subCategory2.filter((cat) => cat.subCategoryName2 === headerSelectedSubCategoryName2);
    setSelectedSubCategory((prevObj) => ({
      ...prevObj,
      subCategoryId2: filteredSubCategoryName2?.subCategoryId2,
      subCategoryName2: filteredSubCategoryName2?.subCategoryName2,
    }));
    await getSubCartegory(filteredSubCategoryName2, subCategoryName);
  };

  const fetchSolutionData = async (categories) => {
    try {
      if (categories && categories?.length) {
        if (headerSelectedCategory) {
          const [filteredCategory] = await categories.filter((cat) => cat.subCategoryName === headerSelectedCategory);
          const { subCategoryName, subCategoryID, subCategory2 } = filteredCategory;
            
          if (headerSelectedSubCategoryName2) {
            await setSubCategories2({ subCategoryName, subCategoryID, subCategory2});
          } else {
            setSelectedCategory({ subCategoryID, subCategoryName });
            await getFirstSubCategory2(subCategory2, subCategoryName);
          }
        } else {
          const { subCategoryName, subCategoryID, subCategory2 } = categories[0];
          setSelectedCategory({ subCategoryID, subCategoryName });
          await getFirstSubCategory2(subCategory2, subCategoryName);
        }
      }
    } catch (error) {
      console.error("Error fetching Solution Data:", error);
    }
  };

  const fetchAcademiaData = async (cats) => {
    try {
      let {subCategoryName, subCategoryID} = cats[0];
      setSelectedCategory({subCategoryID, subCategoryName});
      const academiaData = await axios.get(`${API_BASE_URL}${ACADEMIA_URL}${subCategoryName}`);
      console.log(academiaData?.data?.data[0]?.attributes);
      if (academiaData) {
        setSolution(academiaData?.data?.data[0]?.attributes);
      } else {
        setSolution(null);
      }

    } catch (error) {
      console.error('Error fetching Academia Data:', error);
    }
  }

  const getAboutHeading = (selected) => {
    if (selectedSubCategory?.subCategoryName2) {
      if (selectedSubCategory?.subCategoryName2 === 'Overview') {
        return selectedCategory.subCategoryName
      } else {
        return selectedSubCategory?.subCategoryName2;
      }
    }
    if (selectedCategory?.subCategoryName) {
      if (selectedCategory.subCategoryName === 'Overview') {
        return attributes?.imageTitle;
      } else {
        return selectedCategory.subCategoryName
      }
    }
    return selected;
  }

  useEffect(() => {
    if (!attributes) {
      return navigate('/home');
    }
    
    const fetchData = async () => {
      try {
        const response = await fetchCategories();
        if (attributes && attributes?.heading === 'Academia') {
          await fetchAcademiaData(response);
        } else {
          await fetchSolutionData(response);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [location.state]);

  const handleSelectCategory = async (cat) => {
    console.log({event: 'Category Selected', cat, categories});
    setSelectedCategory((prevObj) => ({
      ...prevObj,
      subCategoryID: cat.subCategoryID,
      subCategoryName: cat.subCategoryName,
    }));
    if (attributes && attributes?.heading === 'Academia') {
      await fetchAcademiaData([cat]);
    } else {
      await getFirstSubCategory2(cat.subCategory2, cat.subCategoryName);
    }
  };

  const handleSelectSubCategory = async (sub) => {
    setSelectedSubCategory((prevObj) => ({
      ...prevObj,
      subCategoryId2: sub?.subCategoryId2,
      subCategoryName2: sub?.subCategoryName2,
    }));
    await getSubCartegory(sub);
  };

  useEffect(() => {
    const fetchTech = async () => {
      try {
        const [companiesData] = await Promise.all([
          axios.get(
            `${API_BASE_URL}/top-tech-innovators-in-housing?populate=TopTechInnovators.companyLogo&populate=TopTechInnovators.videoFile`
          )
        ])
        setTechInnovators(companiesData?.data?.data?.attributes);
      } catch (error) {
        console.error(error)
      }
    }

    fetchTech();
  }, []);

  const [isSidebarOpened, setIsSidebarOpened] = useState(false);
  function sidebarCallback(open) {
    setIsSidebarOpened(open);
  }

  const heading = attributes?.imageTitle;
  const subheading = solution?.typeContentOnTop;
  const url = attributes?.image?.data?.attributes?.url;
  return (
    <div>
      <Header />
      
      <NewLoginDialog />
      <>
        <Banner heading={heading} subheading={subheading} url={url} breadcrumbs={breadcrumbs} />

        <div className="solutions-container">
          <div className="left-column">
            <Sidebar toggleCallback={sidebarCallback} action={handleSelectCategory} title={sideBarHeading} items={categories} selected={selectedCategory?.subCategoryID} />            
          </div>

          <div className={`right-column ${isSidebarOpened ? 'faded' : ''}`}>
            {solution && (
              <>
                <SolutionSubcategories items={subCategories} action={handleSelectSubCategory} selected={selectedSubCategory?.subCategoryId2} />

                <div className="right-column-content">
                  <SolutionAbout solution={solution} heading={getAboutHeading(selectedSubCategory?.subCategoryName2)} description={0} />
                </div>

                {solution.verticalInfographics && solution.verticalInfographics.length > 0 && solution.verticalInfographics.map((vi, i) => (
                  <SolutionInfographics infographic={vi} key={i} />
                ))}

                {solution.subSection1 && solution.subSection1.length > 0 && solution.subSection1.map((s, i) => (
                  <div key={i} className="right-column-content">
                      <SolutionAbout solution={solution} heading={selectedSubCategory?.subCategoryName2} subSectionNumber={i} />
                  </div>
                  ))
                }

                {solution.l5Data && solution.l5Data.length > 0 && (
                  <L5 data={solution.l5Data} selected={l5Name} />
                )}
                
                {solution.innovationType === 'HDFC Tech Innovators' && solution.youtubeVideos?.length > 0 && (<>
                  <div className="section-heading">Videos</div>
                  <GeneralSlider items={solution?.youtubeVideos} show={1} section={'videos'}/>
                </>)}

                {false && solution?.AssociatedCompanies && (<>
                  <div className="section-heading">Innovative Companies</div>
                  <SolutionCompanies companies={solution?.AssociatedCompanies} />
                </>)}            

                {solution.innovationType === 'Academia' && solution?.programList?.length > 0 && (<>
                  {
                    solution?.programListTitle && solution?.programListTitle.length > 0 ? 
                      <div className="esg-exhibit">{solution?.programListTitle}</div> :
                      <div className="section-heading">Projects</div>
                  }
                  <GeneralSlider items={solution.programList} show={1} section={'projects'}/>
                </>)}

                {solution.ExibitEntries?.length > 0 && (<>
                  {solution.innovationType === 'HDFC Tech Innovators' && <div className="section-heading">Conference Showcase</div> }
                  <GeneralSlider items={solution.ExibitEntries} show={2} section={'exhibits'}/>
                </>)}
              
                       

                {solution.verticalInfographics2 && solution.verticalInfographics2.length > 0 && solution.verticalInfographics2.map((vi, i) => (<>
                  <SolutionInfographics infographic={vi} key={i} />
                </>))}

                {solution.innovationType !== 'HDFC Tech Innovators' && solution.youtubeVideos?.length > 0 && (<>
                  <div className="section-heading">Videos</div>
                  <GeneralSlider items={solution?.youtubeVideos} show={1} section={'videos'}/>
                </>)}   

                {solution.referenceReports?.length > 0 && solution.innovationType !== 'HDFC Tech Innovators' && (
                  <SolutionBlogsReports type="report" data={solution?.referenceReports} />
                )}
              
                {solution?.NewsArticle?.length > 0 && (
                  <SolutionNews data={solution?.NewsArticle} />
                )}

                {solution.blogs?.length > 0 && (
                  <SolutionBlogsReports type="blog" data={solution?.blogs} />
                )}

                {solution.innovationType === 'Academia' && solution?.publishReports?.length > 0 && (<>
                  <SolutionBlogsReports data={solution.publishReports} type={'published_reports'}/>
                </>)}

                {solution.innovationType === 'Academia' && solution?.workingPapers?.length > 0 && (<>
                  <SolutionBlogsReports data={solution.workingPapers} type={'working_papers'}/>
                </>)}

                {solution.sectionWithImages && solution.sectionWithImages.length > 0 && solution.sectionWithImages.map((s, i) => (
                  <div key={i} className="right-column-content">
                      <SolutionAbout solution={solution} subCategory={selectedSubCategory?.subCategoryName2} imageSectionNumber={i} />
                  </div>
                  ))
                }
                {
                  solution.innovationType === 'HDFC Tech Innovators' && techInnovators && selectedCategory.subCategoryName ==="2022" &&
                  <>
                    <div className="section-heading">{techInnovators?.heading}</div>
                    {techInnovators && <SolutionTechInnovators techInnovators={techInnovators} /> }
                  </>
                }
              </>
              )}
          </div>
        </div>
      </>
      <Footer />
    </div>
  );
}

export default Solution;
