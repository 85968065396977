import React, { useEffect, useRef, useState } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../styles/header.css';
import { Drawer } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarBrand, NavItem, NavLink, UncontrolledDropdown} from 'reactstrap';
import { headerItems } from "./HeaderItems";
import ScrollToTop from '../components/ScrollToTop';
import close from '../assets/images/close.svg';
import right from '../assets/images/chevron-right.svg';
import left from '../assets/images/chevron-left.svg';
import down from '../assets/images/chevron-down-blue.svg';
import logo from '../assets/images/BLEU Circle_Updated_Logo.png';
import hamburger from '../assets/images/menu-hamburger.svg';
import {isLoggedin, setStatus} from "../redux/slice/newLoginSlice";

function shouldShowMenuItem(item) {
  if (!item) return true;
  if (item.loggedInOnly && !localStorage.accessToken) return false;
  if (item.loggedOutOnly && localStorage.accessToken) return false;
  return true;
}

const MobileDrawer = ({item, handleMenuClick, level, menuItems, children}) => {
  if (!shouldShowMenuItem(item)) return '';
  // console.log(menuItems);
  if (!item.children?.length) return;
  return <>
    <div className={`nav-column nav-level-${level} ${menuItems.includes(item.id) ? 'show' : ''}`}>
      <div className="nav-item back-nav-item" onClick={_ => handleMenuClick(item)}>
        <div className="nav-item-left-icon">
          <img src={left} alt="Left icon" />
        </div>
        <div className="nav-label">{item.label}</div>
        <div className="nav-item-right-icon" />
      </div>
      {item.children?.map((child, i) => 
        <div key={i} className="nav-item" onClick={_ => handleMenuClick(child)}>
          <div className="nav-item-left-icon" />
          <div className="nav-label" data-nosnippet="true">{child.label}</div>
          <div className="nav-item-right-icon">
            {child.children?.length && <img src={right} alt="Right icon" />}
          </div>
        </div>
      )}
      {children}
    </div>
  </>
}

const Header = ({toggleTransparency}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [isTransparent, setIsTransparent] = useState(toggleTransparency && window.scrollY === 0);
  const [overlayListenerAdded, setOverlayListenerAdded] = useState(false);
  const drawerRef = useRef();
  const overlayRef = useRef();
  
  let username = JSON.parse(localStorage.getItem('new_user'))?.name;
  let loggedinUser = useSelector((state) => state?.login?.loggedIn);

  function getFirstLetters(str) {
    if (!str || !str.length) return 'AM';
    if (str.indexOf('_') > -1) return str.toUpperCase().split('_').slice(0, 2).map((word) => word.charAt(0))?.join('');
    return str.toUpperCase().substring(0, 2);
  }
  
  // listen for changes to localstorage (login/logout)
  useEffect(() => {
    const storageAction = (event) => {
      if (localStorage.new_user) {
        try {
          dispatch(isLoggedin(true));
          username = JSON.parse(localStorage.getItem('new_user'))?.name;
        } catch (e) {}
      }
    }
    storageAction();
    window.addEventListener('storage', storageAction);
    return () => window.removeEventListener('storage', storageAction);
  }, []) 

  // set transparency of navbar using a scroll listener
  useEffect(() => {
    if (!toggleTransparency) return;
    const handleScroll = () => {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      setIsTransparent(scrollY === 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  function findChildAndParents(targetId) {
    let result = {};

    function search(node, targetId, parents = []) {
        if (node.id === targetId) {
            result.item = { ...node }; // Copy the child node to exclude original children key
            result.parents = parents.map(parent => ({ ...parent, children: undefined }));
            return;
        }

        if (node.children) {
            for (let child of node.children) {
                search(child, targetId, [...parents, { ...node, children: undefined }]);
            }
        }
    }

    for (let node of headerItems) {
        search(node, targetId);
    }

    return result;
  }

  const handleLogout = () => {
    setIsMobileMenuOpen(false);
    localStorage.removeItem('new_user');
    dispatch(setStatus(null));
    dispatch(isLoggedin(false));
    navigate('/', { replace: true });
  };

  // close all mobile submenus when hamburger closed
  useEffect(() => {
    if (isMobileMenuOpen) return;
    setMenuItems([]);

  }, [isMobileMenuOpen]);

  function handleMenuClick(item) {
    if (!item?.id?.length) return
    if (item.path && !item.children?.length) {
      navigate(item.path, {state: {nav_id: Math.random()}});
      setIsMobileMenuOpen(false);
      return setMenuItems([]);
    }

    const menuItem = findChildAndParents(item.id);
    if (!menuItem?.item?.id) return;

    // capture and pass any L5+ Names to navigation state
    if (!item.children && (item.L5Name || item.L6Name || item.L7Name || item.L8Name || item.L9Name || item.L10Name)) {
      let navObj = {nav_id: Math.random()}
      menuItem.parents.forEach(ancestor => navObj = Object.assign(navObj, ancestor));
      navObj = Object.assign(navObj, menuItem.item);
      let path = navObj.path;
      ['children', 'label', 'id', 'path'].forEach(key => delete navObj[key]);
      navigate(path, {state: navObj});
      setIsMobileMenuOpen(false);
      return setMenuItems([]);
    }

    // open next level of menu, or close if already opened
    if (menuItems.includes(menuItem.item?.id)) {
      let next = menuItems.slice(0, menuItems.indexOf(menuItem.item.id))
      setMenuItems(next);
    } else {
      let next = menuItem.parents?.map(p => p.id) || [];
      next.push(menuItem.item.id);
      setMenuItems(next);
    }
  }

  function scrollToRight() {
    const container = document.getElementById('nav_drawer'); // Change 'yourContainerId' to the actual ID of your container
    if (container) {
      container.scrollLeft = container.scrollWidth;
    }
  }

  useEffect(() => {
    scrollToRight();

    const handleOverlayClick = (e) => {
      if (!e.target || !e.target?.classList?.length) return;
      if (e.target.classList.contains('navigation-desktop-drawer') || e.target.classList.contains('navigation-drawer-content')) {
        setMenuItems([])
      }
    }

    if (menuItems?.length) {
      if (!overlayListenerAdded) {
        overlayRef?.current?.addEventListener('click', handleOverlayClick);
        setOverlayListenerAdded(true);
      }
    } else {
      overlayRef?.current?.removeEventListener('click', handleOverlayClick);
      setOverlayListenerAdded(false);
    }
  }, [menuItems]);

  const [marginLeft, setMarginLeft] = useState(0);
  useEffect(() => {
    const updateMarginLeft = () => {
      const viewportWidth = window.innerWidth;
      const newMarginLeft = viewportWidth >= 1200 ? (viewportWidth - 1200) / 2 : 0;
      setMarginLeft(newMarginLeft);
    };
    window.addEventListener('resize', updateMarginLeft);
    updateMarginLeft();
    return () => window.removeEventListener('resize', updateMarginLeft);
  }, []);

  return (
    <>
      <Navbar className={`navigation fixed-top ${isTransparent && !isMobileMenuOpen ? 'transparent' : ''}`}>
        <NavbarBrand onClick={_ => navigate('/')}>
          <img src={logo} alt='HDFC Logo' />
        </NavbarBrand>
        <Nav className='ms-auto align-items-center d-flex hide-on-mobile'>
          {headerItems.map((item, i) => {
            if (!shouldShowMenuItem(item)) return;
            return (
              <div key={i} className={`navigation-desktop-item ${menuItems.includes(item.id) ? 'active' : ''}`} onClick={_ => handleMenuClick(item)}>
                {item.label} 
                {item?.children?.length > 0 && <span className="caret">&#9660;</span>}
              </div>
            )
          })}
          <div className='nav-action'>
            {loggedinUser && username && (
              <>
                <UncontrolledDropdown>
                  <DropdownToggle nav className='py-1'>
                    <div className='nav-logged-in round-icon'>{getFirstLetters(username)}</div>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem className='d-flex align-items-center user-info' onClick={_ => navigate('/user-profile')}>
                      <div className='round-icon me-2'>{getFirstLetters(username)}</div>
                      <div className='user-name'>{username}</div>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </>
            )} 
          </div>
        </Nav>
        {menuItems?.length > 0 && (
          <div className="navigation-desktop-drawer hide-on-mobile" ref={overlayRef}>
            <div id="nav_drawer" className={`navigation-drawer-content ${false && ['knowledge', 'finance'].includes(menuItems[0]) ? 'reverse' : ''}`} ref={drawerRef} style={{ '--margin-left': `${marginLeft}px` }}>
              {menuItems.map((menuItem, i) => {
                let item = findChildAndParents(menuItem)?.item;
                if (item && item?.children?.length) {
                  return (
                    <div key={i} className="navigation-drawer-column">
                      {item.children.map((child, j) => (
                        // <div key={j} className={`navigation-drawer-item ${menuItems.includes(child.id) ? 'active' : ''}`} onClick={_ => openMenuItem(child.id, i + 1)}>
                        <div key={j} className={`navigation-drawer-item ${menuItems.includes(child.id) ? 'active' : ''}`} onClick={_ => handleMenuClick(child)}>
                          <div className="navigation-drawer-item-label">{child.label}</div>
                          {child.children?.length > 0 && 
                            <svg className="navigation-drawer-item-arrow" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.5 15L12.5 10L7.5 5" stroke={menuItems.includes(child.id) ? '#ED1C24' : '#0c4acf'} strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                          }
                        </div>
                      ))}
                    </div>
                  )
                }
              })}
            </div>
          </div>
        )}
        <div className="show-on-mobile ms-auto align-items-center">
          {loggedinUser && username &&
            <div onClick={_ => navigate('/user-profile')} className="nav-logged-in round-icon">{getFirstLetters(username)}</div>
          }
          {!isMobileMenuOpen && <img className={`menu-toggle-btn ${isTransparent ? 'transparent' : ''}`} onClick={_ => setIsMobileMenuOpen(true)} src={hamburger} alt="Open Menu"/>}
          {isMobileMenuOpen && <img className="menu-toggle-btn bigger" onClick={_ => setIsMobileMenuOpen(false)} src={close} alt="Open Menu"/>}
        </div>
      </Navbar>
      <div className="navigation-container">
        <Drawer anchor="top" open={isMobileMenuOpen} hideBackdrop sx={{top: 70, position: 'relative'}}>
          {/* L1 */}
          <div className="nav-column nav-level-1"> 
            {headerItems.map((item, i) => {
              if (!shouldShowMenuItem(item)) return '';
              return (<div key={i} onClick={_ => handleMenuClick(item)} className={`nav-item`}>
                <div className="nav-label">{item.label}</div>
                <div className="nav-item-right-icon">
                  {item.children && <img src={right} alt="Right icon" />}
                </div>
              </div>)
            })}
            <div className="nav-column-divider" />
            <div className="my-2 d-flex justify-content-center">
              {loggedinUser && username && <div onClick={_ => handleLogout()} className="action-button action-button-mobile">Log Out</div>}
            </div>
          </div>
          {/* L2 */}
          {headerItems.filter(L1 => L1?.children).map((L2, i) => 
            <MobileDrawer key={i} item={L2} handleMenuClick={handleMenuClick} level={2} menuItems={menuItems} />
          )}
          {/* L3 */}
          {headerItems.filter(L1 => L1?.children).map(L2 =>
            L2?.children?.map((L3, i) => <MobileDrawer key={i} item={L3} handleMenuClick={handleMenuClick} level={3} menuItems={menuItems} />)
          )}
          {/* L4 */}
          {headerItems.filter(L1 => L1?.children).map(L2 =>
            L2?.children?.map(L3 => 
              L3?.children?.map((L4, i) => <MobileDrawer key={i} item={L4} handleMenuClick={handleMenuClick} level={4} menuItems={menuItems} />)
            )
          )}
          {/* L5 */}
          {headerItems.filter(L1 => L1?.children).map(L2 =>
            L2?.children?.map(L3 => 
              L3?.children?.map(L4 => 
                L4?.children?.map((L5, i) => <MobileDrawer key={i} item={L5} handleMenuClick={handleMenuClick} level={5} menuItems={menuItems} />)
              )
            )
          )}
          {/* L6 */}
          {headerItems.filter(L1 => L1?.children).map(L2 =>
            L2?.children?.map(L3 => 
              L3?.children?.map(L4 => 
                L4?.children?.map(L5 => 
                  L5?.children?.map((L6, i) => <MobileDrawer key={i} item={L6} handleMenuClick={handleMenuClick} level={6} menuItems={menuItems} />)
                )
              )
            )
          )}
          {/* L7 */}
          {headerItems.filter(L1 => L1?.children).map(L2 =>
            L2?.children?.map(L3 => 
              L3?.children?.map(L4 => 
                L4?.children?.map(L5 => 
                  L5?.children?.map(L6 => 
                    L6?.children?.map((L7, i) => <MobileDrawer key={i} item={L7} handleMenuClick={handleMenuClick} level={7} menuItems={menuItems} />)
                  )
                )
              )
            )
          )}
          {/* L8 */}
          {headerItems.filter(L1 => L1?.children).map(L2 =>
            L2?.children?.map(L3 => 
              L3?.children?.map(L4 => 
                L4?.children?.map(L5 => 
                  L5?.children?.map(L6 => 
                    L6?.children?.map(L7 => 
                      L7?.children?.map((L8, i) => <MobileDrawer key={i} item={L8} handleMenuClick={handleMenuClick} level={8} menuItems={menuItems} />)
                    )
                  )
                )
              )
            )
          )}
        </Drawer>
      </div>
      {/* <ScrollToTop /> */}
    </>
  );
}

export default Header;
