import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../financePage/finSupplier.css";
import finImg from "../../assets/images/Supplier.jpg";
import Footer from "../../components/Footer";
import FinanceSuccsess from "../../assets/images/appliedForFinanceSuccess.gif"
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Card,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  TextField,
  IconButton,
  Button,
  Box,
} from "@mui/material";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { financialRegistrationForSupplier } from "../../redux/slice/finSupplierSlice";
import "../financePage/finSupplier.css";


const FinSupplier = () => {
  const navigate = useNavigate();

  const storedUser = localStorage.getItem("new_user");
  const accessToken = localStorage.getItem("accessToken");
  const gettingLoginNums = storedUser
    ? JSON.parse(storedUser).phoneNumber
    : null;

  const gettingLoginName = storedUser ? JSON.parse(storedUser).name : null;

  const [formValues, setFormValues] = useState({
    developerGroup: "",
    contactPerson: gettingLoginName || "",
    mobileNumberOfContact: gettingLoginNums || "",
    developerSupplyTo: "",
    meterialServiceProvided: "",
    applyFundingFor: "",
  });

  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      developerGroup,
      contactPerson,
      mobileNumberOfContact,
      developerSupplyTo,
      meterialServiceProvided,
      applyFundingFor,
    } = formValues;
  
    if (
      developerGroup === "" ||
      contactPerson === "" ||
      mobileNumberOfContact === ""
    ) {
      setError("Please fill in all required fields");
      return;
    }

    dispatch(
      financialRegistrationForSupplier({
        formValues: {
          developerGroup,
          contactPerson,
          mobileNumberOfContact,
          developerSupplyTo,
          meterialServiceProvided,
          applyFundingFor,
        },
        accessToken,
        gettingLoginNums,
      })
    );
    setFormSubmitted(true);

    setFormValues({
      developerGroup: "",
      contactPerson: "",
      mobileNumberOfContact: "", // Ensure this property is cleared
      developerSupplyTo: "",
      meterialServiceProvided: "",
      applyFundingFor: "",
    });

    // Clear error state
    setError("");
  };

  const handleClick = (id) => {
    navigate(`/${id}`);
  };

  const goToHome = () => {
    navigate(`/home`);
  };
  return (
    <>
      <Header />
      
      <Grid container mt={10}>
        <Grid item xs={12} mt={6} >
          <p variant="body1" className="finance_header_supplier">
            <a onClick={() => handleClick("home")}>Home/&nbsp;</a> Apply for
            Finance
          </p>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12} mt={2} >
          <p className="top_supplier">Apply for Finance</p>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12} >
          <p className="top_content_supplier">
            Improve your working capital cycle by availing invoice discounting
            solutions through our partners.
          </p>
        </Grid>
      </Grid>
      <div className="main_container_supplier">
        <img src={finImg} alt="finImg" className="img_style_Supplier" />

        <div className="form_card_supplier">
          {!formSubmitted ? (
            <CardContent>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="username" className="form-label all_lebel_supplier">
                    Name of the Supplier Firm <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control custom_input_supplier"
                      id="developerGroup"
                      name="developerGroup"
                      value={formValues.developerGroup}
                      onChange={handleChange}
                      size="small"
                      variant="outlined"
                      placeholder="Enter Name of Supplier Firm"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="contact person" className="form-label all_lebel_supplier">
                    Name of the Contact Person<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control custom_input_supplier"
                      name="contactPerson"
                        value={formValues.contactPerson}
                        onChange={handleChange}
                        size="small"
                        variant="outlined"
                        placeholder="Enter Name of the Contact Person"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="password" className="form-label all_lebel_supplier">
                    Mobile number of the Contact Person<span style={{ color: "red" }}>*</span>
                    </label>
                    <PhoneInput
                        defaultCountry="IN"
                        placeholder="Enter mobile number"
                        international={true}
                        value={gettingLoginNums}
                        onChange={(value) =>
                          setFormValues({
                            ...formValues,
                            mobileNumberOfContact: value,
                          })
                        }
                       className="custom_input_supplier"
                    
                      />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="confirmPassword" className="form-label all_lebel_supplier">
                    Developer(s) you supply to
                    </label>
                    <input
                      className="form-control custom_input_supplier"
                      name="developerSupplyTo"
                      value={formValues.developerSupplyTo}
                      onChange={handleChange}
                      type="text"
                      size="small"
                      variant="outlined"
                      placeholder="Enter Developer(s) you supply to"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="password" className="form-label all_lebel_supplier">
                    Material/Service provided
                    </label>
                    <input
                      className="form-control custom_input_supplier"
                      name="meterialServiceProvided"
                        value={formValues.meterialServiceProvided}
                        onChange={handleChange}
                        type="text"
                        size="small"
                        variant="outlined"
                        placeholder="Enter Material/Service provided"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="confirmPassword" className="form-label all_lebel_supplier">
                    Submit interest for
                    </label>
                    <select
                    className="form-select custom_input_supplier"
                    name="applyFundingFor"
                    id="deliveryExperience"
                    aria-label="Select Delivery Experience"
                    value={formValues.applyFundingFor} 
                    onChange={handleChange}
                    
                  >
                     <option defaultValue=""></option>
                    <option value="Invoice Discounting">Invoice Discounting</option>
                    <option value=" Working Capital Debt"> Working Capital Debt</option>
                    <option value="Term Loan">Term Loan</option>
                    
                  </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                  <div className="last_divider"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 btn_group_supplier">
                <button className="cancel_btn_supplier" onClick={goToHome}>Cancel</button>
                <button className="apply_btn_supplier">Apply</button>
                  </div>
                </div>
                
              </form>
            </CardContent>
          ) : (
            <CardContent>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img
                    src={FinanceSuccsess}
                    alt="Your GIF"
                    height="150px"
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <p className="thanks_styles">Thank you for applying!</p>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <p className="below_styles">
                    Please write to us at{" "}
                    <span style={{ color: "#0C4ACF" }}>
                    bleucircle@hdfccapital.com
                    </span>{" "}
                    for further communication.
                  </p>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={12}
                 
                  display="flex"
                  justifyContent="center"
                >
                  <button
                   className="go_to_btn"
                    onClick={goToHome}
                  >
                    Go to Home
                  </button>
                </Grid>
              </Grid>
            </CardContent>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};
export default FinSupplier;
