import React from 'react';
import '../screens/UserProfile.css';
import { Typography, Button, Modal, Box } from '@mui/material';
import warningIcon from '../assets/images/userprofile/warning_icon.svg';

const styles = {
    container: {
        position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper', border: 0, borderRadius: '0.75rem',
        boxShadow: '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)', p: '1.5rem',
    },
    headingText: {
        color: "var(--gray-900, #101828)", fontFamily: "Inter", fontSize: "1.125rem",
        fontStyle: "normal", fontWeight: "600", lineHeight: "1.75rem",
    },
    descriptionText: {
        color: "var(--gray-500, #667085)", fontFamily: "Inter", fontSize: "0.875rem",
        fontStyle: "normal", fontWeight: "400", lineHeight: "1.25rem"
    },
    outlinedButton: {
        display: "flex", width: "6.875rem", padding: "0.625rem 1rem", justifyContent: "center", alignItems: "center", gap: "0.5rem",
        borderRadius: "1.875rem", border: "1px solid var(--gray-300, #D0D5DD)", background: "var(--White, #FFF)", boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
    },
    outlinedText: {
        color: "var(--gray-700, #344054)", fontFamily: "Inter", fontSize: "0.875rem", fontStyle: "normal", fontWeight: "600", lineHeight: "1.25rem", textTransform: "none"
    },
    containedButton: {
        display: "flex", padding: "0.625rem 1rem", justifyContent: "center", alignItems: "center", gap: "0.5rem",
        borderRadius: "2.5rem", border: "1px solid #0C4ACF", background: "#0C4ACF", boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
    },
    containedText: {
        color: "var(--White, #FFF)", fontFamily: "Inter", fontSize: "0.875rem", fontStyle: "normal", fontWeight: "600", lineHeight: "1.25rem", textTransform: "none"
    }
}

const ConfirmModal = ({ modalState, onClose, onAgree, isUnsubscrible, unsubscribeFlag }) => {
    return (
        <div>
            <Modal
                open={modalState}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styles.container}>
                    <img src={warningIcon} alt='check-icon' className='warning-image' />
                    <Typography sx={styles.headingText}>
                        {isUnsubscrible 
                        ? (unsubscribeFlag ? 'Are you sure you want to subscribe from BLEU Circle?' :'Are you sure you want to unsubscribe from BLEU Circle?') 
                        : 'Are you sure you want to delete your account?'}
                    </Typography>
                    <div style={{ height: '0.5rem' }} />
                    <Typography sx={styles.descriptionText}>
                        {isUnsubscrible 
                        ? (unsubscribeFlag ? '' :'You can always come back again.')
                        : 'The changes cannot be reversed.'}
                    </Typography>
                    <div style={{ height: '1.5rem' }} />
                    <div className='actions-buttons'>
                        <div>
                            <Button onClick={onClose} variant='outlined' sx={styles.outlinedButton}>
                                <Typography sx={styles.outlinedText}>
                                    Cancel
                                </Typography>
                            </Button>
                        </div>
                        <div>
                            {isUnsubscrible && <Button onClick={()=>onAgree(!unsubscribeFlag)} variant='contained' sx={styles.containedButton}>
                                <Typography sx={styles.containedText}>
                                    {unsubscribeFlag ? 'Subscribe' :'Unsubscribe'}
                                </Typography>
                            </Button>}
                            {!isUnsubscrible && <Button onClick={()=>onAgree('delete')} variant='contained' sx={styles.containedButton}>
                                <Typography sx={styles.containedText}>
                                    Delete
                                </Typography>
                            </Button>}
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default ConfirmModal;