import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./App.css";
import About from "./screens/About";
import EsgOverview from "./screens/esg/EsgOverview";
import Home from "./screens/Home";
import Innovations from "./screens/innovationPage/Innovations";
import Prelogin from "./screens/Prelogin";
import ResearchReports from "./screens/ResearchReports";
import Solution from "./screens/Solution";
import UserProfile from "./screens/UserProfile";
import MasterFinance from "./screens/financePage/MasterFinance";
import PLContent from "./screens/PLContent";
import ESGContent from "./screens/ESGContent";
import AboutContent from "./screens/AboutContent";
import Search from "./screens/Search";
// import AboutIndiaContent from "./screens/AboutIndiaContent";

function App() {   
  window.dataLayer.push({
    event: 'pageview'
  });
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Prelogin />} />
          <Route path="/about" element={<About />} />
          <Route path="/about/:L3" element={<AboutContent />} />
          <Route path="/home" element={<Home />} />
          
          <Route path="/innovations" element={<Innovations />} />
          <Route path="/innovations/solutions" element={<Solution />} />
          
          <Route path="/project-lifecycle/" element={<Innovations />} />
          <Route path="/project-lifecycle/:L2" element={<PLContent />} />
          <Route path="/project-lifecycle/:L2/:L3" element={<PLContent />} />
          <Route path="/project-lifecycle/:L2/:L3/:L4" element={<PLContent />} />
          
          {/* <Route path='/esg/trends' element={<ESGTrends />} />
          <Route path='/esg/reporting-benchmarks' element={<ESGReportingFramework />} />
          <Route path='/esg/social-responsibility' element={<ESGSocialResponsibility />} />
        <Route path='/esg/green-buildings' element={<ESGGreenBuildings />} /> */}

          <Route path="/esg/" element={<PLContent />} />
          <Route path='/esg/overview' element={<EsgOverview />} />
          <Route path="/esg/:L2" element={<PLContent />} />
          <Route path="/esg/:L2/:L3" element={<PLContent />} />
          <Route path="/esg/:L2/:L3/:L4" element={<PLContent />} />
          
          <Route path="/academia/" element={<ESGContent />} />
          <Route path="/academia/:L2" element={<ESGContent />} />

          <Route path="/hdfc-tech-innovators/" element={<ESGContent />} />
          <Route path="/hdfc-tech-innovators/:L2" element={<ESGContent />} />
          
          <Route path="/research-reports" element={<ResearchReports action='research-report' />} />
          <Route path="/case-studies" element={<ResearchReports action='case-study' />} />

          <Route path="/finance" element={<MasterFinance />} />
          <Route path="/financing/" element={<ESGContent />} />
          <Route path="/financing/:L2" element={<ESGContent />} />

          <Route path="/fractional-ownership/" element={<ESGContent />} />
          <Route path="/fractional-ownership/:L2" element={<ESGContent />} />

          
          <Route path="/user-profile" element={<UserProfile />} />
          
          
      
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
