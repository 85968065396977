import React from 'react'
import '../App.css';
import drive from '../assets/images/Financier.jpg';
import { useNavigate } from "react-router-dom";

export default function SolutionsToDrive({persona = 'developers'}) {
    const navigate = useNavigate();
    function handleClick() {
        navigate('/finance', {state:{persona: persona}})
    }
    return (
        <div className="drive-container">
            <div className="drive-info">
                <div className="drive-info-label">
                    Driven By Vision
                </div>
                <div className="drive-info-title gradient-header">
                    Financing solutions 
                    to drive your vision
                </div>
                <div className="drive-image-container mobile-only">
                    <div className="drive-image" style={{backgroundImage: `url(${drive}`}} alt="Drive" />
                </div>
                <div className="drive-info-body render-new-lines">
                    {persona === 'developers' && (<ul>
                        <li>Apply to HDFC Capital to get funding for your affordable housing projects.</li>
                        <li>Express your interest in providing financing solutions to your trusted suppliers.</li>
                    </ul>)}
                    {persona === 'suppliers' && (<div>
                        <p>Improve your working capital cycle by availing financing solutions through our partners.</p>
                    </div>)}
                    {persona === 'startups' && (<div>
                        <p>Apply to get a chance of funding by HDFC Capital. Get your company and solution evaluated for showcase on the BLEU Circle platform.</p>
                    </div>)}
                </div>
                <div className="action-button" onClick={handleClick}>
                    Apply Now
                </div>
            </div>
            <div className="drive-image-container desktop-only">
                <div className="drive-image" style={{backgroundImage: `url(${drive}`}} alt="Drive" />
            </div>
        </div>
    )
}
