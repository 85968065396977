import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getApiUrl } from "../../getApiUrl";

const initialState = {
  formData: {}, // Your form data state
  errors: null,
  loading: false,
};

export const submitFormData = createAsyncThunk(
  "form/submitFormData",
  async ({ formData, accessToken }, { rejectWithValue }) => {
    const { name, persona, phoneNumber, subPersona } = formData;
    const processedPhoneNumber = phoneNumber.replace(/^(\+)/, "");
    const apiUrl = getApiUrl();
    const data = {
      name,
      persona: persona,
      mobileNumber: processedPhoneNumber,
      personaSubtype: subPersona,
      personaSource: "Website",
      consent: true,
      consentOnWhatsApp: true,
      termsConditions: true,
    };
    localStorage.setItem(
      "user",
      JSON.stringify({
        name: name,
        persona: persona,
        phoneNumber: processedPhoneNumber,
        personaSubtype: subPersona,
        unsubscribedPersona: false
      })
    );
    localStorage.setItem("unsubscribedPersona",false)
    try {
      const response = await axios.post(
        `${apiUrl}/services/apexrest/registration`,
        {
          data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const personaRegSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
    clearFormData: (state) => {
      state.formData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitFormData.pending, (state) => {
        state.loading = true;
      })
      .addCase(submitFormData.fulfilled, (state, action) => {
        state.loading = false;
        state.formData = action.payload; // Update form data on success
        state.errors = null; // Reset errors on success
      })
      .addCase(submitFormData.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload; // Set errors on failure
      });
  },
});

export const { clearErrors, clearFormData } = personaRegSlice.actions;
export default personaRegSlice.reducer;
