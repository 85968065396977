import React, { useEffect, useState } from "react";
import loginImage from "../assets/images/login.png";
import { Checkbox, Dialog, DialogContent, FormControl, FormControlLabel, InputAdornment, TextField } from "@mui/material";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import {fetchAccessToken} from  "../redux/slice/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { newLogin, isLoggedin } from "../redux/slice/newLoginSlice";
import axios from "axios";
import { getStrapiApis } from "../getStrapiApis";

const NewLoginDialog = ({atRoot}) => {
    const emptyForm = {
        username: "",
        password: "",
        phone: "",
        isChecked: false,
        errors: {
            username: null,
            password: null,
            phone: null,
            general: null
        },
    }

    const API_BASE_URL = getStrapiApis();
    const [footerOptions, setFooterOptions] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const accessToken = useSelector((state) => state?.auth?.accessToken);
    const loginState = useSelector((state) => state?.login);
    const [isOpen, setIsOpen] = useState(false);
    const [formValue, setFormValue] = useState(emptyForm);
    const loggedinUser = useSelector((state) => state?.login?.loggedIn);
    
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(
              `${API_BASE_URL}/policy-document?populate=policyDocuments&populate=policyDocuments.reportFile`
            );
            const footerData =
              response?.data?.data?.attributes?.policyDocuments?.map((docs) => {
                return {
                  title: docs.reportName,
                  id: docs.entryNo,
                  reportUrl: docs.reportFile?.data?.attributes?.url,
                };
              });
            setFooterOptions(footerData);
          } catch (error) {
            console.log(error);
          }
        };

        if (!localStorage.getItem('new_user')) {
            if (!atRoot) navigate('/');
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }

        fetchData();
      }, []);

    const handleFormChange = (event) => {
        const key = event.target.name;
        let next = formValue;
        next[key] = event.target.value;
        if (next.errors[key]) next.errors[key] = null;
        setFormValue(() => ({
          ...next,
          errors: {...next.errors},
        }));
    };
    const handlePhoneChange = (nextPhone) => {
        let next = formValue;
        next.phone = nextPhone;
        next.errors.phone = null;
        setFormValue(() => ({
            ...next,
            errors: {...next.errors}
        }))
    }
    const toggleCheckbox = (isChecked) => {
        setFormValue((prev) => ({
            ...prev,
            isChecked: !prev.isChecked,
            errors: {...prev.errors}
        }))
    }
    const login = _ => {
        let values = formValue;
        let errors = formValue.errors;
        errors.general = null;
        let isValid = true;
        if (!values.username || values.username.length <= 0) {
            isValid = false;
            errors.username = 'Please enter a valid username';
        }
        if (!values.password || values.password.length <= 0) {
            isValid = false;
            errors.password = 'Please enter a valid password';
        }
        if (!values.phone || !isValidPhoneNumber(values.phone)) {
            isValid = false;
            errors.phone = 'Please enter a valid phone number';
        }
        if (!isValid) {
            setFormValue((prev) => ({...prev, errors: errors}));
        } else {
            dispatch(newLogin({userName: values.username, password: values.password, accessToken, mobileNumber: values?.phone?.replace('+', '')}));
        }
    }
    const openPDF = (url) => {
        window.open(url, "_blank");
      };

    useEffect(() => {
        // if (localStorage.getItem('accessToken')) return;
        dispatch(fetchAccessToken());
    }, [dispatch]);

    useEffect(() => {
        let errors = formValue.errors;
        if ( loginState?.loading) {
            errors.general = null;
            return setFormValue((prev) => ({...prev, errors: errors}));
        }
        if (!loginState?.loading && loginState?.error) {
            errors.general = 'An unkown error occurred';
            return setFormValue((prev) => ({...prev, errors: errors}));
        }
        if (!loginState?.loading && loginState?.success?.status === false) {
            errors.general = 'Username or password is incorrect';
            return setFormValue((prev) => ({...prev, errors: errors}));
        }
        if (!loginState?.loading && loginState?.success?.status === true) {
            setIsOpen(false);
            setFormValue(emptyForm);
            dispatch(isLoggedin(true))
            document.getElementsByTagName("body")[0].classList.add('show-chatbot');
        }
    }, [loginState]);

    useEffect(() => {
        if(!localStorage.getItem('new_user') && !loggedinUser) {
            setFormValue(emptyForm);
            setIsOpen(true);
        } else {
            document.getElementsByTagName("body")[0].classList.add('show-chatbot');
        }
    },[loggedinUser])

    return (<>
        <Dialog
            className="login-dialog"
            open={isOpen}
            PaperProps={{style: {margin: '0', borderRadius: '1.25rem', width: '640px', maxWidth: '100%'}}}
        >
            <DialogContent className="login-dialog-content">
                <div className="image-container">
                    <img src={loginImage} alt="loginImage" style={{width: '100%', height: '236px'}} />
                    {/* <CloseIcon className="close-icon" onClick={() => onClose()} /> */}
                </div>
                <div className="login-dialog-form">
                    <div className="login-modal-title">Login</div>
                    <FormControl fullWidth className="input-field-container">
                        <label>Username <span className="required">*</span></label>
                        <TextField
                            type="text"
                            size="small"
                            variant="outlined"
                            placeholder="Enter your name"
                            value={formValue.username}
                            name="username"
                            onChange={handleFormChange}
                            disabled={loginState?.loading || loginState?.success?.status}
                            error={!!formValue.errors.username}
                            helperText={formValue.errors.username}
                        />
                    </FormControl>
                    <FormControl fullWidth className="input-field-container">
                        <label>Password <span className="required">*</span></label>
                        <TextField
                            type={showPassword ? 'text' : 'password'}
                            size="small"
                            variant="outlined"
                            placeholder="Enter your password"
                            value={formValue.password}
                            name="password"
                            onChange={handleFormChange}
                            disabled={loginState?.loading || loginState?.success?.status}
                            error={!!formValue.errors.password}
                            helperText={formValue.errors.password}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment className="pw-visibility-toggle" position="end" onClick={_ => setShowPassword(!showPassword)}>
                                        {showPassword ? 'Hide' : 'Show'}
                                    </InputAdornment>
                                )
                            }}
                        />
                    </FormControl>
                    <div>
                        <label className="mobile-num">Mobile number <span className="required">*</span></label>
                        <PhoneInput
                            name="phone"
                            className={`login-phone-input ${true || !!formValue.errors.phone ? '' : 'invalid'}`}
                            defaultCountry="IN"
                            value={formValue.phone}
                            onChange={handlePhoneChange}
                            disabled={loginState?.loading || loginState?.success?.status}
                            country="IN"
                            international
                            countryCallingCodeEditable={false}
                            placeholder="Enter mobile number"
                        />
                        {formValue?.errors?.phone && 
                            <div className="login-error-msg" style={{fontSize: '.75rem', marginLeft: '1rem'}}>{formValue.errors.phone}</div>
                        }
                    </div>
                    <FormControl fullWidth className="input-field-container">
                        <FormControlLabel
                            control={<Checkbox checked={formValue.isChecked} id="isChecked" onChange={toggleCheckbox} color="primary" />}
                            style={{alignItems: 'flex-start'}}
                            label={
                                <div htmlFor="isChecked" className="terms-checkbox" style={{fontSize: '12px', paddingTop: '9px'}}>
                                    You agree and understand that at the time of logging in, you will be required to provide certain personal information on the website. You acknowledge that at the time of accessing the website you have agreed to be bound by the {" "}
                                    <span className="terms-url" onClick={() => openPDF(footerOptions[2]?.reportUrl)}>Terms of Use</span>,{" "}
                                    <span className="terms-url" onClick={() => openPDF(footerOptions[0]?.reportUrl)}>Privacy Policy</span>,{" "}
                                    <span className="terms-url" onClick={() => openPDF(footerOptions[3]?.reportUrl)}>Cookie Policy</span>{" "}
                                    and{" "}
                                    <span className="terms-url" onClick={() => openPDF(footerOptions[1]?.reportUrl)}>Legal Disclaimer</span>{" "}
                                    which also includes terms and policies on the provision and use of such information provided by you. You hereby agree and consent to the provision of such information, including personal information, and accept that you shall continue to be bound by the aforementioned terms and policies that you have agreed on.
                                    Further, any use of Chatbot services will also be subject to the aforementioned terms, policies and disclaimers.
                                    You agree to receive regular updates from us on the provided mobile number.
                                </div>
                            }
                        />
                    </FormControl>
                    <div className="login-button-container">
                        <div className="text-danger">{formValue?.errors?.general || ''}</div>
                        <button className="login-submit-button" disabled={!formValue.isChecked || loginState?.loading} onClick={login}>
                            {loginState?.loading ? 'Logging In' : 'Login'}
                        </button>
                    </div>
                    {/* {formValue?.errors?.general} */}
                </div>
            </DialogContent>
        </Dialog>
        {isOpen && <div className="login-dialog-backdrop" />}
    </>)
}
export default NewLoginDialog;