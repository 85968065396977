import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getApiUrl } from "../../getApiUrl";




export const financialRegistrationForSupplier = createAsyncThunk(
  "startups/financialRegistrationForSupplier",
  async (
    { formValues, accessToken, gettingLoginNums },
    { rejectWithValue }
  ) => {
    const {
      developerGroup,
      contactPerson,
      mobileNumberOfContact,
      developerSupplyTo,
      meterialServiceProvided,
      applyFundingFor,
    } = formValues;

    const processedPhoneNumber1 = gettingLoginNums.replace(/^(\+91)/, "");

    const apiUrl = getApiUrl();

    try {
       
      const url = `${apiUrl}/services/apexrest/financialregistrationForSupplier`;
      console.log('url',url);
      const paramData = {
        data: {
          supplierFirm: developerGroup,
          contactPerson: contactPerson,
          mobileNumberOfContact: processedPhoneNumber1,
          developerSupplyTo: developerSupplyTo,
          meterialServiceProvided: meterialServiceProvided,
          submitInterestFor: applyFundingFor,
          financeType: "Supply Ecosystem",
          userName: JSON.parse(localStorage.getItem("new_user")).name
          // phoneNumber:processedPhoneNumber1
        },
      };
      const apiResp = await axios.post(url, JSON.stringify(paramData), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return apiResp;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const apiSlice = createSlice({
  name: "supplier",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(financialRegistrationForSupplier.pending, (state) => {
      // Handle pending state
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      financialRegistrationForSupplier.fulfilled,
      (state, action) => {
        // Handle success state
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(
      financialRegistrationForSupplier.rejected,
      (state, action) => {
        // Handle error state
        state.loading = false;
        state.error = action.payload;
      }
    );
  },
});

export default apiSlice.reducer;
