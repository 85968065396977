import React, { useEffect, useRef, useState } from 'react'
import Slider from "react-slick";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import "../styles/solution.css";
import "../App.css";
import arrow from "../assets/images/arrow-right.svg";
import Markdown from 'react-markdown';
import CustomMarkdown from "./CustomMarkdown";

function GeneralSlider({items, section, show = 1, hideControls}) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  const sliderRef = useRef(null);

  useEffect(() => {
    
    const handleResize = () => setIsMobile(window.innerWidth < 1000);
    window.addEventListener('resize', handleResize);
    // if (items && items.length && section === 'exhibits' && sliderRef.current) {
    //   let slides = sliderRef.current?.innerSlider?.track?.node.querySelectorAll('.slick-slide .exhibit-text');
    //   if (slides && slides.length) {
    //     let maxHeight = 0;
    //     slides.forEach(s => {
    //       if (s.clientHeight > maxHeight) maxHeight = s.clientHeight;
    //     });
    //     setMinHeight(maxHeight);
    //   }
    // }

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [items, section]);


  const shouldShowControls = () => {
    if (hideControls) return false;
    if (!section || !items || items.length <= 1) return false;
    if (section === 'images' && items.length === 2 && !isMobile) return false;
    if (items.length <= show && !isMobile) return false;
    return true;
  }

  
  const settings = {
    dots: false,
    infinite: items?.length >= show,
    speed: 500,
    slidesToShow: show,
    slidesToScroll: show,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: show < 2 ? show : 2,
          slidesToScroll: show < 2 ? show : 2,
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ],
    afterChange: (index) => setCurrentSlide(index),
  };

  const nextSlide = () => {
    if (sliderRef.current) sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    if (sliderRef.current) sliderRef.current.slickPrev();
  }

  const GSControls = ({current, max, show}) => {
    if (window.innerWidth < 1200 && show >= 2) show = 2;
    if (window.innerWidth < 900 && show >= 1) show = 1;
    if (show <= 0) show = 1;
    let width = 100 / max;
    let left = width * current;
    if ((left + (width * show)) > 100) left = 100 - (width * show);
    return (<>
      <div className="gs-controls-container">
        <div className="gs-controls-line">
          {/* <div className="gs-controls-indicator" style={{width: `calc(100% * ${width})`}} /> */}
          <div className="gs-controls-indicator" style={{left: `${left}%`, width: `${width * show}%`}} />
        </div>
        <div className="gs-controls-buttons">
          <div className="gs-controls-button">
            <NavigateBeforeIcon onClick={prevSlide} style={{ color: '#0C4ACF' }} />
          </div>
          <div className="gs-controls-button">
            <NavigateNextIcon onClick={nextSlide} style={{ color: '#0C4ACF' }} />
          </div>
        </div>
      </div>
    </>)
  }
  const ExhibitCard = ({exhibit}) => {
    return (
      <div className="general-slider-slide-container">
        <div className="exhibit">
          <div className="exhibit-image" style={{backgroundImage: `url(${exhibit?.image?.data?.attributes?.url})`}}></div>
          <div className="exhibit-text">
            {exhibit?.imageDescription}
              <CustomMarkdown className="markdown">
								{exhibit?.imageCredit}
							</CustomMarkdown>
            </div>
        </div>
      </div>
    )
  }

  const ReportCard = ({report, i}) => {
    const openPDF = (url) => {
      if (url) window.open(url,  '_blank');
    }
    return (
      <div key={i} className='rr-container' onClick={()=>openPDF(report?.reportFile?.data?.attributes?.url)}>
        <div className='rrreports-image' style={{backgroundImage: `url(${report?.coverImage?.data?.attributes?.url})`, backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}></div>
        <div className='rrreports-description' >
          {report?.reportName}
        </div>
      </div>
    )
  }

  const ProjectCard = ({card}) => {
    const openUrl = (url) => {
      if (url) window.open(url,  '_blank');
    }
    return (<>
      <div className="general-slider-slide-container">
        <div className="project-card">
          <div className="project-media">
            <div style={{backgroundImage: `url(${card?.programImage?.data?.attributes?.url})`}} />
          </div>
          <div className="project-info">
            <div className="project-info-tag">{card?.programType}</div>
            <div className="project-info-title">{card?.programName}</div>
            <div className="project-info-body"><CustomMarkdown>{card?.programDescription}</CustomMarkdown></div>
            <div className="project-info-action">
              <span onClick={() => openUrl(card?.programUrl)}>{card?.ProgramAction}</span>
              <img src={arrow} alt="right-arrow" />
            </div>
          </div>
        </div>
      </div>
    </>)
  }
    
  const VideoCard = ({video}) => {
    return (<>
      <div className="video-card">
        <div className="video-card-media">
          <iframe src={`https://www.youtube.com/embed/${video?.videoURL?.split("v=")[1]}`} />
        </div>
        <div className="video-card-content" onClick={_ => window.open(video?.videoURL, '_blank')}>
          <div className="video-card-title">{video?.videoTitle || 'No Title Found'}</div>
          <div className="video-card-action">
            <span>Watch Full Video</span>
            <img src={arrow} alt="Watch video" />
          </div>
        </div>
      </div>
    </>)
  }

  const ImageCard = ({img}) => {
    return (<>
      <div className="gs-image-card" style={{backgroundImage: `url(${img?.image?.data?.attributes?.url})`}} />
    </>)
  }

  return (
    <>
      <div className="gs-slider-container">
        {section && items && items.length === 1 && <>
          {section === 'exhibits' && <ExhibitCard exhibit={items[0]} />}
          {section === 'projects' && <ProjectCard card={items[0]} />}
          {section === 'videos'   && <VideoCard video={items[0]} />}
          {section === 'reports'  && <ReportCard report={items[0]} />}
        </>}
        {section === 'images' && items && items.length === 1 && <>
          <Slider {...settings} ref={sliderRef}>
            <ImageCard img={items[0]} />
            <ImageCard img={{}} />
          </Slider>
        </>}
        {section && items && items.length > 1 && <>
          <Slider {...settings} ref={sliderRef}>
            {items.map((item, i) => (<div key={i}>
              {section === 'exhibits' && <ExhibitCard exhibit={item} />}
              {section === 'projects' && <ProjectCard card={item} />}
              {section === 'videos'   && <VideoCard video={item} />}
              {section === 'reports'  && <ReportCard report={item} />}
              {section === 'images' && <ImageCard img={item} />}
            </div>))}
          </Slider>
          {shouldShowControls() && <GSControls current={currentSlide} max={items.length} show={show}/>}
        </>}
      </div>
    </>
  )
}

export default GeneralSlider