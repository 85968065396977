import React, {useState} from 'react'
import { useNavigate } from "react-router-dom";

function Vision({vision, innoSol}) {
	const navigate = useNavigate();
	const {heading, subHeading, image} = vision
	const redirectToSolution = () => {
		
	}
	const handleClick =()=> {
		navigate('/esg/overview');
	}
  	return (
	<>
		<div className="vision-container">
			<div className="vision-info">
				<div className="vision-info-title gradient-header">
					{heading}
				</div>
				<div className="vision-image-container mobile-only">
					{image?.url && <div className="vision-image" style={{backgroundImage: `url(${image?.url}`}} alt="Vision" />}
				</div>
				<div>
					<div className="vision-info-body render-new-lines">
						{subHeading}
					</div>
					<div className="action-button" onClick={handleClick}>
						Explore More
					</div>
				</div>
			</div>
			<div className="vision-image-container desktop-only">
				{image?.url && <div className="vision-image" style={{backgroundImage: `url(${image?.url}`}} alt="Vision" />}
			</div>
		</div>
	</>
  )
}

export default Vision