import React from 'react'
import '../App.css';
import developers from '../assets/images/finance.png';
import supplier from '../assets/images/Supplier.jpg';
import startup from '../assets/images/Startups.jpg';
import TruncateText from "./TruncateText";

export default function SolutionsForAll() {

  const handleOnclick = (opt) => {
    console.log(opt)
  }

  return (
    <div className="sfa-container">
        <div className="sfa-info">
            <div className="sfa-subtitle">
                Driven by Vision
            </div>
            <div className='sfa-title gradient-header'>
                Financing solutions for All
            </div>
            <div className='sfa-body'>
                <TruncateText>
                    Developers, Suppliers & Startups can avail assistance in funding, seize market opportunities, 
                    and receive guidance to navigate the complexities of the real estate industry. 
                    At BLEU Circle, we believe that the future of real estate is innovation of smart solutions. 
                    We fuel vision’s growth with tailored funding solutions designed to lead towards success.
                </TruncateText>
            </div>
        </div>
        <div className="sfa-media">
            <div className="sfa-column">
                <div className="sfa-card sfa-card-full" style={{backgroundImage: `linear-gradient(90deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%), linear-gradient(90deg, var(--hover-bg) 0%, var(--hover-bg) 100%), url(${developers})`}}>
                    <div className="sfa-card-title">
                        Developers
                    </div>
                    <div className="sfa-card-subtitle">
                        Funding for Project, Financing for Supply Ecosystem
                    </div>
                </div>
            </div>
            <div className="sfa-column">
                <div className="sfa-card" style={{backgroundImage: `linear-gradient(90deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%), linear-gradient(90deg, var(--hover-bg) 0%, var(--hover-bg) 100%), url(${supplier})`}}>
                    <div className="sfa-card-title">
                        Suppliers
                    </div>
                    <div className="sfa-card-subtitle">
                        Working Capital Financing Solutions
                    </div>
                </div>
                <div className="sfa-card" style={{backgroundImage: `linear-gradient(90deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%), linear-gradient(90deg, var(--hover-bg) 0%, var(--hover-bg) 100%), url(${startup})`}}>
                    <div className="sfa-card-title">
                        Startup
                    </div>
                    <div className="sfa-card-subtitle">
                        Funding for Project, Showcase on BLEU
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
