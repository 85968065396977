import React, { useState, useRef, useEffect } from 'react';
import { SwipeableDrawer } from '@mui/material';
import close from '../assets/images/close.svg';
import '../styles/truncate.css';

const TruncateText = ({ children, heading, content, maxLines = 4 }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [hasOverflow, setHasOverflow] = useState(true);
  const containerRef = useRef();

  useEffect(() => {
    const container = containerRef.current;
    const computedStyle = window.getComputedStyle(container);
    const lineHeight = parseInt(computedStyle.lineHeight);

    const contentHeight = container.scrollHeight;
    const maxHeight = maxLines * lineHeight;
    setHasOverflow(contentHeight > maxHeight);
    setIsExpanded(contentHeight <= maxHeight);
  }, [children]);

  const toggleTruncate = () => {
    setIsExpanded((prev) => !prev);
  };

  return (<>
    <div className="truncate-content toasted" ref={containerRef}>
      {children}
    </div>
    {hasOverflow && (<>
      <button className="read-more" onClick={toggleTruncate}>
        Read {isExpanded ? ' Less' : 'More'}
      </button>
      <SwipeableDrawer id="truncate-toast" anchor="bottom" open={isExpanded} onOpen={_ => {}} onClose={toggleTruncate} PaperProps={{sx: {borderRadius: '1.5rem 1.5rem 0 0'}}}>
        <div className="truncate-toast-header">
            <div className="truncate-toast-title">{heading}</div>
            <div className="truncate-toast-toggle" onClick={toggleTruncate}>
                <img alt="Close Description" src={close} />
            </div>
        </div>
        <div className="truncate-toast-body">
            {content}
        </div>
      </SwipeableDrawer>
    </>)}
    
  </>);
};

export default TruncateText;
