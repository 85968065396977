import React, { useEffect, useRef, useState } from "react";
import "../App.css";
import Slider from "react-slick";
import TI from "../assets/images/tech-innovators.png";
import TruncateText from "./TruncateText";
import arrowLeft from '../assets/images/arrowleft.svg';
import arrowRight from '../assets/images/arrowright.svg';

function TechInnovators({content}) {
    const {heading = '', subHeading = '', image} = content;
    const [drawerText, setDrawerText] = useState('');
    const [slideImages, setSlideImages] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = useRef(null);
    const settings = {
        infinite: true,
        // speed: 1000,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: current => {
            setDrawerText(slideImages[current].text);
            setCurrentSlide(current);
        }
    };
    useEffect(() => {
        if (!image || !image.length) return;
        let imageObj = {}
        image.forEach(i => {
            imageObj[i.imageId] = {
                text: i.imageTitle,
                url: i.imageFile?.data?.attributes?.url
            }
        });
        setSlideImages(Object.values(imageObj));
        
    }, [image]);

    useEffect(() => {
        if (!slideImages || !slideImages.length) return
        setDrawerText(slideImages[0].text);
    }, [slideImages]);

    const changeSlide = i => {
        if (!slideImages || !slideImages.length) return;
        let current = currentSlide;
        if (current === 0 && i === -1) return;
        current = (current + i) % slideImages.length;
        setCurrentSlide(current);
        sliderRef.current.slickPause();
        sliderRef.current.slickGoTo(current);
    }

    return (
        <>
        <div className="tech-container">
            <div className="tech-title gradient-header">
                {heading}
            </div>
            <div className="tech-body">
                <TruncateText>{subHeading}</TruncateText>
            </div>
            <div className="tech-media">
                <div className="tech-slider">
                    <Slider ref={sliderRef} {...settings}>
                        {slideImages.length && slideImages.map((slide, i) => (
                            <div key={i} className="tech-slider-slide">
                                <div style={{backgroundImage: `url(${slide.url})`}} />
                            </div>
                        ))}
                    </Slider>
                    <div className="tech-slider-controls">
                        <div className={`tech-slider-button ${currentSlide === 0 ? 'disabled' : ''}`} onClick={_ => changeSlide(-1)}>
                            <img src={arrowLeft} alt="Previous image" />
                        </div>
                        <div className="tech-slider-button" onClick={_ => changeSlide(1)}>
                            <img src={arrowRight} alt="Next image" />
                        </div>
                    </div>
                </div>
                <div className="tech-slider-drawer">
                    <div className="tech-slider-body">
                        {drawerText}
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default TechInnovators;
