import React from 'react'
import { Typography, Box, Grid, Card, CardContent } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import VideoPlay from "../assets/images/video-play.svg";

function SolutionTechInnovators({techInnovators}) {
  return (
    <>
        {/* <Grid container>
            <Grid item xs={12} mx={1} mt={2}>
              <Typography
                variant="body2"
                fontSize="20px"
                fontWeight="400"
                color="#667085"
              >
                {techInnovators?.description}
              </Typography>
            </Grid>
        </Grid> */}
        <Grid container sx={{display: 'flex' ,width: '100%', overflowX: 'scroll', flexWrap: 'nowrap'}} className='sol-tech-cont'>
        {techInnovators?.TopTechInnovators?.map((card) => (
            <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={card?.companyEntryNo}
            my={1}
            mx={1.5}
            sx={{ display: "flex", flexDirection: "row", minWidth: '19.0625rem' }}
            >
            <Card sx={{ borderRadius: "0px 20px", cursor: "pointer" }}>
                <CardContent>
                <Box
                    sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignContent: "center",
                    }}
                >
                    <div
                    style={{
                        height: "60px",
                        width: "170px",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left center",
                        backgroundImage: `url(${card.companyLogo?.data?.attributes?.url})`,
                    }}
                    />
                    <ArrowOutwardIcon
                    sx={{
                        borderRadius: "50%",
                        backgroundColor: "#0C4ACF",
                        color: "white",
                        fontSize: "32px",
                    }}
                    />
                </Box>
                <div
                    style={{
                    backgroundColor: "#F9FAFB",
                    borderRadius: "0 1rem",
                    padding: ".5rem 1rem",
                    marginTop: ".75rem",
                    fontFamily: "Inter",
                    }}
                >
                    <div
                        style={{
                            fontWeight: "500",
                            lineHeight: "1.5",
                            color: "#1D2939",
                        }}
                        onClick={() =>
                            window.open("http://" + card?.CompanyUrl)
                        }
                        >
                        {card?.CompanyUrl?.replace("https://", "")}
                        </div>
                        <div
                        style={{
                            fontSize: ".75rem",
                            lineHeight: "1.5",
                            color: "#475467",
                            minHeight: '4.5rem'
                        }}
                    >
                            {card?.CompanyProfile}
                    </div>
                    <div
                    style={{
                        fontSize: "0.875rem",
                        lineHeight: "1.25",
                        color: "#0C4ACF",
                        fontWeight: "600",
                        paddingTop: "1rem",
                        paddingBottom: "0.5rem",
                    }}
                    onClick={() =>
                        window.open(
                        card?.videoFile?.data?.attributes?.url,
                        "_blank"
                        )
                    }
                    >
                    Watch Video{" "}
                    <span>
                        {" "}
                        <img src={VideoPlay} alt="Play" />
                    </span>
                    </div>
                </div>
                </CardContent>
            </Card>
            </Grid>
            ))}
          </Grid>
    </>
  )
}

export default SolutionTechInnovators