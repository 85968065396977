import React, { useState, useRef } from 'react';
import '../App.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function HeroCarousel({ images }) {
  const imageData = images?.map(img => ({
    url: img?.attributes?.image?.data[0]?.attributes?.url, 
    tag: img?.attributes?.subheading
  })) || [];
  const fixedText = images.length ? images[0].attributes?.heading : '';

  const sliderRef = useRef(null);
  const tagRef = useRef(null);
  const [index, setIndex] = useState(0);
  const goToSlide = (next) => {
    updateSlide(next);
    sliderRef.current.slickGoTo(next);
  }
  
  const settings = {
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 4000,
    beforeChange: (current, next) => {
      updateSlide(next);
    },
  };

  const updateSlide = next => {
    if (!tagRef || !tagRef.current || !tagRef.current.classList) return
    tagRef.current.classList.add('fade');
    setTimeout(_ => {
      setIndex(next);
      tagRef.current?.classList.remove('fade')
    }, 301)
  }
  
  return (
    <div className="hero-container">
      <div className="hero-slider">
        <Slider ref={sliderRef} {...settings}>
          {imageData.map((img, i) => (
            <div key={i} className="slide">
              <div className="slide-bg-container" style={{backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%), linear-gradient(0deg, #0000 0%, #0000 75%, rgba(0,0,0,1) 100%), url(${img.url})`}}></div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="hero-slider-indicators">
        {imageData.map((_, i) => (
          <div 
            key={i}
            onClick={_ => goToSlide(i)}
            className={`hero-slider-indicator ${i === index ? 'active' : ''}`}
          ></div>
        ))}
      </div>
      <div className="hero-text">
        <div className="hero-fixed-text">
          {fixedText}
          <span ref={tagRef} className="hero-dynamic-text">{imageData[index].tag}</span>
        </div>
      </div>
    </div>
  );
}

export default HeroCarousel;
