// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-container {
    width: 1240px;
    margin: 0 auto;
    max-width: 100%;
    min-height: calc(100vh - 28vh);
}
.search-container .breadcrumbs {
    margin-top: 100px;
}
.search-container .search-title {
    font-family: Syne;
    color: #0C4ACF;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -.32px;
    font-size: 3rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/screens/search.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;IACd,eAAe;IACf,8BAA8B;AAClC;AACA;IACI,iBAAiB;AACrB;AACA;IACI,iBAAiB;IACjB,cAAc;IACd,gBAAgB;IAChB,gBAAgB;IAChB,sBAAsB;IACtB,eAAe;IACf,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".search-container {\n    width: 1240px;\n    margin: 0 auto;\n    max-width: 100%;\n    min-height: calc(100vh - 28vh);\n}\n.search-container .breadcrumbs {\n    margin-top: 100px;\n}\n.search-container .search-title {\n    font-family: Syne;\n    color: #0C4ACF;\n    font-weight: 500;\n    line-height: 1.2;\n    letter-spacing: -.32px;\n    font-size: 3rem;\n    margin-top: 1.5rem;\n    margin-bottom: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
