import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getApiUrl } from "../../getApiUrl";

export const verifyOTP = createAsyncThunk(
  "otpVerification/verifyOTP",
  async ({ phoneNumber, otp, accessToken }, { rejectWithValue }) => {
    const phoneNumberNew = phoneNumber.slice(1);
   
    const apiUrl = getApiUrl();
    try {
      const response = await axios.post(
        `${apiUrl}/services/apexrest/otpVerification`,
        {
          data: {
            phoneNumber: phoneNumberNew,
            otp: otp,
            consent: true,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("response", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const otpVerificationSlice = createSlice({
  name: "otpVerification",
  initialState: {
    otpVerificationResponse: null,
    error: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
      state.otpVerificationResponse = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyOTP.pending, (state) => {
        state.error = null;
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        state.otpVerificationResponse = action.payload;
      })
      .addCase(verifyOTP.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const { clearError } = otpVerificationSlice.actions;

export default otpVerificationSlice.reducer;
