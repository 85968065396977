import React, { useState, useEffect } from "react";
import "../App.css";
import "../styles/banner.css";
import TruncateToast from "./TruncateToast";
import Breadcrumbs from "./Breadcrumbs";


export default function Banner(props) {
  const defaultBreadcrumbs = [['Home', 'home']];
  const { url = '', heading, subheading, breadcrumbs = defaultBreadcrumbs} = props;

  return (
    <div className='banner' style={{backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 64.71%), url(${url})`}}>
      <div className='banner-layout'>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <div>
          <h1 className='banner-heading'>
            {heading}
          </h1>
          <TruncateToast heading={heading} content={subheading}>
            <div className='banner-content'>
              {subheading}
            </div>
          </TruncateToast>
        </div>
      </div>
    </div>
  );
}
