import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import axios from "axios";
import Banner from "../components/Banner";
import TruncateText from "../components/TruncateText";

import { getStrapiApis } from "../getStrapiApis";
import NewLoginDialog from "../accounts/NewLoginDialog";
import SolutionInfographics from "../components/SolutionInfographics";
import SolutionBlogsReports from "../components/SolutionBlogsReports";
import { useNavigate, useParams } from "react-router-dom";
import GeneralSlider from "../components/GeneralSlider";
import CustomMarkdown from "../components/CustomMarkdown";
import EsgImageGrid from "./esg/EsgImageGrid";
import ScrollToTop from "../components/ScrollToTop";

const API_BASE_URL = getStrapiApis();
const FILTER_URL = "/innovation-types?populate=aboutSectionImages.image&populate=aboutLogo&populate=cards.catImage&populate=workingPapers&populate=programList.programImage&populate=subSection1.sectionImage&populate=subSection1.sectionReports.reportFile&populate=sectionWithImages.sectionImages.image&populate=publishReports&populate=youtubeVideos&populate=ExibitEntries.image&populate=NewsArticle.newsLink&populate=trending.reportURL&populate=referenceReports.reportUrl&populate=referenceReports.reportFile&populate=blogs.blogUrl&populate=verticalInfographics.url&populate=verticalInfographics2.url&populate=l5Data.verticalInfographics.url&populate=l5Data.verticalInfographics2.url&populate=l5Data.youtubeVideos&populate=l5Data.ExibitEntries.image&populate=l5Data.NewsArticle.newsLink&populate=l5Data.trending.reportURL&populate=l5Data.referenceReports.reportUrl&populate=l5Data.referenceReports.reportFile&populate=l5Data.blogs.blogUrl&populate=l5Data.l6Data&populate=l5Data.l6Data.verticalInfographics.url&populate=sectionWithImages.sectionImages&[filters][innovationType][$eq]=";

const AboutContent = () => {
  const navigate = useNavigate();
  const [aboutContent, setAboutContent] = useState();
  const [attributes, setAttributes] = useState();
  const [esgContent, setESGContent] = useState();
  const innovationType = 'About';
  const {L3} = useParams();
  
  function makeLower(text) {
    if (!text?.length || !text.toLowerCase) return ''
    return text.trim().toLowerCase().replaceAll(' ', '-').replaceAll(',', '');
  }
  function makeUpper(text) {
    if (!text?.length) return '';
    if (text.indexOf('heart') > -1) text = text.replace('heart', 'h@art');
    // if (text.indexOf('heart') > -1) category = category.replace('heart', 'h@art');
    return text.trim().replaceAll('-', ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ').replaceAll('Hdfc', 'HDFC');
  }

  const breadcrumbs = [["Home", "home"], ['About'], [makeUpper(L3)]];

  const url_map = {
    1: '/about/global-affordable-housing',
    2: '/about/about-bleu-circle',
    3: '/about/about-hdfc-capital',
    4: '/hdfc-tech-innovators/overview',
    5: '/about/indian-affordable-housing',
  }

  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}${FILTER_URL}${innovationType}&[filters][category][$eq]=${makeUpper(L3)}`);
        setAboutContent(response?.data?.data[0]?.attributes || null);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchAboutData();

    const fetchInnovationData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/prelogin-innovations?populate=*&sortId=ImageId`);
        if (response && response?.data?.data?.length) {
          let match = response.data.data.find(d => makeLower(d?.attributes?.imageTitle) === makeLower(L3))?.attributes;
          if (match) setAttributes(match);
        }
      } catch (error) {
        console.error('Error fetching Attributes:', error);
      }
    };
    fetchInnovationData();
    const fetchESGData = async () => {
      try {
        const [response] = await Promise.all([
          axios.get(`${API_BASE_URL}/esg-overview?populate=esgSolutions.esgCatImage`),
        ]);
        setESGContent(response?.data?.data?.attributes);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchESGData();
  }, [L3]);

  const esgSolutionRedirect = (solution) => {
    if (!solution || !solution.esgCatName) return;
    navigate(`/esg/${solution?.esgCatName?.toLowerCase()}`);
  }

  useEffect(() => {
    console.log(aboutContent);
  }, [aboutContent])
  
  return (<>
    <Header />
    
    <NewLoginDialog />
    <Banner heading={makeUpper(L3)} subheading={aboutContent?.typeContentOnTop} breadcrumbs={breadcrumbs} url={attributes?.image?.data?.attributes?.url}/>
    <div className="text-container" style={{paddingBottom: '1rem'}}>
			<div className="esg-text">
				<TruncateText>
					<CustomMarkdown>{aboutContent?.categoryContent}</CustomMarkdown>
				</TruncateText>
			</div>
      {aboutContent?.verticalInfographics?.length > 0 && aboutContent?.verticalInfographics.map((vi, i) => (
        <SolutionInfographics key={i} infographic={vi} />
      ))}
      {aboutContent?.ExibitEntries?.length > 0 && (<>
        <GeneralSlider items={aboutContent?.ExibitEntries} show={2} section={'exhibits'}/>
      </>)}
      {L3?.length && makeLower(L3) === makeLower('Indian Affordable Housing') && esgContent?.esgSolutions?.length > 0 && (
        <div className="esg-solutions-grid">
          {esgContent.esgSolutions.map((sol,i) => (
            <div key={i} className="esg-solution-card" onClick={_ => esgSolutionRedirect(sol)}>
              <div className="esg-solution-card-img" style={{backgroundImage: `url(${sol?.esgCatImage?.data?.attributes?.url})`}} />
              <div className="esg-solution-content">
                <div className="esg-solution-title">{sol?.esgCatName}</div>
                <div className="esg-solution-subtitle">{sol?.esgCatDesc}</div>
              </div>
            </div>
          ))}
        </div>
      )}
      {aboutContent?.verticalInfographics2?.length > 0 && aboutContent?.verticalInfographics2.map((vi, i) => (
        <SolutionInfographics key={i} infographic={vi} />
      ))}
      {aboutContent?.referenceReports?.length > 0 && (
        <SolutionBlogsReports type="report" data={aboutContent?.referenceReports} />
      )}
      {aboutContent?.blogs?.length > 0 && (
        <SolutionBlogsReports type="blog" data={aboutContent?.blogs} />
      )}
    </div>
    {['Global Affordable Housing', 'Indian Affordable Housing'].map(makeLower).includes(L3) && L3?.length > 0 && (
      <EsgImageGrid data={aboutContent?.cards} url_map={url_map}/>
    )}
    <ScrollToTop />
    <Footer />
  </>);
};

export default AboutContent;
