import React, { useState, useEffect } from 'react';
import '../screens/UserProfile.css';
import { Box, TextField, MenuItem, Select, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import user from '../assets/images/userprofile/users.svg';
import mail from '../assets/images/userprofile/mail.svg';
import briefcase from '../assets/images/userprofile/briefcase.svg';
import chevron from '../assets/images/userprofile/chevron-down.svg';

const styles = {
	// container: { display: 'flex', flexDirection: 'column', gap: '2.44rem', paddingLeft: '5.37rem' },
	logoContainer: { display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '1.75rem' },
	logoTextContainer: { display: 'flex', flexDirection: 'column', gap: '0.25rem', justifyContent: 'center', alignItems: 'center' },
	infoContainer: { display: 'flex', flexDirection: 'column', gap: '2rem' },
	textElement: { display: 'flex', flexDirection: 'column', gap: '0.38rem' },
	labelText: { color: "var(--gray-700, #344054)", fontFamily: "Inter", fontSize: "0.875rem", fontStyle: "normal", fontWeight: "500", lineHeight: "1.25rem" },
	textBox: {
		display: 'flex',
		padding: '0rem 0.875rem',
		alignItems: 'center',
		gap: '0.5rem',
		alignSelf: 'stretch',
		borderRadius: '0.5rem',
		border: '1px solid var(--gray-300, #D0D5DD)',
		background: 'var(--White, #FFF)',
		boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
	},
	textInsideBox: {
		color: 'var(--gray-900, #101828)',
		fontFamily: 'Inter',
		fontSize: '1rem',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '1.5rem',
	},
	countrycodecont: {
		"display": "flex",
		"padding": "0.625rem 0.75rem 0.625rem 0rem",
		"justifyContent": "space-between",
		"alignItems": "center",
		"alignSelf": "stretch"
	},
	disabledTextBox: {
		"borderRadius": "8px",
		"border": "1px solid var(--gray-300, #D0D5DD)",
		"background": "var(--gray-50, #F9FAFB)",
		"boxShadow": "0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
	},
	disabledText: {
		"color": "var(--gray-500, #667085)",
		"fontFamily": "Inter",
		"fontSize": "16px",
		"fontStyle": "normal",
		"fontWeight": "400",
		"lineHeight": "24px"
	}
}

const ProfileInfo = ({ onChange }) => {

	const authToken = localStorage.getItem('accessToken')
	const userData = JSON.parse(localStorage.getItem('user'))
	const subscribed = JSON.parse(localStorage.getItem('unsubscribedPersona'))
	const [userDetails, setUserDetails] = useState({
		name: '',
		countrycode: '+',
		phone: '',
		role: '',
		subrole: '',
		email: '',
		firm: '',
		authToken: '',
		unsubscribedPersona: null,
	})

	const roles = {
		developers: 'Developers & Builders',
		suppliers: 'Supply Ecosystem',
		startups: 'Startups & Innovators',
		financiers: 'Financiers & Corporates',
		homebuyer: 'Homebuyers',
		others: 'Others'
	}

	// Case sensitive, do no modify keys or API will not work correctly
	const subRoles = {
		developers: [
			{'Developers and Builders': 'Developers & Builders'}
		],
		suppliers: [
			{'Supply ecosystem': 'Supply Ecosystem'},
			{'Bankers or lawyers': 'Bankers & lawyers'}
		],
		startups: [
			{'Startups': 'Startups & Innovators'}
		],
		financiers: [
			{'Global Investors': 'Global Investors'},
			{'Indian Investors': 'Indian Investors'},
			{'Corporate/Firms': 'Corporate/Firms'},
		],
		homebuyer: [
			{'Homebuyers': 'Homebuyers'}
		]
	}

	const handleSetUserDetails = (event, id) => {
		setUserDetails((prevObj) => ({ ...prevObj, [id]: event.target.value }))
	}

	function getFirstLetters(str) {
		if (!str || !str.length) return 'AM';
		if (str.indexOf(' ') > -1) {
		  return str.toUpperCase().split(' ').slice(0,2).map(word => word.charAt(0))?.join('');
		}
		return str.toUpperCase().substring(0,2);
	}

	useEffect(() => {
		let data = {
		}

		console.log('user: ', userData);

		const processedPhoneNumber = userData?.phoneNumber?.replace(/^(\+91)/, "");
		data.phone = processedPhoneNumber
		data.name = userData?.name
		data.role = userData?.persona
		data.subrole = userData?.personaSubtype
		data.authToken = authToken
		data.email= userData?.email
		data.firm= userData?.firm
		data.authToken= authToken
		data.unsubscribedPersona= subscribed || userData.unsubscribedPersona

		setUserDetails({
			...userDetails,
			...data
		})
	}, [])

	useEffect(() => onChange(userDetails), [userDetails])

	return (
		<div>
			<div style={styles.logoContainer}>
				<Box className="nav-logo-cont"><p className="nav-logo-text">{ getFirstLetters(userData.name) ?? 'AM'}</p></Box>
				<Box sx={styles.logoTextContainer}>
					<p className='user-name'>{userData.name ?? 'User Name'}</p>
					<p className='user-role'>{roles[userData.persona]}</p>
				</Box>
			</div>
			<div style={styles.infoContainer}>

				<div style={styles.textElement}>
					<label htmlFor="name" style={styles.labelText}>Name<span className='required'>*</span></label>
					<TextField id="name" variant="outlined" value={userDetails.name} onChange={(event) => handleSetUserDetails(event, 'name')}
						InputProps={{
							sx: {
								...styles.textBox,
								...styles.textInsideBox,
								paddingLeft: 0
							},
							inputProps: {
								// style: styles.textInsideBox,
							},
						}}
					/>
				</div>

				<div style={styles.textElement}>
					<label htmlFor="phno" style={styles.labelText}>Phone Number</label>
					<TextField id="phno" variant="outlined"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<span style={styles.textInsideBox}>IN</span> <img src={chevron} alt='chevron' />
								</InputAdornment>
							),
							sx: {
								...styles.disabledTextBox,
								...styles.disabledText,
							},
						}}
						value={`${userDetails.countrycode}${userDetails.phone}`}
						onChange={(event) => handleSetUserDetails(event, 'phone')}
						disabled={true}
					/>
				</div>

				<div style={styles.textElement}>
					<label htmlFor="role" style={styles.labelText}>Which category do you fall in?<span className='required'>*</span></label>
					<TextField id="role" variant="outlined" select value={userDetails.role}
						onChange={(event) => handleSetUserDetails(event, 'role')}
						InputProps={{
							startAdornment: (<InputAdornment position="start"> <img src={user} alt="users" /> </InputAdornment>),
							sx: {
								...styles.textBox,
								...styles.textInsideBox,
							},
							inputProps: {
								style: styles.textInsideBox,
							},
						}}
						SelectProps={{
							IconComponent: () => null,
							endAdornment: (
								<InputAdornment position="end" style={{ pointerEvents: 'none' }}>
									<img src={chevron} alt="chevron" />
								</InputAdornment>
							),
						}}
					>
						{Object.entries(roles).map(([cat, label]) => (<MenuItem key={label} value={cat}>{label}</MenuItem>))}
					</TextField>
				</div>

				<div style={styles.textElement}>
					<label htmlFor="sub-role" style={styles.labelText}>What describes you the best?<span className='required'>*</span></label>
					{["developers", "suppliers", "startups", "financiers", "homebuyer"].includes(userDetails.role) && 
						<RadioGroup
							id="sub-role"
							name="subrole"
							value={userDetails.subrole}
							onChange={(event) => handleSetUserDetails(event, 'subrole')}
						>
							{subRoles[userDetails.role].map((sr, index, role) => {
								const [checkedrole] = Object.keys(role[index])
								return (
									<FormControlLabel
										key={index}
										value={Object.keys(sr)[0]}
										control={<Radio />}
										label={Object.values(sr)[0]}
										checked={userDetails.subrole === checkedrole}
									/>
								)
								})}
						</RadioGroup>
					}
					{userDetails.role === 'others' && (
						<TextField id="role" variant="outlined" placeholder='Enter other value' value={userDetails.subrole}
							onChange={(event) => handleSetUserDetails(event, 'subrole')}
							InputProps={{
								// startAdornment: (<InputAdornment position="start"> <img src={mail} alt="email" />	</InputAdornment>),
								sx: {
									...styles.textBox,
									...styles.textInsideBox,
								},
								inputProps: {
									style: styles.textInsideBox,
								},
							}}
						/>
					)}
				</div>

				<div style={styles.textElement}>
					<label htmlFor="email" style={styles.labelText}>Email</label>
					<TextField id="email" variant="outlined" placeholder='Enter email' value={userDetails.email}
						onChange={(event) => handleSetUserDetails(event, 'email')}
						InputProps={{
							startAdornment: (<InputAdornment position="start"> <img src={mail} alt="email" />	</InputAdornment>),
							sx: {
								...styles.textBox,
								...styles.textInsideBox,
							},
							inputProps: {
								style: styles.textInsideBox,
							},
						}}
					/>
				</div>

				<div style={styles.textElement}>
					<label htmlFor="firm" style={styles.labelText}>Name of the Firm</label>
					<TextField id="firm" variant="outlined" placeholder='Enter name of the firm'

						value={userDetails.firm}
						onChange={(event) => handleSetUserDetails(event, 'firm')}
						InputProps={{
							startAdornment: (<InputAdornment position="start"> <img src={briefcase} alt="firm" />	</InputAdornment>),
							sx: {
								...styles.textBox,
								...styles.textInsideBox,
							},
							inputProps: {
								style: styles.textInsideBox,
							},
						}}
					/>
				</div>

			</div>
		</div>
	)
}

export default ProfileInfo;