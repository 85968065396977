import React, { useEffect, useState } from "react";
import "../screens/UserProfile.css";
import { Typography, Button } from "@mui/material";
import ConfirmModal from "./ConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import { submitUnsubscribe } from "../redux/slice/unsubscribeSlice";
import { submitDelete } from "../redux/slice/personaDelSlice";
import { clearFormData } from "../redux/slice/personaRegSlice";
import { useNavigate } from "react-router-dom";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    paddingLeft: "2.5rem",
    marginTop: "1rem",
  },
  heading: {
    display: "flex",
    justifyContent: "flex-start",
    color: "var(--gray-900, #101828)",
    textAlign: "center",
    fontFamily: "Syne",
    fontSize: "1.875rem",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "2.375rem",
    margin: 0,
  },
  description: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    color: "var(--gray-600, #475467)",
    fontFamily: "Inter",
    fontSize: "1.25rem",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "1.875rem",
    margin: '1rem 0',
  },
  subHeading: {
    color: "var(--gray-800, #1D2939)",
    fontFamily: "Inter",
    fontSize: "1.25rem",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "1.875rem",
    margin: '1rem 0',
  },
  outlinedButton: {
    display: "flex",
    width: "6.875rem",
    padding: "0.625rem 1rem",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem",
    borderRadius: "1.875rem",
    border: "1px solid var(--gray-300, #D0D5DD)",
    background: "var(--White, #FFF)",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  },
  outlinedText: {
    color: "var(--gray-700, #344054)",
    fontFamily: "Inter",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "1.25rem",
    textTransform: "none",
  },
  containedButton: {
    display: "flex",
    padding: "0.625rem 1rem",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem",
    borderRadius: "2.5rem",
    border: "1px solid #0C4ACF",
    background: "#0C4ACF",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  },
  containedText: {
    color: "var(--White, #FFF)",
    fontFamily: "Inter",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "1.25rem",
    textTransform: "none",
  },
};

const DeactivateAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [unsubscribedPersonaLS, setUnsubscribedPersonaLS] = useState(true);
  const authToken = localStorage.getItem("accessToken");
  const userData = JSON.parse(localStorage.getItem("new_user"));
  const [modal, setModal] = useState({
    modalState: false,
    isUnsubscribe: true,
  });

  const handleLogout = () => {
    localStorage.clear();
    navigate("/", { replace: true });
    console.log("logout");
    dispatch(clearFormData());
  };
  useEffect(() => {
    // debugger;
    if (localStorage.getItem('unsubscribed') === undefined) return;
    try {
      let up = JSON.parse(localStorage.getItem('unsubscribed'));
      setUnsubscribedPersonaLS(up);
    } catch {}
  }, [])

  return (
    <div>
      <p style={styles.heading}>Keeping your data safe</p>
      <p style={styles.description}>
        Nothing is more important to us than the safety and security of the
        community. People put trust in us by sharing information related to
        their businesses on BLEU Circle. So we will never make any compromises
        when it comes to safeguarding your data.
      </p>
      {unsubscribedPersonaLS ? (
        <>
          <span style={styles.subHeading}> Subscribe to BLEU Circle </span>
          <p style={styles.description}>
            Subscribe to Bleu Circle for cutting-edge solutions and insights
            across the entire real estate lifecycle. Access innovative tools and
            information that redefine the way you approach real estate, ensuring
            you stay ahead in a dynamic industry. Join a community committed to
            revolutionising the future of real estate.
            <br />
          </p>
        </>
      ) : (
        <>
          <span style={styles.subHeading}> Unsubscribe from BLEU Circle </span>
          <p style={styles.description}>
            {/* Hi {userData.name},<br /> */}
            We are sorry to hear that you would like to unsubscribe from Bleu Circle platform. Please note you will not be able to receive any further communications from us if you choose to unsubscribe.
            <br />
          </p>
        </>
      )}
      <div className="actions-container">
        <div />
        <div className="actions-buttons">
          <div>
            <Button
              variant="contained"
              sx={styles.containedButton}
              onClick={() =>
                setModal({
                  ...modal,
                  isUnsubscribe: true,
                  modalState: true,
                })
              }
            >
              {unsubscribedPersonaLS ? (
                <Typography sx={styles.containedText}>Subscribe</Typography>
              ) : (
                <Typography sx={styles.containedText}>Unsubscribe</Typography>
              )}
            </Button>
          </div>
        </div>
      </div>

      <div>
        <hr />
      </div>

      <p style={styles.subHeading}>Delete account</p>
      <p style={styles.description}>
        We are sorry to hear that you would like to delete your account. Please
        note you will not be able to retrieve any information at a later stage.
      </p>
      <div className="actions-container">
        <div />
        <div className="actions-buttons">
          <div>
            <Button
              variant="contained"
              sx={styles.containedButton}
              onClick={() =>
                setModal({
                  ...modal,
                  isUnsubscribe: false,
                  modalState: true,
                })
              }
            >
              <Typography sx={styles.containedText}>Delete Account</Typography>
            </Button>
          </div>
        </div>
      </div>
      <ConfirmModal
        modalState={modal.modalState}
        isUnsubscrible={modal.isUnsubscribe}
        unsubscribeFlag={unsubscribedPersonaLS}
        onClose={() =>
          setModal({
            ...modal,
            modalState: false,
          })
        }
        onAgree={async (flag) => {
          if (modal.isUnsubscribe) {
            setUnsubscribedPersonaLS(flag)
            let resp = dispatch(
              submitUnsubscribe({
                accessToken: authToken,
                formData: {
                  phoneNumber: userData?.phoneNumber,
                  subPersona: userData?.personaSubtype,
                  unsubscribePersona: flag,
                },
              })
            );
            setModal({
              ...modal,
              modalState: false,
            });
            console.log("resp: ", resp);
          } else {
            let resp = dispatch(
              submitDelete({
                accessToken: authToken,
                formData: {
                  phoneNumber: userData?.phoneNumber,
                  subPersona: userData?.personaSubtype,
                },
              })
            );
            console.log("resp: ", resp);
            handleLogout();
          }
        }}
      />
    </div>
  );
};

export default DeactivateAccount;
