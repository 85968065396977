export const getCredentials = ()=> {
  const environment = process.env.REACT_APP_ENV;

  return {
    grant_type: 'password',
    client_id: "3MVG9znNpg3WgIM4jQbP4Zb8haXlmxExm3l0fK0LdxMrTIU84IQ6WT3SUJqMqp212loApgvTkHgseFZcuwiQ2",
    client_secret: "905A640B893DD2497AABF393CC08570FBE7D3ACE53243D920331A4BC7094D2D1",
    username: "jainsanyam3@deloitte.com.hcaluat", 
    password: "Sanyam@1234",
  };

  if (environment === 'DEV') {

    // salesforce api currently pointing to UAT only as dev account is disabled
    return {
      grant_type: "password",
      client_id: "3MVG9duA.sPEm7m8sfpEZT0wIR8vxbg3j4RSwOJirwLO5KgdeFChCQh_OwJMhSIYki5NjdxSKl8DPnoaRilq2",
      client_secret: "8087325842D3E7F9DFF332C6CFB447887B67D539FC33AF5F7BDDFD7CF8A9C276",
      username: "jainsanyam3@deloitte.com",
      password: "Sanyam@098",
    };
  } else if (environment === 'UAT') {
    return {
      grant_type: 'password',
      client_id: "3MVG9znNpg3WgIM4jQbP4Zb8haXlmxExm3l0fK0LdxMrTIU84IQ6WT3SUJqMqp212loApgvTkHgseFZcuwiQ2",
      client_secret: "905A640B893DD2497AABF393CC08570FBE7D3ACE53243D920331A4BC7094D2D1",
      username: "jainsanyam3@deloitte.com.hcaluat", 
      password: "Sanyam@1234",
    };
  }

  return {};
}






