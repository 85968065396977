import React, {useState, useEffect} from 'react';
import { Typography, Box } from '@mui/material';
import '../App.css';

export default function BleuCircleContent({bleuContent}) {
  return (
    <Box className="bleu-text-container">
        <p className="bleu-text">
          {bleuContent}
        </p>
    </Box>
  )
}
