import React, { useState, useEffect } from "react";
import axios from "axios";

import "../App.css";
import Header from "../components/Header";
import Insights from "../components/Insights";
import Prefooter from "../components/Prefooter";
import Footer from "../components/Footer";
import HeroCarousel from "../components/HeroCarousel";
import HomeInnovativeSolutions from "../components/HomeInnovativeSolutions";
import Personas from "../components/Personas";
import BleuCircleContent from "../components/BleuCircleContent";
import Top10Innovators from "../components/Top10Innovators";
import { useNavigate, useLocation } from "react-router-dom";

import { getStrapiApis } from "../getStrapiApis";
import NewLoginDialog from "../accounts/NewLoginDialog";
import ReactGA from 'react-ga';

const API_BASE_URL = getStrapiApis();

function Prelogin() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  const navigate = useNavigate();
  const [heroTexts, setHeroTexts] = useState(null);
  const [innovations, setInnovations] = useState([]);
  const [content, setContent] = useState([]);
  const [insights, setInsights] = useState([]);
  const [topTech, setTopTech] = useState({});

  const [bleuText, setBleuText] = useState("");
  const [bleuFooter, setBleuFooter] = useState("");

  useEffect(() => {
    // if (localStorage.accessToken) navigate('/home');
    const fetchData = async () => {
      try {
        const [
          heroTextsResponse,
          innovationsResponse,
          contentResponse,
          insightsResponse,
          topTechResponse,
        ] = await Promise.all([
          axios.get(`${API_BASE_URL}/prelogin-hero-texts?populate=*`),

          axios.get(
            `${API_BASE_URL}/prelogin-innovations?populate=*&sortId=ImageId`
          ),

          axios.get(`${API_BASE_URL}/prelogin-contents?sort=contentId`),
          axios.get(
            `${API_BASE_URL}/prelogin-insights?populate=image&populate=imageList.imageFile`
          ),
          axios.get(
            `${API_BASE_URL}/top-tech-innovators-in-housing?populate=TopTechInnovators.companyLogo`
          ),
        ]);

        setHeroTexts(heroTextsResponse.data.data);
        setInnovations(innovationsResponse.data);
        setContent(contentResponse.data);
        setInsights(insightsResponse.data);
        setTopTech(topTechResponse.data?.data?.attributes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (content?.data?.length) {
      setBleuText(content?.data[0]?.attributes?.content);
      setBleuFooter(content?.data[1]?.attributes?.content);
    }
  }, [content]);

  return (
    <div className="prelogin">
      <Header toggleTransparency={true} />
      <NewLoginDialog atRoot={true}/>
      <div className='hero'>
        {heroTexts && heroTexts?.length && <HeroCarousel images={heroTexts} />}
      </div>
      <Personas />
      <BleuCircleContent bleuContent={bleuText} />
      {innovations?.data?.length > 1 && (
        <HomeInnovativeSolutions innoData={innovations.data} />
      )}
      <Top10Innovators content={topTech} />

      {insights?.data?.length &&
        insights.data.length === 5 &&
        innovations?.data?.length > 1 && (
          <Insights insights={insights} innoData={innovations.data} />
        )}
      <Prefooter bleuFooter={bleuFooter} />
      <Footer />
    </div>
  );
}

export default Prelogin;
