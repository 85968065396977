import React from 'react';
import { useNavigate } from "react-router-dom";
import arrow from '../../assets/images/arrow-top-right.svg';

const EsgImageGrid = ({data, url_map}) => {
  const navigate = useNavigate();

  if (!url_map) url_map = {
    1: '/esg/esg-in-housing/esg-trends-in-housing/overview',
    2: '/esg/social/labour-practices-and-ethics/laborers-empowerment-aid-programme-(leap)',
    3: '/esg/governance/esg-reporting-benchmarks/overview',
    4: '/esg/governance/green-building-certifications/overview',
  }
  return (
    <div className="esg-overview-cards">
      {data && data.length > 0 && data.map((d, i) => (
        <div key={i} className="esg-overview-card" onClick={_ => navigate(url_map[d.esgCatId || d.catId])}>
          <div className="esg-overview-bg-img" style={{backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%), url(${d.esgCatImage?.data?.attributes?.url || d.catImage?.data?.attributes?.url})`}} />
          <div className="esg-card-content">
            <div className="esg-card-title-container">
              <div className="esg-card-title">{d.esgCatName || d.catName}</div>
              <div className="esg-card-arrow esg-mobile-only">
                <img alt="Open section" src={arrow} />
              </div>
            </div>
            <div className="esg-card-body">{d.esgCatDesc || d.catDesc}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EsgImageGrid;
