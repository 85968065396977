export const getApiUrl =() =>{
  return "https://hdfccapitaladvisorslimited--hcaluat.sandbox.my.salesforce.com"
    if (process.env.REACT_APP_ENV === "DEV") {
      return process.env.REACT_APP_API_URL_DEV;
    } else if (process.env.REACT_APP_ENV === "PRODUCTION") {
      return process.env.REACT_APP_API_URL_PRODUCTION;
    } else if (process.env.REACT_APP_ENV === "UAT") {
      return process.env.REACT_APP_API_URL_UAT;
    } else {
      // Default API URL or error handling if necessary
      return process.env.REACT_APP_API_URL_DEV;
    }
  }
  