import React, {useState} from 'react'
import { Button, Typography } from '@mui/material';
import '../App.css';
import LoginDialog from "../accounts/LoginDialog";


function Prefooter({bleuFooter}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  return (
    <div className="pre-footer-bg">
      <div className="pre-footer">
        <div className="pre-footer-text render-new-lines">
          {bleuFooter.replace('\n\n', '\n')}
        </div>
        {/* <div className="pre-footer-action">
          <div className="action-button pre-footer-action-button" onClick={_ => localStorage?.accessToken ? console.log('Already loggedin') :handleOpenDialog}>
            Subscribe
          </div>
        </div> */}
      </div>
      <LoginDialog open={dialogOpen} onClose={handleCloseDialog} />
    </div>
  )
}

export default Prefooter