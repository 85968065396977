import { useLocation, useNavigate, useParams } from "react-router-dom";
import NewLoginDialog from "../accounts/NewLoginDialog";
import Banner from "../components/Banner";
import Header from "../components/Header";
import { useEffect, useRef, useState } from "react";
import Sidebar from "../components/Sidebar";
import SolutionTechInnovators from "../components/SolutionTechInnovators";
import SolutionAbout from "../components/SolutionAbout";
import SolutionBlogsReports from "../components/SolutionBlogsReports";
import SolutionNews from "../components/SolutionNews";
import GeneralSlider from "../components/GeneralSlider";
import SolutionInfographics from "../components/SolutionInfographics";
import SolutionCompanies from "../components/SolutionCompanies";
import L5 from "../components/L5";
import SolutionSubcategories from "../components/SolutionSubcategories";
import { getStrapiApis } from "../getStrapiApis";
import axios from "axios";
import scrollIntoView from "scroll-into-view-if-needed";
import Footer from "../components/Footer";

const API_BASE_URL = getStrapiApis();
const FILTER_URL = "/innovation-types?populate=aboutSectionImages.image&populate=aboutLogo&populate=workingPapers&populate=programList.programImage&populate=subSection1.sectionImage&populate=subSection1.sectionReports.reportFile&populate=sectionWithImages.sectionImages.image&populate=publishReports&populate=youtubeVideos&populate=ExibitEntries.image&populate=NewsArticle.newsLink&populate=trending.reportURL&populate=referenceReports.reportUrl&populate=referenceReports.reportFile&populate=blogs.blogUrl&populate=verticalInfographics.url&populate=verticalInfographics2.url&populate=l5Data.verticalInfographics.url&populate=l5Data.verticalInfographics2.url&populate=l5Data.youtubeVideos&populate=l5Data.ExibitEntries.image&populate=l5Data.NewsArticle.newsLink&populate=l5Data.trending.reportURL&populate=l5Data.referenceReports.reportUrl&populate=l5Data.referenceReports.reportFile&populate=l5Data.blogs.blogUrl&populate=sectionWithImages.sectionImages&[filters][innovationType][$eq]=";
const ACADEMIA_URL =  "/innovation-types?populate=aboutSectionImages.image&populate=aboutLogo&populate=workingPapers&populate=programList.programImage&populate=subSection1.sectionImage&populate=subSection1.sectionReports.reportFile&populate=sectionWithImages.sectionImages.image&populate=publishReports&populate=youtubeVideos&populate=ExibitEntries.image&[filters][innovationType][$eq]=Academia&[filters][category][$eq]="
const INNOVATOR_URL = "/top-tech-innovators-in-housing?populate=TopTechInnovators.companyLogo&populate=TopTechInnovators.videoFile";

export default function ESGContent() {
    const location = useLocation();
    const navigate = useNavigate();
    const scrollTarget = useRef(null);

    const [attributes, setAttributes] = useState();
    const [categories, setCategories] = useState();
    const [subCategories, setSubCategories] = useState();

    const [selectedCategory, setSelectedCategory] = useState();
    const [selectedSubCategory, setSelectedSubCategory] = useState();
    const [solution, setSolution] = useState();
    const [techInnovators, setTechInnovators] = useState();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const L1 = location?.pathname?.split('/')?.[1];
    const nav_id = location?.state?.nav_id;
    const {L2, L3, L4} = useParams();
    // console.log({L1}, {L2}, {L3}, {L4});
    function makeLower(text) {
        if (!text?.length || !text.toLowerCase) return ''
        return text.trim().toLowerCase().replaceAll(' ', '-');
    }
    function makeUpper(text) {
        if (!text?.length || !text.trim) return '';
        return text.trim().replaceAll('-', ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }
    const breadcrumb_map = {
        'project-lifecycle' : ['Project Lifecycle', 'project-lifecycle'],
        'esg': ['ESG'],
        'academia': ['Academia'],
        'hdfc-tech-innovators': ['HDFC Tech Innovators', 'hdfc-tech-innovators'],
        'fractional-ownership': ['Fractional Ownership'],
        'financing': ['Financing'],
    }
    let breadcrumbs = [['Home', 'home'], breadcrumb_map[L1] || []];
    if (L2?.length && L1 !== 'academia') breadcrumbs.push([makeUpper(L2)]);

    function getSidebarHeading(attributes) {
        if (attributes?.heading === 'Academia') return 'Academic Institutions'
        if (attributes?.heading === 'HDFC Tech Innovators') return 'Conferences';
        return 'Popular Categories'
    }

    function getAboutHeading(selected) {
        if (selectedSubCategory?.subCategoryName2) {
            if (selectedSubCategory?.subCategoryName2 === 'Overview') {
                return selectedCategory.subCategoryName
            } else {
                return selectedSubCategory?.subCategoryName2;
            }
        }
        if (selectedCategory?.subCategoryName) {
            if (selectedCategory.subCategoryName === 'Overview') {
                return attributes?.imageTitle;
            } else {
                return selectedCategory.subCategoryName
            }
        }
        return selected;
    }
    function handleSidebarSelect(cat) {
        setSelectedCategory(cat);
        navigate(`/${L1}/${makeLower(cat?.subCategoryName)}`);
        scroll();
    }
    function handleSubcategorySelect(subcat) {
        setSelectedSubCategory(subcat);
        if (subcat?.subCategoryName2) {
            navigate(`/${L1}/${makeLower(selectedCategory?.subCategoryName)}/${makeLower(subcat?.subCategoryName2)}`);
        } else {
            navigate(`/${L1}/${makeLower(selectedCategory?.subCategoryName)}`);
        }
    }

    async function fetchAcademies() {
        try {
            const response = await axios.get(`${API_BASE_URL}${ACADEMIA_URL}${selectedCategory?.subCategoryName}`);
            if (response?.data?.data[0]?.attributes) setSolution(response?.data?.data[0]?.attributes)
        } catch (error) {
            console.error("Error fetching Academies:", error);
        }
    }

    async function fetchInnovators() {
        if (techInnovators?.length) return;
        try {
            const response = await axios.get(`${API_BASE_URL}${INNOVATOR_URL}`);
            if (response?.data?.data?.attributes) setTechInnovators(response?.data?.data?.attributes);
        } catch (error) {
            console.error("Error fetching innovators");
        }
    }

    async function fetchCategories() {
        try {
            const response = await axios.get(`${API_BASE_URL}/innovation-sub-categories?populate=subCategory.subCategory2&[filters][categoryName][$eq]=${attributes?.imageTitle}`);
            const sortedCats = response.data?.data[0]?.attributes?.subCategory.sort((a, b) => a.subCategoryID - b.subCategoryID);
            setCategories(sortedCats);
        } catch (error) {
            console.error("Error fetching Categories:", error);
        }
    }
    async function fetchSubCategories() {
        try {
            if (selectedSubCategory?.subCategoryName2?.length) {
                const response = await axios.get(`${API_BASE_URL}${FILTER_URL}${attributes?.imageTitle}&[filters][category][$eq]=${selectedCategory?.subCategoryName}&[filters][subCategoryName2][$eq]=${selectedSubCategory?.subCategoryName2}`);
                if (response?.data?.data[0]?.attributes) setSolution(response?.data?.data[0]?.attributes);
            } else {
                const response = await axios.get(`${API_BASE_URL}${FILTER_URL}${attributes?.imageTitle}&[filters][category][$eq]=${selectedCategory?.subCategoryName}`);
                if (response?.data?.data[0]?.attributes) setSolution(response?.data?.data[0]?.attributes);
            }
        } catch (error) {
            console.error("Error fetching SubCategories:", error);
        }
    }

    useEffect(() => {
        if (!attributes  || makeLower(attributes?.imageTitle) !== makeLower(L2)) {
            const fetchData = async () => {
                try {
                    const response = await axios.get(`${API_BASE_URL}/prelogin-innovations?populate=*&sortId=ImageId`);
                    // console.log(response);
                    if (response && response?.data?.data?.length) {
                        if (L1 === 'esg') {
                            let match = response.data.data.find(d => makeLower(d?.attributes?.imageTitle) === 'esg')?.attributes;
                            if (match) setAttributes(match);
                        } else if (L1 === 'academia') {
                            let match = response.data.data.find(d => makeLower(d?.attributes?.imageTitle) === 'academia')?.attributes;
                            if (match) setAttributes(match);
                        } else if (L1 === 'hdfc-tech-innovators') {
                            let match = response.data.data.find(d => makeLower(d?.attributes?.imageTitle) === 'hdfc-tech-innovators')?.attributes;
                            if (match) setAttributes(match);
                        } else if (L1 === 'fractional-ownership') {
                            let match = response.data.data.find(d => makeLower(d?.attributes?.imageTitle) === 'fractional-ownership')?.attributes;
                            if (match) setAttributes(match);
                        } else if (L1 === 'financing') {
                            let match = response.data.data.find(d => makeLower(d?.attributes?.imageTitle) === 'financing')?.attributes;
                            if (match) setAttributes(match);
                        }
                    }
                } catch (error) {
                    console.error('Error fetching Attributes:', error);
                }
            };
            fetchData();
        }
    }, [L2]);

    useEffect(() => {
        if (!attributes) return
        if (attributes?.imageTitle === 'HDFC Tech Innovators') fetchInnovators();
        fetchCategories();
    }, [attributes, L2]);

    // Use URL Param or auto select first sidebar item
    useEffect(() => {
        if (!categories?.length) return;
        let match = categories.find(sc => makeLower(sc.subCategoryName) === makeLower(L2));
        let selected = match || categories[0];
        setSelectedCategory(selected);
        setSubCategories(selected?.subCategory2?.length ? selected?.subCategory2 : null);
    }, [categories, L3, L4])

    // Use URL Param or auto select first subcategory
    useEffect(() => {
        if (!selectedCategory?.subCategory2?.length) {
            setSelectedSubCategory(null);
            setSubCategories([]);
            attributes?.imageTitle === 'Academia' ? fetchAcademies() : fetchSubCategories();
            return;
        }
        let match = selectedCategory.subCategory2.find(sc => makeLower(sc.subCategoryName2) === makeLower(L3));
        setSelectedSubCategory(match || selectedCategory?.subCategory2[0]);
        setSubCategories(selectedCategory?.subCategory2 || []);
    }, [selectedCategory, L3, L4]);

    useEffect(() => {
        // if (!selectedSubCategory?.subCategoryName2?.length) return;
        attributes?.imageTitle === 'Academia' ? fetchAcademies() : fetchSubCategories();
    }, [selectedSubCategory, L4]);

    // auto scroll if coming from the header and no L5 or L6
    useEffect(() => {
        if (!solution || !nav_id || location?.state?.L5Name?.length || location?.state?.L6Name?.length) return;
        scroll();
    }, [nav_id]);

    function scroll() {
        if (!scrollTarget?.current) return;
        scrollIntoView(scrollTarget.current,  {
            scrollMode: 'if-needed',
            block: 'center',
            inline: 'center',
        });
    }

    return (<>
        <Header />
        <NewLoginDialog />
        <Banner 
            breadcrumbs={breadcrumbs}
            heading={attributes?.imageTitle}
            subheading={solution?.typeContentOnTop}
            url={attributes?.image?.data?.attributes?.url}
        />
        <div className="solutions-container">
            <div className="left-column">
                <Sidebar
                    title={getSidebarHeading(attributes)}
                    toggleCallback={setIsSidebarOpen}
                    action={handleSidebarSelect}
                    items={categories}
                    selected={selectedCategory?.subCategoryID}
                />
            </div>
            <div className={`right-column ${isSidebarOpen ? 'faded' : ''}`}>
                {solution && (<>
                    <SolutionSubcategories items={subCategories} action={handleSubcategorySelect} selected={selectedSubCategory?.subCategoryId2} />

                    <div className="right-column-content" ref={scrollTarget}>
                        <SolutionAbout solution={solution} heading={getAboutHeading(selectedSubCategory?.subCategoryName2)} description={0} />
                    </div>

                    {solution.verticalInfographics && solution.verticalInfographics.length > 0 && solution.verticalInfographics.map((vi, i) => (
                        <SolutionInfographics infographic={vi} key={i} />
                    ))}

                    {solution.subSection1 && solution.subSection1.length > 0 && solution.subSection1.map((s, i) => (
                        <div key={i} className="right-column-content">
                            <SolutionAbout solution={solution} heading={selectedSubCategory?.subCategoryName2} subSectionNumber={i} />
                        </div>
                    ))}

                    {solution.l5Data && solution.l5Data.length > 0 && (
                        /* FIXME selected */
                        <L5 data={solution.l5Data} selected={undefined}  />
                    )}
                    
                    {solution.innovationType === 'HDFC Tech Innovators' && solution.youtubeVideos?.length > 0 && (<>
                        <div className="section-heading">Videos</div>
                        <GeneralSlider items={solution?.youtubeVideos} show={1} section={'videos'}/>
                    </>)}

                    {false && solution?.AssociatedCompanies && (<>
                        <div className="section-heading">Innovative Companies</div>
                        <SolutionCompanies companies={solution?.AssociatedCompanies} />
                    </>)}            

                    {solution.innovationType === 'Academia' && solution?.programList?.length > 0 && (<>
                        {solution?.programListTitle && solution?.programListTitle.length > 0 ? 
                            <div className="esg-exhibit">{solution?.programListTitle}</div> :
                            <div className="section-heading">Projects</div>
                        }
                        <GeneralSlider items={solution.programList} show={1} section={'projects'}/>
                    </>)}

                    {solution.ExibitEntries?.length > 0 && (<>
                        {solution.innovationType === 'HDFC Tech Innovators' && <div className="section-heading">Conference Showcase</div> }
                        <GeneralSlider items={solution.ExibitEntries} show={2} section={'exhibits'}/>
                    </>)}   

                    {solution.verticalInfographics2 && solution.verticalInfographics2.length > 0 && solution.verticalInfographics2.map((vi, i) => (
                        <SolutionInfographics infographic={vi} key={i} />
                    ))}

                    {solution.innovationType !== 'HDFC Tech Innovators' && solution.youtubeVideos?.length > 0 && (<>
                        <div className="section-heading">Videos</div>
                        <GeneralSlider items={solution?.youtubeVideos} show={1} section={'videos'}/>
                    </>)}   

                    {solution.referenceReports?.length > 0 && solution.innovationType !== 'HDFC Tech Innovators' && (
                        <SolutionBlogsReports type="report" data={solution?.referenceReports} />
                    )}
                
                    {solution?.NewsArticle?.length > 0 && (
                        <SolutionNews data={solution?.NewsArticle} />
                    )}

                    {solution.blogs?.length > 0 && (
                        <SolutionBlogsReports type="blog" data={solution?.blogs} />
                    )}

                    {solution.innovationType === 'Academia' && solution?.publishReports?.length > 0 && (<>
                        <SolutionBlogsReports data={solution.publishReports} type={'published_reports'}/>
                    </>)}

                    {solution.innovationType === 'Academia' && solution?.workingPapers?.length > 0 && (<>
                        <SolutionBlogsReports data={solution.workingPapers} type={'working_papers'}/>
                    </>)}

                    {solution.sectionWithImages && solution.sectionWithImages.length > 0 && solution.sectionWithImages.map((s, i) => (
                        <div key={i} className="right-column-content">
                            <SolutionAbout solution={solution} subCategory={selectedSubCategory?.subCategoryName2} imageSectionNumber={i} />
                        </div>
                    ))}

                    {solution.innovationType === 'HDFC Tech Innovators' && techInnovators && selectedCategory.subCategoryName ==="2022" && <>
                        <div className="section-heading">{techInnovators?.heading}</div>
                        {techInnovators && <SolutionTechInnovators techInnovators={techInnovators} /> }
                    </>}
                </>)}
            </div>
        </div>
        <Footer />
    </>)
}