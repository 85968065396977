import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
import scrollIntoView from "scroll-into-view-if-needed";

export default function SolutionSubcategories({items, selected, action}) {
    const [show, setShow] = useState(false);
    useEffect(() => {
        setShow(items?.length > 0)
    }, [items]);

    const idMaker = (str) => {
        if (!str?.length || !str?.toLowerCase) return '';
        return str.toLowerCase().replaceAll(' ', '_').replaceAll('(', '').replaceAll(')', '').replaceAll('-', '');
    }
    
    useEffect(() => {
        let match = document.getElementById(`L4_${selected}`);
        if (match) scrollIntoView(match, {
            scrollMode: 'if-needed',
            block: 'center',
            inline: 'center',
            boundary: document.querySelector('.subcategory-container') 
        });
    }, [selected]);
    
    /* delete below later */
    // function copyToClipboard(text) {
        //     navigator.clipboard.writeText(text).then(_ => {}).catch(_ => {});
        // }
    // const location = useLocation();
    // useEffect(() => {
    //     if (!items?.[selected]) return;
    //     let L4 = items?.[selected]?.subCategoryName2;
    //     copyToClipboard(L4);
    //     console.log(idMaker(L4));
    //     console.log(location.pathname)
    // }, [selected]);
    /* delete above later */

    return (<>
        <div className="subcategory-container">
            {show && items?.map((item, i) => (
                <div 
                    key={i}
                    id={item?.subCategoryName2?.length ? `L4_${item.subCategoryId2}` : ''}
                    className={`subcategory ${selected === item.subCategoryId2 ? 'selected' : ''}`} 
                    onClick={_ => action(item)}
                >
                    <span>{item.subCategoryName2}</span>
                </div>
            ))}
        </div>
    </>)
}
