import React, { useState, useRef, useEffect } from 'react';
import '../styles/truncate.css';

const TruncateText = ({ children, maxLines = 7 }) => {
  const [isClamped, setIsClamped] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(false);
  const containerRef = useRef();
  // let heightBeforeClamping = 0;
  // let isClamped = false;

  useEffect(() => {
    window.addEventListener('resize', setup);
    return () => window.removeEventListener('resize', setup);
  }, []);

  useEffect(() => {
    setup();
  }, [children]);
  
  function setup() {
    const container = containerRef.current;
    setIsClamped(false);
    setHasOverflow(false);
    setTimeout(() => {
      // console.log('before', isClamped, container.clientHeight);
      if (container.clientHeight > 0) {
        setIsClamped(true);
        setTimeout(() => {
          setHasOverflow(container.clientHeight < container.scrollHeight);
          // console.log('after', isClamped, container.scrollHeight + ' -> ' + container.clientHeight);
        }, 500)
      }
    }, 500);
  }

  const toggleTruncate = () => {
    setIsClamped((prev) => !prev);
  };

  return (<>
    <div className={`truncate-content ${isClamped ? 'clamped' : ''}`} ref={containerRef}>
      {children}
    </div>
    {hasOverflow && (
      <button className="read-more" onClick={toggleTruncate}>
        Read {isClamped ? 'More' : 'Less'}
      </button>
    )}
  </>);
};

export default TruncateText;
