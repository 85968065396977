import { useEffect, useRef, useState } from "react";
import SolutionAbout from "./SolutionAbout";
import SolutionInfographics from "./SolutionInfographics";
import SolutionBlogsReports from "./SolutionBlogsReports";
import GeneralSlider from "./GeneralSlider";
import SolutionNews from "./SolutionNews";

import arrow from '../assets/images/chevron-down-blue.svg';
import scrollIntoView from 'scroll-into-view-if-needed';
import CustomMarkdown from "./CustomMarkdown";

export default function L5({data, selected, deepStateData}) {
    const L5ScrollTarget = useRef(null)
    const [selectedL5, setSelectedL5] = useState();
    const [isDrawerOpen, setDrawerOpen] = useState(false);

    function makeLower(str) {
        if (!str || typeof str !== 'string' || !str.toLowerCase || !str.trim) return '';
        return str.trim().toLowerCase();
    }

    useEffect(() => {
        // if (!data?.length) return;
        selectDeepLevels();
    }, [data, deepStateData]);

    const mobileL5Selected = (item) => {
        setSelectedL5(item);
        setDrawerOpen(false);
    }

    const idMaker = (str) => {
        if (!str?.length || typeof str !== 'string') return '';
        return str.trim().toLowerCase().replaceAll(' ', '_').replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(',', '');
    }
    /* delete below later */
    // function copyToClipboard(text) {
    //     navigator.clipboard.writeText(text).then(_ => {}).catch(_ => {});
    // }
    // useEffect(() => {
    //     if (!selectedL5?.catName?.length) return;
    //     copyToClipboard(selectedL5?.catName?.trim());
    //     console.log(idMaker(selectedL5?.catName));
    // }, [selectedL5]);
    /* delete above later */
    function scroll(el) {
        if (!el) return;
        scrollIntoView(el, {
            scrollMode: 'if-needed',
            block: 'center',
            inline: 'center',
        })
    }
    useEffect(() => {
        let match = document.getElementById(`L5_${idMaker(selectedL5?.catName)}`);
        // console.log(selectedL5);
        if (match) scrollIntoView(match, {
            scrollMode: 'if-needed',
            block: 'center',
            inline: 'center',
            boundary: document.querySelector('.l5-controller') 
        });
    }, [selectedL5])

    useEffect(() => {
        let match = document.getElementById(`L4_${selected}`);
        if (match) scrollIntoView(match, {
            scrollMode: 'if-needed',
            block: 'center',
            inline: 'center',
            boundary: document.querySelector('.subcategory-container') 
        });
    }, [selected]);

    function scrollDeepLevels() {
        let L6match = document.getElementById(`L6_${idMaker(deepStateData?.L6Name)}`);
        if (L6match && deepStateData?.L6Name !== 'Overview') {
            scroll(L6match);
        } else {
            scroll(L5ScrollTarget?.current)
        }
    }
    
    function selectDeepLevels() {
        if (!data?.length) setSelectedL5([]);
        if (deepStateData?.L5Name?.length) {
            let L5match = data.find(d => makeLower(d.catName) === makeLower(deepStateData?.L5Name));
            if (L5match) {
                setSelectedL5(L5match);
                scrollDeepLevels();
            } else {
                setSelectedL5(data[0]);    
            }
        } else {
            setSelectedL5(data[0]);
        }
    }

    return (<>
        {data?.length > 0 && <div className="l5">
            <div className="l5-controller">
                {data.map((d, i) => (
                    <div
                        key={i} 
                        className={`l5-control ${d.catId === selectedL5?.catId ? 'active' : ''}`} 
                        onClick={_ => setSelectedL5(d)}
                        id={`L5_${idMaker(d.catName)}`}
                    >
                        {d.catName}
                    </div>
                ))}
            </div>
            <div className={`l5-mobile-controller ${isDrawerOpen ? 'is-open' : ''}`}>
                <div className="l5-mobile-controller-top" onClick={_ => setDrawerOpen(!isDrawerOpen)}>
                    <div>
                        {isDrawerOpen ? 'Select a subcategory' : selectedL5?.catName}
                    </div>
                    <div className="l5-mobile-arrow">
                        <img src={arrow} alt="Open sidebar" />
                    </div>
                </div>
                <div className="l5-mobile-controller-bottom">
                    {data.map((d,i) => (
                        <div key={i} onClick={_ => mobileL5Selected(d)} className={`l5-mobile-control ${d.catId === selectedL5?.catId ? 'selected' : ''}`}>
                            {d.catName}
                        </div>
                    ))}
                </div>
            </div>
            <div className="right-column-content" ref={L5ScrollTarget}>
                <SolutionAbout solution={selectedL5} hideHeading />
            </div>
            {selectedL5?.verticalInfographics?.length > 0 && selectedL5?.verticalInfographics?.map((vi, i) => (
                <SolutionInfographics infographic={vi} key={i} />
            ))}
            {selectedL5?.ExibitEntries?.length > 0 && (<>
                {/* <div className="section-heading">Showcase</div> */}
                <GeneralSlider items={selectedL5?.ExibitEntries} show={2} section={'exhibits'}/>
            </>)}
            {selectedL5?.verticalInfographics2?.length > 0 && selectedL5?.verticalInfographics2?.map((vi, i) => (
                <SolutionInfographics infographic={vi} key={i} />
            ))}
            {selectedL5?.l6Data?.length > 0 && selectedL5?.l6Data?.map((L6, i) => (
                <div className="l6" key={i} id={L6?.catName?.length ? `L6_${idMaker(L6?.catName)}` : ''}>
                    <div className="section-heading">{L6?.catName}</div>
                    <div className="solution-about-body">
                        <CustomMarkdown>{L6?.catAbout}</CustomMarkdown>
                    </div>
                
                    {L6?.verticalInfographics?.length > 0 && L6?.verticalInfographics.map((L6VI, j) => 
                        <SolutionInfographics infographic={L6VI} key={j} />
                    )}
                </div>
            ))}
            {selectedL5?.referenceReports?.length > 0 && (
                <SolutionBlogsReports type="report" data={selectedL5?.referenceReports} sectionTitle={selectedL5.catName} />
            )}
            {selectedL5?.youtubeVideos?.length > 0 && (<>
                <div className="section-heading">
                    Videos
                    <span>{selectedL5.catName}</span>
                </div>
                <GeneralSlider items={selectedL5?.youtubeVideos} show={1} section={'videos'}/>
            </>)}
            {selectedL5?.NewsArticle?.length > 0 && (
                <SolutionNews data={selectedL5?.NewsArticle} sectionTitle={selectedL5.catName} />
            )}
            {selectedL5?.blogs?.length > 0 && (
                <SolutionBlogsReports type="blog" data={selectedL5?.blogs} sectionTitle={selectedL5.catName} />
            )}
            <div className="l5-divider" />
        </div>}
    </>)
}