import React, { useState, useEffect } from "react";
import axios from "axios";
import linkedin from "../assets/images/linkedin.svg";
import "../App.css";
import { getStrapiApis } from "../getStrapiApis";

export default function Footer() {
  const [footerOptions, setFooterOptions] = useState([]);
  const API_BASE_URL = getStrapiApis();
  const openPDF = (url) => {
    window.open(url, "_blank");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/policy-document?populate=policyDocuments&populate=policyDocuments.reportFile`
        );
        const footerData =
          response?.data?.data?.attributes?.policyDocuments?.map((docs) => {
            return {
              title: docs.reportName,
              id: docs.entryNo,
              reportUrl: docs.reportFile?.data?.attributes?.url,
            };
          });
        setFooterOptions(footerData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="footer-bg">
      <div className="footer">
        <div className="footer-dynamic">
          {footerOptions?.map((opt) => (
            <div
              key={opt.id}
              className="footer-link"
              onClick={() => openPDF(opt.reportUrl)}
            >
              {opt.title}
            </div>
          ))}
        </div>
        <div className="footer-static">
          <div className="social" onClick={_ => openPDF('https://www.linkedin.com/company/hdfc-capital/mycompany/')}>
            <img src={linkedin} alt="socialmedia" className="social-img" />
          </div>
        </div>
      </div>
    </div>
  );
}
