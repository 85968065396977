// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosClient from "../../apiAccesss";
import { getCredentials } from '../../getCredentials';


export const fetchAccessToken = createAsyncThunk(
  'auth/fetchAccessToken',
  async (credentials, { rejectWithValue }) => {
    try {
    
      const getCredentialsforAll = getCredentials();
      const formBody = Object.keys(getCredentialsforAll)
        .map(
          key => encodeURIComponent(key) + '=' + encodeURIComponent(getCredentialsforAll[key]),
        )
        .join('&');
      const response = await axiosClient.post("", formBody);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const authSlice = createSlice({
  name: 'auth',
  initialState: {
    accessToken: null,
    error: null,
    loading: 'idle',
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccessToken.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchAccessToken.fulfilled, (state, action) => {
        state.loading = 'idle';
        state.accessToken = action.payload.access_token;
        localStorage.setItem('accessToken', action.payload.access_token);
      })
      .addCase(fetchAccessToken.rejected, (state, action) => {
        state.loading = 'idle';
        state.error = action.payload;
      });
  },
});
export default authSlice.reducer;
