
import axios from 'axios';
import { getApiUrl } from '../getApiUrl';



const apiUrl = getApiUrl();

 const config = {
	
	pre_prod: `${apiUrl}/services/oauth2/token`
}
export const baseURL = config.pre_prod;

const axiosClient = axios.create({
	baseURL: baseURL,
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
		'Access-Control-Allow-Origin': '*',
	}
});

axiosClient.interceptors.request.use(
	async (axiosConf) => {
	
		const token = await localStorage.getItem('authToken');
		if (token) {
			axiosConf.headers.Authorization = `Token ${token}`;
			
		}

		return axiosConf;
	},
	(error) => Promise.reject(error)
);

export default axiosClient

