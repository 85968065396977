import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getApiUrl } from "../../getApiUrl";




export const financialRegistrationForDeveloperSupplierEco = createAsyncThunk(
  "startups/financialRegistrationForDeveloperSupplierEco",
  async (
    { formValuesSecond, accessToken, gettingLoginNums },
    { rejectWithValue }
  ) => {
    const {
      developerGroup,
      contactPerson,
      numberOfVendors,
      valueOfMonthlyPurchase,
      typicalPaymentPeriod,
      submitInterestFor,
    } = formValuesSecond;

    const processedPhoneNumber1 = gettingLoginNums.replace(/^(\+91)/, "");

    const apiUrl = getApiUrl();
    debugger;
    try {
      const url = `${apiUrl}/services/apexrest/financialregistrationForSupplierFunding`;
      const paramData = {
        data: {
          developerGroup:developerGroup,
          contactPerson: contactPerson,
          mobileNumberOfContact: processedPhoneNumber1,
          numberOfVendors:numberOfVendors,
          valueOfMonthlyPurchase:valueOfMonthlyPurchase,
          typicalPaymentPeriod:typicalPaymentPeriod,
          submitInterestFor:submitInterestFor,
          financeType:"Explore financing for your supply ecosystem",
          userName: JSON.parse(localStorage.getItem("new_user")).name
        },
      };
   
      const apiResp = await axios.post(url, JSON.stringify(paramData), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return apiResp;
     
    } catch (error) {

      return rejectWithValue(error.response.data);
    }
  }
);


const apiSlice = createSlice({
  name: "developerSupply",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(financialRegistrationForDeveloperSupplierEco.pending, (state) => {
      // Handle pending state
   
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      financialRegistrationForDeveloperSupplierEco.fulfilled,
      (state, action) => {
      
        // Handle success state
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(
      financialRegistrationForDeveloperSupplierEco.rejected,
      (state, action) => {
   
        // Handle error state
        state.loading = false;
        state.error = action.payload;
      }
    );

  },
});

export default apiSlice.reducer;
