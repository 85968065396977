export const headerItems = [
  {
    id: 'home',
    label: 'Home',
    path: '/home',
  },
  {
    id: 'about_bleu',
    label: 'About',
    children: [
      {
        id: 'global_affordable_housing',
        label: 'Global Affordable Housing',
        path: '/about/global-affordable-housing',
      },
      {
        id: 'indian_affordable_housing',
        label: 'Indian Affordable Housing',
        path: '/about/indian-affordable-housing',
      },
      {
        id: 'about_bleu_circle',
        label: 'About BLEU Circle',
        path: '/about/about-bleu-circle',
      },
      {
        id: 'about_hdfc_capital',
        label: 'About HDFC Capital',
        path: '/about/about-hdfc-capital',
      },
      {
        id: 'hdfc_tech_innovators',
        label: 'HDFC Tech Innovators',
        path: '/hdfc-tech-innovators/',
        children: [
          {
            id: 'hdfc_tech_innovators_overview',
            label: 'Overview',
            path: '/hdfc-tech-innovators/overview'
          },
          {
            id: 'hdfc_tech_innovators_2022',
            label: '2022',
            path: '/hdfc-tech-innovators/2022'
          },
          {
            id: 'hdfc_tech_innovators_2019',
            label: '2019',
            path: '/hdfc-tech-innovators/2019'
          },
        ]
      },
    ]
  },
  {
    id: 'project_lifecycle',
    label: 'Project Lifecycle',
    children: [
      {
        id: 'lifecycle_overview',
        label: 'Overview',
        path: '/project-lifecycle'
      },
      {
        id: 'research_and_data_analytics',
        label: 'Research & Analytics',
        children: [
          {
            id: 'research_platforms',
            label: 'Research Platforms',
            path: '/project-lifecycle/research-and-data-analytics/research-platforms',
          },
          {
            id: 'infrastructure_mapping',
            label: 'Infrastructure Mapping',
            path: '/project-lifecycle/research-and-data-analytics/infra-mapping',
          },
        ],
      },
      {
        id: 'land_sourcing',
        label: 'Land Sourcing',
        children: [
          {
            id: 'sourcing',
            label: 'Sourcing',
            path: '/project-lifecycle/land-sourcing/sourcing'
          },
          {
            id: 'land_surveying',
            label: 'Land Surveying',
            path: '/project-lifecycle/land-sourcing/land-surveying'
          },
          {
            id: 'approvals',
            label: 'Approvals',
            path: '/project-lifecycle/land-sourcing/approvals'
          },
          {
            id: 'legal',
            label: 'Legal',
            path: '/project-lifecycle/land-sourcing/legal'
          },
        ],
      },
      {
        id: 'design',
        label: 'Design',
        children: [
          {
            id: 'arvr_solutions',
            label: 'AR/VR Solutions for design',
            path: '/project-lifecycle/design/ar-vr-solutions-for-design'
          },
          {
            id: 'real_time_design',
            label: 'Real time design collaborations',
            path: '/project-lifecycle/design/real-time-design-collaborations'
          },
          {
            id: 'building_simulations',
            label: 'Building Simulations',
            path: '/project-lifecycle/design/building-simulations'
          },
          {
            id: 'budgeting_boq',
            label: 'Budgeting & BoQ',
            path: '/project-lifecycle/design/budgeting-and-boq'
          },
        ],
      },
      {
        id: 'procurement',
        label: 'Procurement',
        children: [
          {
            id: 'material_procurement',
            label: 'Material Procurement Marketplace',
            path: '/project-lifecycle/procurement/material-procurement-marketplace',
          },
          {
            id: 'equipment_procurement',
            label: 'Equipment & Tool Procurement Marketplace',
            path: '/project-lifecycle/procurement/equipment-and-tool-procurement-marketplace',
          },
          {
            id: 'working_capital',
            label: 'Working Capital Solutions for Procurement',
            path: '/project-lifecycle/procurement/working-capital-solutions-for-procurement',
          },
        ],
      },
      {
        id: 'construction',
        label: 'Construction',
        children: [
          {
            id: 'construction_overview',
            label: 'Overview',
            path: '/project-lifecycle/construction/overview'
          },
          {
            id: 'structure_formwork',
            label: 'Structure and formwork technology',
            children: [
              {
                id: 'structure_formwork_overview',
                label: 'Overview',
                path: '/project-lifecycle/construction/structure-and-formwork-technology/overview',
              },
              {
                id: 'precast',
                label: 'Precast',
                path: '/project-lifecycle/construction/structure-and-formwork-technology/precast',
                children: [
                  {
                    id: 'precast_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: '2d_precast',
                    L5Name: '2D Precast',
                    label: '2D Precast',
                  },
                  {
                    id: '3d_precast',
                    L5Name: '3D Precast',
                    label: '3D Precast',
                  },
                ]
              },
              {
                id: '3d_printing',
                label: '3D Printing',
                path: '/project-lifecycle/construction/structure-and-formwork-technology/3d-printing',
              },
              {
                id: 'engineered_formwork',
                label: 'Engineered Formwork',
                path: '/project-lifecycle/construction/structure-and-formwork-technology/engineered-formwork',
                children: [
                  {
                    id: 'engineered_formwork_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'aluminium_formwork',
                    L5Name: 'Aluminium Formwork',
                    label: 'Aluminium Formwork'
                  },
                  {
                    id: 'tunnel_formwork',
                    L5Name: 'Tunnel Formwork',
                    label: 'Tunnel Formwork'
                  },
                ]
              },
              {
                id: 'sip_formwork',
                label: 'Stay-in-place formwork',
                path: '/project-lifecycle/construction/structure-and-formwork-technology/stay-in-place-formwork',
                children: [
                  {
                    id: 'sip_formwork_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'glass_fiber_gypsum_panels',
                    L5Name: 'Glass Fiber reinforced Gypsum Panel',
                    label: 'Glass Fiber Reinforced Gypsum Panel'
                  },
                  {
                    id: 'pvc_based_formwork',
                    L5Name: 'PVC Based formwork',
                    label: 'PVC Based Formwork'
                  },
                ]
              },
              {
                id: 'steel_structure',
                label: 'Steel Structure',
                path: '/project-lifecycle/construction/structure-and-formwork-technology/steel-structure',
                children: [
                  {
                    id: 'steel_structure_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'pre_engineered_buildings',
                    L5Name: 'Pre engineered buildings',
                    label: 'Pre-Engineered Buildings'
                  },
                  {
                    id: 'lgsf_system',
                    L5Name: 'LGSF System',
                    label: 'LGSF System'
                  }
                ]
              }
            ]
          },
          {
            id: 'automation',
            label: 'Automating Construction',
            children: [
              {
                id: 'automation_overview',
                label: 'Overview',
                path: '/project-lifecycle/construction/automating-construction/overview'
              },
              {
                id: 'robotic_assembly',
                label: 'Robotic Assembly',
                path: '/project-lifecycle/construction/automating-construction/robotic-assembly'
              },
              {
                id: 'autonomous_vehicles',
                label: 'Autonomous Vehicles',
                path: '/project-lifecycle/construction/automating-construction/autonomous-vehicles'
              },
              {
                id: 'iot_solutions',
                label: 'IoT solutions',
                path: '/project-lifecycle/construction/automating-construction/iot-solutions'
              }
            ]
          },
          {
            id: 'homebuilding_solutions',
            label: 'Homebuilding Solutions',
            path: '/project-lifecycle/construction/homebuilding-solutions',
          },
          {
            id: 'green_materials2',
            label: 'Green Materials',
            children: [
              {
                id: 'green_materials_overview2',
                label: 'Overview',
                path: '/project-lifecycle/construction/green-materials/overview'
              },
              {
                id: 'green_cement_and_concrete2',
                label: 'Green cement and concrete',
                path: '/project-lifecycle/construction/green-materials/green-cement-and-concrete',
                children: [
                  {
                    id: 'green_cement_overview2',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'recycled_aggregate_concrete_rac2',
                    label: 'Recycled Aggregate Concrete (RAC)',
                    L5Name: 'Recycled Aggregate Concrete (RAC)',
                  },
                  {
                    id: 'geopolymer_concrete2',
                    label: 'Geopolymer Concrete',
                    L5Name: 'Geopolymer Concrete',
                  },
                  {
                    id: 'selfhealing_concrete2',
                    label: 'Self-Healing Concrete',
                    L5Name: 'Self-Healing Concrete',
                  },
                  {
                    id: 'low_carbon_concrete2',
                    label: 'Low Carbon Concrete',
                    L5Name: 'Low Carbon Concrete',
                  },
                  {
                    id: 'lightweight_concrete2',
                    label: 'Lightweight Concrete',
                    L5Name: 'Lightweight Concrete',
                    children: [
                      {
                        id: 'Lightweight aggregate concrete2',
                        label: 'Lightweight aggregate concrete',
                        L6Name: 'Lightweight aggregate concrete'
                      },
                      {
                        id: 'Lightweight aggregate concrete2',
                        label: 'Lightweight aggregate concrete',
                        L6Name: 'Lightweight aggregate concrete'
                      },
                      {
                        id: 'No-fines concrete2',
                        label: 'No-fines concrete',
                        L6Name: 'No-fines concrete'
                      },
                    ]
                  },
                ]
              },
              {
                id: 'recycled_wood2',
                label: 'Recycled wood',
                path: '/project-lifecycle/construction/green-materials/recycled-wood',
                children: [
                  {
                    id: 'recycled_wood_overview2',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'interior_flooring2',
                    label: 'Interior Flooring',
                    L5Name: 'Interior Flooring',
                  },
                  {
                    id: 'wall_cladding2',
                    label: 'Wall Cladding',
                    L5Name: 'Wall Cladding',
                  },
                  {
                    id: 'furniture_construction2',
                    label: 'Furniture Construction',
                    L5Name: 'Furniture Construction',
                  },
                  {
                    id: 'exterior_decking2',
                    label: 'Exterior Decking',
                    L5Name: 'Exterior Decking',
                  },
                  {
                    id: 'doors_and_windows2',
                    label: 'Doors and Windows',
                    L5Name: 'Doors and Windows',
                  },
                  {
                    id: 'ceiling_beams_and_trusses2',
                    label: 'Ceiling Beams and Trusses',
                    L5Name: 'Ceiling Beams and Trusses',
                  },
                  {
                    id: 'cabinetry_and_shelving2',
                    label: 'Cabinetry and Shelving',
                    L5Name: 'Cabinetry and Shelving',
                  },
                  {
                    id: 'outdoor_landscaping_elements2',
                    label: 'Outdoor Landscaping Elements',
                    L5Name: 'Outdoor Landscaping Elements',
                  },
                  {
                    id: 'feature_walls2',
                    label: 'Feature Walls',
                    L5Name: 'Feature Walls',
                  },
                  {
                    id: 'staircases2',
                    label: 'Staircases',
                    L5Name: 'Staircases',
                  },
                  {
                    id: 'custom_art_installations2',
                    label: 'Custom Art Installations',
                    L5Name: 'Custom Art Installations',
                  },
                  {
                    id: 'sustainable_landscaping2',
                    label: 'Sustainable Landscaping',
                    L5Name: 'Sustainable Landscaping',
                  },
                ]
              },
              {
                id: 'rapidly_renewable_products2',
                label: 'Rapidly Renewable Products',
                path: '/project-lifecycle/construction/green-materials/rapidly-renewable-products',
                children: [
                  {
                    id: 'rapidly_renewable_prods_overview2',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'bamboo2',
                    label: 'Bamboo',
                    L5Name: 'Bamboo',
                  },
                  {
                    id: 'jute2',
                    label: 'Jute',
                    L5Name: 'Jute',
                  },
                  {
                    id: 'coir2',
                    label: 'Coir',
                    L5Name: 'Coir',
                  },
                  {
                    id: 'cork2',
                    label: 'Cork',
                    L5Name: 'Cork',
                  },
                ]
              },
              {
                id: 'high_performance_glass2',
                label: 'High performance glass',
                path: '/project-lifecycle/construction/green-materials/high-performance-glass',
                children: [
                  {
                    id: 'high_performance_glass_overview2',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'insulated_double_glazed_and_triple_glazed2',
                    label: 'Insulated',
                    L5Name: 'Insulated (double glazed and triple glazed)',
                  },
                  {
                    id: 'gas_filled_glazing2',
                    label: 'Gas Filled Glazing',
                    L5Name: 'Gas Filled Glazing',
                  },
                  {
                    id: 'heat_absorbing_tints2',
                    label: 'Heat Absorbing Tints',
                    L5Name: 'Heat Absorbing Tints',
                  },
                  {
                    id: 'low_emissivity_lowe_coatings2',
                    label: 'Low Emissivity (Low-E) Coatings',
                    L5Name: 'Low Emissivity (Low-E) Coatings',
                  },
                  {
                    id: 'spectrally_selective_glass2',
                    label: 'Spectrally Selective Glass',
                    L5Name: 'Spectrally Selective Glass',
                  },
                  {
                    id: 'reflective_coating2',
                    label: 'Reflective coating',
                    L5Name: 'Reflective coating',
                  },
                ]
              },
              {
                id: 'smart_glass2',
                label: 'Smart glass',
                path: '/project-lifecycle/construction/green-materials/smart-glass',
                children: [
                  {
                    id: 'smart_glass_overview2',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'spd_smartglass2',
                    label: 'SPD SmartGlass',
                    L5Name: 'SPD SmartGlass',
                  },
                  {
                    id: 'electrochromic_smartglass2',
                    label: 'Electrochromic SmartGlass',
                    L5Name: 'Electrochromic SmartGlass',
                  },
                  {
                    id: 'pdlc_smartglass2',
                    label: 'PDLC Smartglass',
                    L5Name: 'PDLC Smartglass',
                  },
                  {
                    id: 'thermochromic_smartglass2',
                    label: 'Thermochromic Smartglass',
                    L5Name: 'Thermochromic Smartglass',
                  },
                  {
                    id: 'photochromic_smart_film2',
                    label: 'Photochromic Smart Film',
                    L5Name: 'Photochromic Smart Film',
                  },
                  {
                    id: 'retrofitting_glass_for_better_energy_performance2',
                    label: 'Retrofitting Glass for better energy performance',
                    L5Name: 'Retrofitting Glass for better energy performance',
                  },
                ]
              },
              {
                id: 'sustainable_building_insulation2',
                label: 'Sustainable building insulation',
                path: '/project-lifecycle/construction/green-materials/sustainable-building-insulation',
                children: [
                  {
                    id: 'sustainable_insulation_overview2',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'natural_fibers2',
                    label: 'Natural Fibers',
                    L5Name: 'Natural Fibers',
                  },
                  {
                    id: 'recycled_material_insulation2',
                    label: 'Recycled Material Insulation',
                    L5Name: 'Recycled Material Insulation',
                  },
                  {
                    id: 'biobased_foam2',
                    label: 'Bio-based Foam',
                    L5Name: 'Bio-based Foam',
                  },
                  {
                    id: 'silica_aerogel2',
                    label: 'Silica Aerogel',
                    L5Name: 'Silica Aerogel',
                  },
                  {
                    id: 'earth_based_adobe2',
                    label: 'Earth Based (Adobe)',
                    L5Name: 'Earth Based (Adobe)',
                  },
                  {
                    id: 'mineral_wool2',
                    label: 'Mineral Wool',
                    L5Name: 'Mineral Wool',
                  },
                  {
                    id: 'expanded_cork_board2',
                    label: 'Expanded Cork Board',
                    L5Name: 'Expanded Cork Board',
                  },
                  {
                    id: 'rice_husk2',
                    label: 'Rice husk',
                    L5Name: 'Rice husk',
                  },
                  {
                    id: 'coconut_coir_insulation2',
                    label: 'Coconut coir insulation',
                    L5Name: 'Coconut coir insulation',
                  },
                  {
                    id: 'hempcrete_insulation2',
                    label: 'Hempcrete insulation',
                    L5Name: 'Hempcrete insulation',
                  },
                  {
                    id: 'recycled_pet_insulation2',
                    label: 'Recycled PET insulation',
                    L5Name: 'Recycled PET insulation',
                  },
                ]
              },
              {
                id: 'building_blocks_and_bricks2',
                label: 'Building blocks and bricks',
                path: '/project-lifecycle/construction/green-materials/building-blocks-and-bricks',
                children: [
                  {
                    id: 'building_blocks_overview2',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'fly_ash_bricks22',
                    label: 'Fly ash Bricks',
                    L5Name: 'Fly ash Bricks',
                  },
                  {
                    id: 'agricultural_residue_bricks2',
                    label: 'Agricultural Residue Bricks',
                    L5Name: 'Agricultural Residue Bricks',
                  },
                  {
                    id: 'plastic_bricks_22',
                    label: 'Plastic Bricks',
                    L5Name: 'Plastic Bricks',
                  },
                  {
                    id: 'compressed_stabilized_earth_blocks2',
                    label: 'Compressed Stabilized Earth Blocks',
                    L5Name: 'Compressed Stabilized Earth Blocks',
                  },
                  {
                    id: 'hollow_concrete_blocks_hcb2',
                    label: 'Hollow Concrete Blocks (HCB)',
                    L5Name: 'Hollow Concrete Blocks (HCB)',
                  },
                  {
                    id: 'stabilized_mud_blocks2',
                    label: 'Stabilized Mud Blocks',
                    L5Name: 'Stabilized Mud Blocks',
                  },
                  {
                    id: 'porotherm_smart_bricks2',
                    label: 'Porotherm Smart Bricks',
                    L5Name: 'Porotherm Smart Bricks',
                  },
                  {
                    id: 'comparison_of_bricks2',
                    label: 'Comparison of Bricks',
                    L5Name: 'Comparison of Bricks',
                  },
                ]
              },
              {
                id: 'sustainable_steel2',
                label: 'Sustainable steel',
                path: '/project-lifecycle/construction/green-materials/sustainable-steel',
                children: [
                  {
                    id: 'sustainable_steel_overview2',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'low_embodied_steel2',
                    label: 'Low embodied steel',
                    L5Name: 'Low embodied steel',
                  },
                  {
                    id: 'steel_with_recycled_content2',
                    label: 'Steel with recycled content',
                    L5Name: 'Steel with recycled content',
                  },
                ]
              },
            ],
          },
          {
            id: 'labour_practices2',
            label: 'Labour practices and ethics',
            path: '/project-lifecycle/construction/labour-practices-and-ethics/',
            children: [
              {
                id: 'labour_practices_overview2',
                label: 'Overview',
                path: '/project-lifecycle/construction/labour-practices-and-ethics/overview'
              },
              {
                id: 'labour_empowerment2',
                label: 'Laborers Empowerment Aid Programme (LEAP)',
                path: '/project-lifecycle/construction/labour-practices-and-ethics/laborers-empowerment-aid-programme-(leap)'
              },
              {
                id: 'training_skill_dev2',
                label: 'Training and skill development',
                path: '/project-lifecycle/construction/labour-practices-and-ethics/training-and-skill-development',
              },
              {
                id: 'health_and_safety2',
                label: 'Health and Safety Standards',
                path: '/project-lifecycle/construction/labour-practices-and-ethics/health-and-safety-standards',
                children: [
                  {
                    id: 'health_and_safety_overview2',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'general_safety_ppe2',
                    label: 'General Safety and PPE Training',
                    L5Name: 'General Safety and PPE Training'
                  },
                  {
                    id: 'task_specific_safety2',
                    label: 'Task-Specific Safety Training',
                    L5Name: 'Task-Specific Safety Training'
                  },
                  {
                    id: 'emergency_rt2',
                    label: 'Emergency Response Training',
                    L5Name: 'Emergency Response Training'
                  }
                ]
              },
              {
                id: 'labour_management2',
                label: 'Labour Management',
                path: '/project-lifecycle/construction/labour-practices-and-ethics/labour-management',
                children: [
                  {
                    id: 'Worker Hiring2',
                    label: 'Worker Hiring',
                    L5Name: 'Worker Hiring'
                  },
                  {
                    id: 'Worker / Labour Rental Housing2',
                    label: 'Worker / Labour Rental Housing',
                    L5Name: 'Worker / Labour Rental Housing'
                  },
                  {
                    id: 'Workforce Management & Compliance2',
                    label: 'Workforce Management & Compliance',
                    L5Name: 'Workforce Management & Compliance'
                  },
                ]
              },
            ]
          }
        ],
      },
      {
        id: 'project_management',
        label: 'Project Management',
        children: [
          {
            id: 'digital_pmc',
            label: 'Digital PMC',
            path: '/project-lifecycle/project-management/digital-pmc',
          },
        ],
      },
      {
        id: 'sales',
        label: 'Sales',
        children: [
          {
            id: 'sales_overview',
            label: 'Overview',
            path: '/project-lifecycle/sales/overview'
          },
          {
            id: 'marketing',
            label: 'Digital Marketing',
            children: [
              {
                id: 'marketing_overview',
                label: 'Overview',
                subCategoryName2: 'Overview',
                path: '/project-lifecycle/sales/digital-marketing/overview',
              },
              {
                id: 'ai_digital_mrkt',
                label: 'AI In Digital Marketing',
                path: '/project-lifecycle/sales/digital-marketing/ai-in-digital-marketing',
              },
              {
                id: 'search_engine_optimisation',
                label: 'Search Engine Optimization',
                path: '/project-lifecycle/sales/digital-marketing/search-engine-optimization',
                children: [
                  {
                    id: 'seo_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'on_page_seo',
                    label: 'On-Page SEO',
                    L5Name: 'On-page SEO'
                  },
                  {
                    id: 'off_page_seo',
                    label: 'Off-Page SEO',
                    L5Name: 'Off-page SEO'
                  }
                ]
              },
              {
                id: 'pay_per_click',
                label: 'Pay Per Click (PPC)',
                path: '/project-lifecycle/sales/digital-marketing/pay-per-click-(ppc)',
                children: [
                  {
                    id: 'ppc_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'top_ppc_platforms',
                    label: 'Top PPC Ad Platforms',
                    L5Name: 'Top PPC advertising platforms'
                  },
                  {
                    id: 'ppc_types',
                    label: 'Types of PPC Ads',
                    L5Name: 'Types of PPC Ads'
                  }
                ]
              },
              {
                id: 'content_marketing',
                label: 'Content Marketing',
                path: '/project-lifecycle/sales/digital-marketing/content-marketing'
              },             
            ]
          },
          {
            id: 'native_advertising',
            label: 'Native Advertising',
            path: '/project-lifecycle/sales/native-advertising'
          },
          {
            id: 'email_marketing',
            label: 'Email Marketing',
            path: '/project-lifecycle/sales/email-marketing'
          },     
          {
            id: 'mobile_marketing',
            label: 'Mobile Marketing',
            path: '/project-lifecycle/sales/mobile-marketing/',
            children: [
              {
                id: 'mm_overview',
                label: 'Overview',
                path: '/project-lifecycle/sales/mobile-marketing/overview',
              },
              {
                id: 'SMS_marketing',
                label: 'SMS Marketing',
                path: '/project-lifecycle/sales/mobile-marketing/sms-marketing',
              },
              {
                id: 'social_media_marketing',
                label: 'Social Media Marketing',
                path: '/project-lifecycle/sales/mobile-marketing/social-media-marketing',
              },
              {
                id: 'location_based_marketing',
                label: 'Location (GPS) Based Marketing',
                path: '/project-lifecycle/sales/mobile-marketing/location-(gps)-based-marketing',
              },
              {
                id: 'In_app_marketing',
                label: 'In-app Marketing',
                path: '/project-lifecycle/sales/mobile-marketing/in-app-marketing',
              },
            ]
          },
          {
            id: 'influencer_marketing',
            label: 'Influencer Marketing',
            path: '/project-lifecycle/sales/influencer-marketing',
          },
          {
            id: 'social_media',
            label: 'Social Media Marketing',
            path: '/project-lifecycle/sales/social-media-marketing',
          },
          {
            id: 'customer_engagement',
            label: 'Customer Engagement',
            children: [
              {
                id: 'customer_engagement_overview',
                label: 'Overview',
                path: '/project-lifecycle/sales/customer-engagement/overview'
              },
              {
                id: 'ar_vr_mr_xr',
                label: 'AR/VR/XR for walkthroughs',
                path: '/project-lifecycle/sales/customer-engagement/ar-vr-xr-for-walkthroughs',
                children: [
                  {
                    id: 'ar_vr_mr_xr_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'virtual_reality',
                    label: 'Virtual Reality (VR)',
                    L5Name: 'Virtual Reality (VR)'
                  },
                  {
                    id: 'augmented_reality',
                    label: 'Augmented Reality (AR)',
                    L5Name: 'Augmented Reality (AR)'
                  },
                  {
                    id: 'mixed_reality',
                    label: 'Mixed Reality',
                    L5Name: 'Mixed Reality'
                  },
                  {
                    id: 'gamification',
                    label: 'Gamification of experience',
                    L5Name: 'Gamification of experience'
                  }
                ]
              },{
                id: 'virtual_walkthroughs',
                label: 'Virtual walkthroughs using 3D Videography',
                path: '/project-lifecycle/sales/customer-engagement/virtual-walkthroughs-using-3d-videography'
              },
              {
                id: 'touch_tables',
                label: 'Touch Tables',
                path: '/project-lifecycle/sales/customer-engagement/touch-tables'
              },
              {
                id: 'holographic_models',
                label: 'Holographic Models',
                path: '/project-lifecycle/sales/customer-engagement/holographic-models'
              },
              {
                id: 'drone_videos',
                label: 'Drone Videography',
                path: '/project-lifecycle/sales/customer-engagement/drone-videography'
              },
              {
                id: 'geofencing',
                label: 'Geofencing',
                path: '/project-lifecycle/sales/customer-engagement/geofencing'
              },
              {
                id: 'chatbots',
                label: 'Chatbots',
                path: '/project-lifecycle/sales/customer-engagement/chatbots'
              },
              {
                id: 'other_upcoming_technologies',
                label: 'Other Upcoming Technologies',
                path: '/project-lifecycle/sales/customer-engagement/other-upcoming-technologies',
                children: [
                  {
                    id: 'other_tech_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'digital_twins',
                    label: 'Digital Twins',
                    L5Name: 'Digital Twins',
                  },
                  {
                    id: 'deepfake_tech',
                    label: 'Deepfake Technology',
                    L5Name: 'Deepfake Technology',
                  },
                ]
              },
            ]
          },
          {
            id: 'project_sales_marketing_services',
            label: 'Project Sales and Marketing Services',
            path: '/project-lifecycle/sales/project-sales-and-marketing-services',
          },
          {
            id: 'listing_platforms',
            label: 'Listing Platforms',
            path: '/project-lifecycle/sales/listing-platforms',
          },
          {
            id: 'loyalty_management',
            label: 'Loyalty Management',
            path: '/project-lifecycle/sales/loyalty-management',
          },
          {
            id: 'broker_management_apps',
            label: 'Broker Management',
            path: '/project-lifecycle/sales/broker-management',
          },
        ],
      },
      {
        id: 'post_sales',
        label: 'Post Sales',
        children: [
          {
            id: 'post_sales_overview',
            label: 'Overview',
            path: '/project-lifecycle/post-sales/overview'
          },
          {
            id: 'property_management',
            label: 'Property management',
            path: '/project-lifecycle/post-sales/property-management/',
            children: [
              {
                id: 'property_management_overview',
                label: 'Overview',
                path: '/project-lifecycle/post-sales/property-management/overview'
              },
              {
                id: 'property_management_ai',
                label: 'Artificial Intelligence',
                path: '/project-lifecycle/post-sales/property-management/artificial-intelligence'
              },
              {
                id: 'property_management_immersive',
                label: 'Immersive Technologies',
                path: '/project-lifecycle/post-sales/property-management/immersive-technologies'
              },
              {
                id: 'property_management_iot',
                label: 'Internet of Things (IoT)',
                path: '/project-lifecycle/post-sales/property-management/internet-of-things-(iot)'
              },
              {
                id: 'property_management_sht',
                label: 'Smart home Technology',
                path: '/project-lifecycle/post-sales/property-management/smart-home-technology'
              },
              {
                id: 'property_management_ocp',
                label: 'Online communication platforms',
                path: '/project-lifecycle/post-sales/property-management/online-communication-platforms'
              },
            ],
          },
          {
            id: 'parking_solutions',
            label: 'Parking Solutions',
            path: '/project-lifecycle/post-sales/parking-solutions/',
            children: [
              {
                id: 'parking_solutions_overview',
                label: 'Overview',
                path: '/project-lifecycle/post-sales/parking-solutions/overview',
              },
              {
                id: 'parking_solutions_mechanical',
                label: 'Mechanical Parking',
                path: '/project-lifecycle/post-sales/parking-solutions/mechanical-parking',
              },
              {
                id: 'parking_solutions_automated',
                label: 'Automated Parking',
                path: '/project-lifecycle/post-sales/parking-solutions/automated-parking',
              },
            ],
          },
          {
            id: 'home_interiors',
            label: 'Home Interiors',
            children: [
              {
                id: 'home_interiors_overview',
                label: 'Overview',
                path: '/project-lifecycle/post-sales/home-interiors/overview'
              },
              {
                id: 'home_interiors_services',
                label: 'Home Interior Services',
                path: '/project-lifecycle/post-sales/home-interiors/home-interior-services'
              },
              {
                id: 'home_interior_product',
                label: 'Home Interior Product Online Marketplace',
                path: '/project-lifecycle/post-sales/home-interiors/home-interior-product-online-marketplace'
              },
              {
                id: 'home_interiors_innovations',
                label: 'Innovations in Home Interiors',
                path: '/project-lifecycle/post-sales/home-interiors/innovations-in-home-interiors'
              },
            ],
          },
          {
            id: 'home_automation',
            label: 'Home Automation Solutions',
            path: '/project-lifecycle/post-sales/home-automation-solutions'
          },
        ],
      },
    ],
  },
  {
    id: 'esg',
    label: 'ESG',
    children: [
      // {
      //     id: 'esg_overview_main',
      //     label: 'Overview',
      //     path: '/esg/overview'
      // },
      {
        id: 'esg_in_housing',
        label: 'ESG in Housing',
        children: [
          {
            id: 'esg_in_housing_overview',
            label: 'Overview',
            path: '/esg/overview'
          },
          // {
          //     id: 'need_for_esg',
          //     label: 'Need for ESG in India',
          //     path: '/esg/esg-in-housing/need-for-esg-in-india'
          // },
          // {
          //     id: 'esg_for_housing_sector',
          //     label: 'ESG for Housing Sector',
          //     path: '/esg/esg-in-housing/esg-for-housing-sector'
          // },
          {
            id: 'esg_trends_in_housing',
            label: 'ESG Trends in Housing',
            path: '/esg/esg-in-housing/esg-trends-in-housing',
            children: [
              {
                id: 'esg_trends_in_housing_overview',
                label: 'Overview',
                path: '/esg/esg-in-housing/esg-trends-in-housing/overview',
              },
              {
                id: 'esg_trends_in_housing_green_financing',
                label: 'Green Financing',
                path: '/esg/esg-in-housing/esg-trends-in-housing/green-financing',
              },
            ]
          },
        ]
      },
      {
        id: 'esg_environmental',
        label: 'Environmental',
        children: [
          {
            id: 'esg_environmental_overview',
            label: 'Overview',
            path: '/esg/environmental/overview',
          },
          {
            id: 'renewable_energy',
            label: 'Renewable Energy',
            children: [
              {
                id: 'renewable_energy_overview',
                label: 'Overview',
                path: '/esg/environmental/renewable-energy/overview'
              },
              {
                id: 'solar_energy',
                label: 'Solar Energy',
                path: '/esg/environmental/renewable-energy/solar-energy',
                children: [
                  {
                    id: 'solar_energy_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'solar_rooftop_photovoltaic_pv_systems',
                    label: 'Solar Rooftop Photovoltaic (PV) Systems',
                    L5Name: 'Solar Rooftop Photovoltaic (PV) Systems',
                  },
                  {
                    id: 'building_integrated_photovoltaic_bipv',
                    label: 'Building integrated Photovoltaic (BIPV)',
                    L5Name: 'Building integrated Photovoltaic (BIPV)',
                  },
                  {
                    id: 'solar_water_heating_systems',
                    label: 'Solar Water Heating Systems',
                    L5Name: 'Solar Water Heating Systems',
                  },
                  {
                    id: 'solar_lighting',
                    label: 'Solar Lighting',
                    L5Name: 'Solar Lighting',
                  },
                  {
                    id: 'solar_garden_lights',
                    label: 'Solar Garden Lights',
                    L5Name: 'Solar Garden Lights',
                  },
                  {
                    id: 'solar_pumping_systems',
                    label: 'Solar Pumping Systems',
                    L5Name: 'Solar Pumping Systems',
                  },
                  {
                    id: 'solar_battery_storage_solutions',
                    label: 'Solar Battery Storage Solutions',
                    L5Name: 'Solar Battery Storage Solutions',
                  },
                  {
                    id: 'solarpowered_ev_charging_stations',
                    label: 'Solar-powered EV Charging Stations',
                    L5Name: 'Solar-powered EV Charging Stations',
                  },
                  {
                    id: 'solarpowered_security_systems',
                    label: 'Solar-powered Security Systems',
                    L5Name: 'Solar-powered Security Systems',
                  },
                  {
                    id: 'solarpowered_hvac_systems',
                    label: 'Solar-powered HVAC Systems',
                    L5Name: 'Solar-powered HVAC Systems',
                  },
                  {
                    id: 'solarpowered_community_amenities',
                    label: 'Solar-powered Community Amenities',
                    L5Name: 'Solar-powered Community Amenities',
                  },
                  {
                    id: 'solar_carports_and_canopies',
                    label: 'Solar Carports and Canopies',
                    L5Name: 'Solar Carports and Canopies',
                  },
                ]
              },
              {
                id: 'wind_energy',
                label: 'Wind Energy',
                path: '/esg/environmental/renewable-energy/wind-energy',
                children: [
                  {
                    id: 'wind_energy_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'rooftop_wind_turbines',
                    label: 'Rooftop Wind Turbines',
                    L5Name: 'Rooftop Wind Turbines',
                  },
                  {
                    id: 'standalone_turbines',
                    label: 'Standalone Turbines for Buildings',
                    L5Name: 'Standalone Turbines for Buildings',
                    children: [
                      {
                        id: 'standalone_turbines_overview',
                        label: 'Overview',
                        L6Name: 'Overview'
                      },
                      {
                        id: 'horizontalaxis_wind_turbines_hawts',
                        label: 'Horizontal-Axis Wind Turbines (HAWTs)',
                        L6Name: 'Horizontal-Axis Wind Turbines (HAWTs)'
                      },
                      {
                        id: 'verticalaxis_wind_turbines_vawts',
                        label: 'Vertical-Axis Wind Turbines (VAWTs)',
                        L6Name: 'Vertical-Axis Wind Turbines (VAWTs)'
                      },
                    ]
                  },
                ]
              },
              {
                id: 'hybrid_system',
                label: 'Hybrid System',
                path: '/esg/environmental/renewable-energy/hybrid-system',
                children: [
                  {
                    id: 'hybrid_system_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'solar_wind_hybrid',
                    label: 'Solar Wind Hybrid Systems',
                    L5Name: 'Solar wind Hybrid systems',
                  }
                ]
              },
            ]
          },
          {
            id: 'water_solutions',
            label: 'Water Solutions',
            children: [
              {
                id: 'water_solutions_overview',
                label: 'Overview',
                path: '/esg/environmental/water-solutions/overview'
              },
              {
                id: 'grey_water_recycling',
                label: 'Grey water recycling',
                path: '/esg/environmental/water-solutions/grey-water-recycling',
                children: [
                  {
                    id: 'greywater_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'greywater_treatment_innovations',
                    label: 'Greywater Treatment Innovations',
                    L5Name: 'Greywater Treatment Innovations',
                  },
                  {
                    id: 'smart_monitoring_and_control',
                    label: 'Smart Monitoring and Control',
                    L5Name: 'Smart Monitoring and Control',
                  },
                  {
                    id: 'decentralized_systems',
                    label: 'Decentralized Systems',
                    L5Name: 'Decentralized Systems',
                  },
                ],
              },
              {
                id: 'low_flow_fixtures',
                label: 'Low flow fixtures',
                path: '/esg/environmental/water-solutions/low-flow-fixtures',
              },
              {
                id: 'smart_water_meters',
                label: 'Smart Water Meters',
                path: '/esg/environmental/water-solutions/smart-water-meters',
                children: [
                  {
                    id: 'smart_water_meters_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'smart_water_meters_l5',
                    label: 'Smart Water Meters',
                    L5Name: 'Smart Water Meters',
                  },
                  {
                    id: 'smart_water_meters_dashboard_and_analytics',
                    label: 'Dashboard and Analytics',
                    L5Name: 'Dashboard and Analytics',
                  },
                  {
                    id: 'iot_internet_of_things',
                    label: 'IoT (Internet of Things)',
                    L5Name: 'IoT (Internet of Things)',
                  },
                ],
              },
              {
                id: 'rainwater_harvesting',
                label: 'Rainwater harvesting',
                path: '/esg/environmental/water-solutions/rainwater-harvesting',
                children: [
                  {
                    id: 'rainwater_harvesting_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'rooftop_harvesting',
                    label: 'Rooftop Harvesting',
                    L5Name: 'Rooftop Harvesting',
                  },
                  {
                    id: 'surface_harvesting',
                    label: 'Surface Harvesting',
                    L5Name: 'Surface Harvesting',
                  },
                ],
              },
              {
                id: 'potable_water_solutions',
                label: 'Potable water solutions',
                path: '/esg/environmental/water-solutions/potable-water-solutions',
              },
              {
                id: 'sewage_treatment_solutions',
                label: 'Sewage treatment solutions',
                path: '/esg/environmental/water-solutions/sewage-treatment-solutions',
                children: [
                  {
                    id: 'sewage_treatment_solutions_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'activated_sludge_plant_asp_stp',
                    label: 'Activated Sludge Plant (ASP) STP',
                    L5Name: 'Activated Sludge Plant (ASP) STP',
                  },
                  {
                    id: 'trickling_filter_stp',
                    label: 'Trickling filter STP',
                    L5Name: 'Trickling filter STP',
                  },
                  {
                    id: 'membrane_bioreactor_stp',
                    label: 'Membrane bioreactor STP',
                    L5Name: 'Membrane bioreactor STP',
                  },
                  {
                    id: 'rotating_biological_contractor_stp',
                    label: 'Rotating Biological Contractor STP',
                    L5Name: 'Rotating Biological Contractor STP',
                  },
                ],
              },
              {
                id: 'condensate_water_recovery',
                label: 'Condensate water recovery',
                path: '/esg/environmental/water-solutions/condensate-water-recovery',
              },
              {
                id: 'water_efficient_landscaping',
                label: 'Water efficient landscaping',
                path: '/esg/environmental/water-solutions/water-efficient-landscaping',
              },
              
            ],
          },
          {
            id: 'waste_management',
            label: 'Waste Management',
            children: [
              {
                id: 'waste_management_overview',
                label: 'Overview',
                path: '/esg/environmental/waste-management'
              },
              {
                id: 'organic_waste_treatment',
                label: 'Organic waste treatment',
                path: '/esg/environmental/waste-management/organic-waste-treatment',
                children: [
                  {
                    id: 'organic_waste_mgmt_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'onsite_waste_composter',
                    label: 'Onsite Waste Composter',
                    L5Name: 'Onsite Waste Composter',
                  },
                  {
                    id: 'segregation_bin_systems',
                    label: 'Segregation Bin Systems',
                    L5Name: 'Segregation Bin Systems',
                  },
                ]
              },
              {
                id: 'waste_to_energy_technologies',
                label: 'Waste to energy technologies',
                path: '/esg/environmental/waste-management/waste-to-energy-technologies',
              },
              {
                id: 'construction_and_demolition_waste',
                label: 'Construction and demolition waste',
                path: '/esg/environmental/waste-management/construction-and-demolition-waste',
                children: [
                  {
                    id: 'construction_waste_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'waste_sorting_robots',
                    label: 'Waste Sorting Robots',
                    L5Name: 'Waste Sorting Robots',
                  },
                  {
                    id: '3d_printing_with_recycled_materials',
                    label: '3D Printing with Recycled Materials',
                    L5Name: '3D Printing with Recycled Materials',
                  },
                ],
              },
            ],
          },
          {
            id: 'green_materials',
            label: 'Green Materials',
            children: [
              {
                id: 'green_materials_overview',
                label: 'Overview',
                path: '/esg/environmental/green-materials/overview'
              },
              {
                id: 'green_cement_and_concrete',
                label: 'Green cement and concrete',
                path: '/esg/environmental/green-materials/green-cement-and-concrete',
                children: [
                  {
                    id: 'green_cement_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'recycled_aggregate_concrete_rac',
                    label: 'Recycled Aggregate Concrete (RAC)',
                    L5Name: 'Recycled Aggregate Concrete (RAC)',
                  },
                  {
                    id: 'geopolymer_concrete',
                    label: 'Geopolymer Concrete',
                    L5Name: 'Geopolymer Concrete',
                  },
                  {
                    id: 'selfhealing_concrete',
                    label: 'Self-Healing Concrete',
                    L5Name: 'Self-Healing Concrete',
                  },
                  {
                    id: 'low_carbon_concrete',
                    label: 'Low Carbon Concrete',
                    L5Name: 'Low Carbon Concrete',
                  },
                  {
                    id: 'lightweight_concrete',
                    label: 'Lightweight Concrete',
                    L5Name: 'Lightweight Concrete',
                    children: [
                      {
                        id: 'Lightweight aggregate concrete',
                        label: 'Lightweight aggregate concrete',
                        L6Name: 'Lightweight aggregate concrete'
                      },
                      {
                        id: 'Lightweight aggregate concrete',
                        label: 'Lightweight aggregate concrete',
                        L6Name: 'Lightweight aggregate concrete'
                      },
                      {
                        id: 'No-fines concrete',
                        label: 'No-fines concrete',
                        L6Name: 'No-fines concrete'
                      },
                    ]
                  },
                ]
              },
              {
                id: 'recycled_wood',
                label: 'Recycled wood',
                path: '/esg/environmental/green-materials/recycled-wood',
                children: [
                  {
                    id: 'recycled_wood_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'interior_flooring',
                    label: 'Interior Flooring',
                    L5Name: 'Interior Flooring',
                  },
                  {
                    id: 'wall_cladding',
                    label: 'Wall Cladding',
                    L5Name: 'Wall Cladding',
                  },
                  {
                    id: 'furniture_construction',
                    label: 'Furniture Construction',
                    L5Name: 'Furniture Construction',
                  },
                  {
                    id: 'exterior_decking',
                    label: 'Exterior Decking',
                    L5Name: 'Exterior Decking',
                  },
                  {
                    id: 'doors_and_windows',
                    label: 'Doors and Windows',
                    L5Name: 'Doors and Windows',
                  },
                  {
                    id: 'ceiling_beams_and_trusses',
                    label: 'Ceiling Beams and Trusses',
                    L5Name: 'Ceiling Beams and Trusses',
                  },
                  {
                    id: 'cabinetry_and_shelving',
                    label: 'Cabinetry and Shelving',
                    L5Name: 'Cabinetry and Shelving',
                  },
                  {
                    id: 'outdoor_landscaping_elements',
                    label: 'Outdoor Landscaping Elements',
                    L5Name: 'Outdoor Landscaping Elements',
                  },
                  {
                    id: 'feature_walls',
                    label: 'Feature Walls',
                    L5Name: 'Feature Walls',
                  },
                  {
                    id: 'staircases',
                    label: 'Staircases',
                    L5Name: 'Staircases',
                  },
                  {
                    id: 'custom_art_installations',
                    label: 'Custom Art Installations',
                    L5Name: 'Custom Art Installations',
                  },
                  {
                    id: 'sustainable_landscaping',
                    label: 'Sustainable Landscaping',
                    L5Name: 'Sustainable Landscaping',
                  },
                ]
              },
              {
                id: 'rapidly_renewable_products',
                label: 'Rapidly Renewable Products',
                path: '/esg/environmental/green-materials/rapidly-renewable-products',
                children: [
                  {
                    id: 'rapidly_renewable_prods_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'bamboo',
                    label: 'Bamboo',
                    L5Name: 'Bamboo',
                  },
                  {
                    id: 'jute',
                    label: 'Jute',
                    L5Name: 'Jute',
                  },
                  {
                    id: 'coir',
                    label: 'Coir',
                    L5Name: 'Coir',
                  },
                  {
                    id: 'cork',
                    label: 'Cork',
                    L5Name: 'Cork',
                  },
                ]
              },
              {
                id: 'high_performance_glass',
                label: 'High performance glass',
                path: '/esg/environmental/green-materials/high-performance-glass',
                children: [
                  {
                    id: 'high_performance_glass_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'insulated_double_glazed_and_triple_glazed',
                    label: 'Insulated',
                    L5Name: 'Insulated (double glazed and triple glazed)',
                  },
                  {
                    id: 'gas_filled_glazing',
                    label: 'Gas Filled Glazing',
                    L5Name: 'Gas Filled Glazing',
                  },
                  {
                    id: 'heat_absorbing_tints',
                    label: 'Heat Absorbing Tints',
                    L5Name: 'Heat Absorbing Tints',
                  },
                  {
                    id: 'low_emissivity_lowe_coatings',
                    label: 'Low Emissivity (Low-E) Coatings',
                    L5Name: 'Low Emissivity (Low-E) Coatings',
                  },
                  {
                    id: 'spectrally_selective_glass',
                    label: 'Spectrally Selective Glass',
                    L5Name: 'Spectrally Selective Glass',
                  },
                  {
                    id: 'reflective_coating',
                    label: 'Reflective coating',
                    L5Name: 'Reflective coating',
                  },
                ]
              },
              {
                id: 'smart_glass',
                label: 'Smart glass',
                path: '/esg/environmental/green-materials/smart-glass',
                children: [
                  {
                    id: 'smart_glass_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'spd_smartglass',
                    label: 'SPD SmartGlass',
                    L5Name: 'SPD SmartGlass',
                  },
                  {
                    id: 'electrochromic_smartglass',
                    label: 'Electrochromic SmartGlass',
                    L5Name: 'Electrochromic SmartGlass',
                  },
                  {
                    id: 'pdlc_smartglass',
                    label: 'PDLC Smartglass',
                    L5Name: 'PDLC Smartglass',
                  },
                  {
                    id: 'thermochromic_smartglass',
                    label: 'Thermochromic Smartglass',
                    L5Name: 'Thermochromic Smartglass',
                  },
                  {
                    id: 'photochromic_smart_film',
                    label: 'Photochromic Smart Film',
                    L5Name: 'Photochromic Smart Film',
                  },
                  {
                    id: 'retrofitting_glass_for_better_energy_performance',
                    label: 'Retrofitting Glass for better energy performance',
                    L5Name: 'Retrofitting Glass for better energy performance',
                  },
                ]
              },
              {
                id: 'sustainable_building_insulation',
                label: 'Sustainable building insulation',
                path: '/esg/environmental/green-materials/sustainable-building-insulation',
                children: [
                  {
                    id: 'sustainable_insulation_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'natural_fibers',
                    label: 'Natural Fibers',
                    L5Name: 'Natural Fibers',
                  },
                  {
                    id: 'recycled_material_insulation',
                    label: 'Recycled Material Insulation',
                    L5Name: 'Recycled Material Insulation',
                  },
                  {
                    id: 'biobased_foam',
                    label: 'Bio-based Foam',
                    L5Name: 'Bio-based Foam',
                  },
                  {
                    id: 'silica_aerogel',
                    label: 'Silica Aerogel',
                    L5Name: 'Silica Aerogel',
                  },
                  {
                    id: 'earth_based_adobe',
                    label: 'Earth Based (Adobe)',
                    L5Name: 'Earth Based (Adobe)',
                  },
                  {
                    id: 'mineral_wool',
                    label: 'Mineral Wool',
                    L5Name: 'Mineral Wool',
                  },
                  {
                    id: 'expanded_cork_board',
                    label: 'Expanded Cork Board',
                    L5Name: 'Expanded Cork Board',
                  },
                  {
                    id: 'rice_husk',
                    label: 'Rice husk',
                    L5Name: 'Rice husk',
                  },
                  {
                    id: 'coconut_coir_insulation',
                    label: 'Coconut coir insulation',
                    L5Name: 'Coconut coir insulation',
                  },
                  {
                    id: 'hempcrete_insulation',
                    label: 'Hempcrete insulation',
                    L5Name: 'Hempcrete insulation',
                  },
                  {
                    id: 'recycled_pet_insulation',
                    label: 'Recycled PET insulation',
                    L5Name: 'Recycled PET insulation',
                  },
                ]
              },
              {
                id: 'building_blocks_and_bricks',
                label: 'Building blocks and bricks',
                path: '/esg/environmental/green-materials/building-blocks-and-bricks',
                children: [
                  {
                    id: 'building_blocks_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'fly_ash_bricks2',
                    label: 'Fly ash Bricks',
                    L5Name: 'Fly ash Bricks',
                  },
                  {
                    id: 'agricultural_residue_bricks',
                    label: 'Agricultural Residue Bricks',
                    L5Name: 'Agricultural Residue Bricks',
                  },
                  {
                    id: 'plastic_bricks_2',
                    label: 'Plastic Bricks',
                    L5Name: 'Plastic Bricks',
                  },
                  {
                    id: 'compressed_stabilized_earth_blocks',
                    label: 'Compressed Stabilized Earth Blocks',
                    L5Name: 'Compressed Stabilized Earth Blocks',
                  },
                  {
                    id: 'hollow_concrete_blocks_hcb',
                    label: 'Hollow Concrete Blocks (HCB)',
                    L5Name: 'Hollow Concrete Blocks (HCB)',
                  },
                  {
                    id: 'stabilized_mud_blocks',
                    label: 'Stabilized Mud Blocks',
                    L5Name: 'Stabilized Mud Blocks',
                  },
                  {
                    id: 'porotherm_smart_bricks',
                    label: 'Porotherm Smart Bricks',
                    L5Name: 'Porotherm Smart Bricks',
                  },
                  {
                    id: 'comparison_of_bricks',
                    label: 'Comparison of Bricks',
                    L5Name: 'Comparison of Bricks',
                  },
                ]
              },
              {
                id: 'sustainable_steel',
                label: 'Sustainable steel',
                path: '/esg/environmental/green-materials/sustainable-steel',
                children: [
                  {
                    id: 'sustainable_steel_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'low_embodied_steel',
                    label: 'Low embodied steel',
                    L5Name: 'Low embodied steel',
                  },
                  {
                    id: 'steel_with_recycled_content',
                    label: 'Steel with recycled content',
                    L5Name: 'Steel with recycled content',
                  },
                ]
              },
            ],
          },
          {
            id: 'green_system_and_solutions',
            label: 'Green system and solutions',
            children: [
              {
                id: 'green_system_and_solutions_overview',
                label: 'Overview',
                path: '/esg/environmental/green-system-and-solutions/overview'
              },
              {
                id: 'sustainable_roofing_systems',
                label: 'Sustainable roofing systems',
                path: '/esg/environmental/green-system-and-solutions/sustainable-roofing-systems',
                children: [
                  {
                    id: 'sustainble_roofing_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'green_roofing_systems',
                    label: 'Green Roofing Systems',
                    L5Name: 'Green Roofing Systems',
                  },
                  {
                    id: 'high_sri_roofs/white/cool_roofs',
                    label: 'High SRI Roofs/White/Cool Roofs',
                    L5Name: 'High SRI Roofs/White/Cool Roofs',
                  },
                  {
                    id: 'solar_panel_roofs',
                    label: 'Solar panel roofs',
                    L5Name: 'Solar panel roofs',
                  },
                ]
              },
              {
                id: 'green_flooring_options',
                label: 'Green flooring options',
                path: '/esg/environmental/green-system-and-solutions/green-flooring-options',
                children: [
                  {
                    id: 'green_flooring_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'cork_flooring',
                    label: 'Cork flooring',
                    L5Name: 'Cork flooring',
                  },
                  {
                    id: 'linoleum_flooring',
                    label: 'Linoleum Flooring',
                    L5Name: 'Linoleum Flooring',
                  },
                  {
                    id: 'polished_concrete',
                    label: 'Polished Concrete',
                    L5Name: 'Polished Concrete',
                  },
                ]
              },
              {
                id: 'boards_panels_false_ceiling_&_plaster',
                label: 'Boards, Panels, False Ceiling & Plaster',
                path: '/esg/environmental/green-system-and-solutions/boards-panels-false-ceiling-&-plaster'
              },
            ],
          },
          {
            id: 'climate_construction',
            label: 'Climate resilient construction',
            children: [
              {
                id: 'climate_construction_overview',
                label: 'Overview',
                path: '/esg/environmental/climate-resilient-construction/overview'
              },
              {
                id: 'flood_risk_management',
                label: 'Flood Risk Management',
                path: '/esg/environmental/climate-resilient-construction/flood-risk-management',
                children: [
                  {
                    id: 'flood_risk_mgmt_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'flood_barriers_and_gates',
                    label: 'Flood Barriers and Gates',
                    L5Name: 'Flood Barriers and Gates',
                  },
                  {
                    id: 'drainage_systems',
                    label: 'Drainage Systems',
                    L5Name: 'Drainage Systems',
                  },
                ]
              },
              {
                id: 'extreme_heat_mitigation',
                label: 'Extreme Heat Mitigation',
                path: '/esg/environmental/climate-resilient-construction/extreme-heat-mitigation',
                children: [
                  {
                    id: 'extreme_heat_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'drought_tolerant_landscaping',
                    label: 'Drought Tolerant Landscaping',
                    L5Name: 'Drought Tolerant Landscaping',
                  },
                ]
              },
              {
                id: 'earthquake_resistant_structure',
                label: 'Earthquake resistant structure',
                path: '/esg/environmental/climate-resilient-construction/earthquake-resistant-structure',
              },
              
            ],
          },
          {
            id: 'energy_efficiency',
            label: 'Energy Efficiency',
            children: [
              {
                id: 'energy_efficiency_overview',
                label: 'Overview',
                path: '/esg/environmental/energy-efficiency/overview'
              },
              {
                id: 'efficient_lighting',
                label: 'Efficient lighting',
                path: '/esg/environmental/energy-efficiency/efficient-lighting',
                children: [
                  {
                    id: 'efficient_lighting_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'tubes_and_bulbs',
                    label: 'Tubes and Bulbs',
                    L5Name: 'Tubes and Bulbs',
                  },
                  {
                    id: 'dimmable_cfls',
                    label: 'Dimmable CFLs',
                    L5Name: 'Dimmable CFLs',
                  },
                  {
                    id: 'interior_lighting',
                    label: 'Interior Lighting',
                    L5Name: 'Interior Lighting',
                  },
                  {
                    id: 'exterior_lighting',
                    label: 'Exterior Lighting',
                    L5Name: 'Exterior Lighting',
                  },
                  {
                    id: 'skylights',
                    label: 'Skylights',
                    L5Name: 'Skylights',
                  },
                  {
                    id: 'roof_monitors',
                    label: 'Roof Monitors',
                    L5Name: 'Roof Monitors',
                  },
                  {
                    id: 'tubular_daylight_devices',
                    label: 'Tubular Daylight Devices',
                    L5Name: 'Tubular Daylight Devices',
                  },
                  {
                    id: 'light_ducts',
                    label: 'Light Ducts',
                    L5Name: 'Light Ducts',
                  },
                  {
                    id: 'reflective_blinds',
                    label: 'Reflective Blinds',
                    L5Name: 'Reflective Blinds',
                  },
                  {
                    id: 'Comparison of Lighting Systems',
                    label: 'Comparison of Lighting Systems',
                    L5Name: 'Comparison of Lighting Systems',
                  },
                ]
              },
              {
                id: 'sensors_and_controls',
                label: 'Sensors and Controls',
                path: '/esg/environmental/energy-efficiency/sensors-and-controls',
                children: [
                  {
                    id: 'sensors_controls_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'daylight_sensor',
                    label: 'Daylight Sensor',
                    L5Name: 'Daylight Sensor',
                  },
                  {
                    id: 'occupancy_sensors',
                    label: 'Occupancy Sensors',
                    L5Name: 'Occupancy Sensors',
                  },
                  {
                    id: 'timerbased_controls',
                    label: 'Timer-based controls',
                    L5Name: 'Timer-based controls',
                  },
                  {
                    id: 'dimmable_controls',
                    label: 'Dimmable Controls',
                    L5Name: 'Dimmable Controls',
                  },
                ]
              },
              {
                id: 'energy_management_systems',
                label: 'Energy management systems',
                path: '/esg/environmental/energy-efficiency/energy-management-systems',
                children: [
                  {
                    id: 'ems_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'sensors_&_controls_ems',
                    label: 'Sensors & Controls',
                    L5Name: 'Sensors & Controls',
                  },
                  {
                    id: 'smart_metering_ems',
                    label: 'Smart Metering',
                    L5Name: 'Smart Metering',
                  },
                ]
              },
              {
                id: 'efficient_building_pumps_and_motors',
                label: 'Efficient building pumps and motors',
                path: '/esg/environmental/energy-efficiency/efficient-building-pumps-and-motors',
                children: [
                  {
                    id: 'efficient_building_pumps_and_motors_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'variable_speed_pumps',
                    label: 'Variable Speed Pumps',
                    L5Name: 'Variable Speed Pumps',
                  },
                  {
                    id: 'magnetic_drive_pumps',
                    label: 'Magnetic Drive Pumps',
                    L5Name: 'Magnetic Drive Pumps',
                  },
                  {
                    id: 'multistage_pumps',
                    label: 'Multistage Pumps',
                    L5Name: 'Multistage Pumps',
                  },
                  {
                    id: 'positive_displacement_pumps',
                    label: 'Positive Displacement Pumps',
                    L5Name: 'Positive Displacement Pumps',
                  },
                  {
                    id: 'heat_pump_systems',
                    label: 'Heat Pump Systems',
                    L5Name: 'Heat Pump Systems',
                  },
                  {
                    id: 'solar_pumps',
                    label: 'Solar Pumps',
                    L5Name: 'Solar Pumps',
                  },
                  {
                    id: 'Motors22',
                    label: 'Motors',
                    L5Name: 'Motors',
                    children: [
                      {
                        id: "Premium Efficiency Motors (IE3 and IE4)",
                        label: "Premium Efficiency Motors (IE3 and IE4)",
                        L6Name: "Premium Efficiency Motors (IE3 and IE4)"
                      },
                      {
                        id: "Energy Efficient Elevators",
                        label: "Energy Efficient Elevators",
                        L6Name: "Energy Efficient Elevators"
                      },
                      {
                        id: "Variable Frequency Drives (VFDs)",
                        label: "Variable Frequency Drives (VFDs)",
                        L6Name: "Variable Frequency Drives (VFDs)"
                      },
                    ]
                  },
                ]
              },
              {
                id: 'cooling_systems',
                label: 'Cooling systems',
                path: '/esg/environmental/energy-efficiency/cooling-systems',
                children: [
                  {
                    id: 'cooling_systems_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'cooling_and_ventilation',
                    label: 'Cooling and Ventilation',
                    L5Name: 'Cooling and Ventilation',
                    children: [
                      {
                        id: "High Efficiency Chillers",
                        label: "High Efficiency Chillers",
                        L6Name: "High Efficiency Chillers"
                      },
                      {
                        id: "Evaporative Coolers",
                        label: "Evaporative Coolers",
                        L6Name: "Evaporative Coolers"
                      },
                      {
                        id: 'economizers',
                        label: 'Economizers',
                        L6Name: 'Economizers'
                      },
                      {
                        id: 'Energy Recovery Ventilators (ERV)',
                        label: 'Energy Recovery Ventilators (ERV)',
                        L6Name: 'Energy Recovery Ventilators (ERV)'
                      },
                      {
                        id: 'Demand Controlled Ventilation',
                        label: 'Demand Controlled Ventilation',
                        L6Name: 'Demand Controlled Ventilation'
                      },
                    ]
                  },
                  {
                    id: 'air_distribution',
                    label: 'Air Distribution',
                    L5Name: 'Air Distribution',
                    children: [
                      {
                        id: 'Variable Air Volume Systems',
                        label: 'Variable Air Volume Systems',
                        L6Name: 'Variable Air Volume Systems'
                      },
                      {
                        id: 'Split and VRF Systems',
                        label: 'Split and VRF Systems',
                        L6Name: 'Split and VRF Systems'
                      },
                    ]
                  },
                  {
                    id: 'geothermal_systems',
                    label: 'Geothermal Systems',
                    L5Name: 'Geothermal Systems',
                  },
                ]
              },
              {
                id: 'passive_design',
                label: 'Passive design',
                path: '/esg/environmental/energy-efficiency/passive-design',
                children: [
                  {
                    id: 'passive_design_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'orientation_and_layout',
                    label: 'Orientation and Layout',
                    L5Name: 'Orientation and Layout',
                  },
                  {
                    id: 'high_performance_window_systems',
                    label: 'High Performance Window Systems',
                    L5Name: 'High Performance Window Systems',
                  },
                  {
                    id: 'louvers_and_other_shading_systems',
                    label: 'Louvers and Other Shading Systems',
                    L5Name: 'Louvers and Other Shading Systems',
                    children: [
                      {
                        id: 'Fixed Louvers',
                        label: 'Fixed Louvers',
                        L6Name: 'Fixed Louvers'
                      },
                      {
                        id: 'Operable Louvers',
                        label: 'Operable Louvers',
                        L6Name: 'Operable Louvers'
                      },
                      {
                        id: 'Solar-tracking Louvers',
                        label: 'Solar-tracking Louvers',
                        L6Name: 'Solar-tracking Louvers'
                      },
                      {
                        id: 'Integrated Louver Systems',
                        label: 'Integrated Louver Systems',
                        L6Name: 'Integrated Louver Systems'
                      },
                      {
                        id: 'Vegetated Louver Systems',
                        label: 'Vegetated Louver Systems',
                        L6Name: 'Vegetated Louver Systems'
                      },
                    ]
                  },
                ]
              },
            ],
          },
        ]
      },
      {
        id: 'esg_social',
        label: 'Social',
        children: [
          {
            id: 'esg_social_overview',
            label: 'Overview',
            path: '/esg/social/overview',
          },
          {
            id: 'occupant_health',
            label: 'Occupant Health and Wellbeing',
            path: '/esg/social/occupant-health-and-wellbeing/',
            children: [
              {
                id: 'occupant_health_overview',
                label: 'Overview',
                path: '/esg/social/occupant-health-and-wellbeing/overview'
              },
              {
                id: 'indoor_air_quality_mgmt',
                label: 'Indoor Air Quality Management',
                path: '/esg/social/occupant-health-and-wellbeing/indoor-air-quality-management',
                children: [
                  {
                    id: 'indoor_aq_management_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'air_purification_tech',
                    label: 'Air Purification Technologies',
                    L5Name: 'Air Purification Technologies',
                    children: [
                      {
                        id: 'hepa_filters',
                        label: 'HEPA Filters',
                        L6Name: 'HEPA Filters',
                      },
                      {
                        id: 'activated_carbon',
                        label: 'Activated Carbon',
                        L6Name: 'Activated Carbon',
                      },
                      {
                        id: 'uv_c_systems',
                        label: 'UV C Systems',
                        L6Name: 'UV C Systems',
                      }
                    ]
                  },
                  {
                    id: 'low_voc_non_toxic',
                    label: 'Low VOC & Non-Toxic Paints, Sealants and Adhesives',
                    L5Name: 'Low VOC & Non-Toxic Paints, Sealants and Adhesives'
                  },
                  {
                    id: 'biophilic_concepts',
                    label: 'Biophilic Concepts Green Wall',
                    L5Name: 'Biophilic Concepts Green Wall',
                    children: [
                      {
                        id: 'biophilic_concepts_overview',
                        label: 'Overview',
                        L6Name: 'Overview'
                      },
                      {
                        id: 'green_facades',
                        label: 'Green Facades',
                        L6Name: 'Green Facades',
                      },
                      {
                        id: 'living_walls',
                        label: 'Living Walls',
                        L6Name: 'Living Walls',
                      },
                      {
                        id: 'bio_walls',
                        label: 'Bio Walls',
                        L6Name: 'Bio Walls',
                      }
                    ],
                  },
                ],
              },
              {
                id: 'acoustics_comfort',
                label: 'Acoustic Comfort',
                path: '/esg/social/occupant-health-and-wellbeing/acoustic-comfort',
                children: [
                  {
                    id: 'acoustic_comfort_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'sound_insulation_materials',
                    label: 'Sound Insulation Materials',
                    L5Name: 'Sound Insulation Materials',
                  },
                  {
                    id: 'acoustic_panel',
                    label: 'Acoustic Panels',
                    L5Name: 'Acoustic Panels',
                    children: [
                      {
                        id: 'acoustic_panel_overview',
                        label: 'Overview',
                        L6Name: 'Overview'
                      },
                      {
                        id: 'composite_acoustic_panels',
                        label: 'Composite Acoustic Panels',
                        L6Name: 'Composite Acoustic Panels',
                      },
                      {
                        id: 'fabric_acoustic_panels',
                        label: 'Fabric Acoustic Panels',
                        L6Name: 'Fabric Acoustic Panels',
                      },
                      {
                        id: 'grooved_wooden_slats',
                        label: 'Grooved Wooden Slats',
                        L6Name: 'Grooved Wooden Slats',
                      },
                      {
                        id: 'timber_acoustics',
                        label: 'Timber Acoustics Absorption Panels',
                        L6Name: 'Timber Acoustics Absorption Panels',
                      },
                    ]
                  },
                  {
                    id: 'sound_insulation',
                    label: 'Sound Insulation',
                    L5Name: 'Sound Insulation',
                  },
                  {
                    id: 'sound_masking',
                    label: 'Sound Masking',
                    L5Name: 'Sound Masking',
                  },
                ]
              }
            ],
          },
          {
            id: 'community_dev',
            label: 'Community Development',
            children: [
              {
                id: 'community_dev_overview',
                label: 'Overview',
                path: '/esg/social/community-development/overview'
              },
              {
                id: 'innovative_amenities',
                label: 'Innovative Amenity Concepts',
                path: '/esg/social/community-development/innovative-amenity-concepts',
                children: [
                  {
                    id: 'innovative_amenities_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'wellness_centers',
                    label: 'Wellness and Therapeutic Centers',
                    L5Name: 'Wellness and Therapeutic Centers',
                  },
                  {
                    id: 'pet_friendly_amenities',
                    label: 'Pet Friendly Amenities',
                    L5Name: 'Pet Friendly Amenities',
                  },
                  {
                    id: 'urban_gardens',
                    label: 'Urban Gardens and Urban Farming',
                    L5Name: 'Urban Gardens and Urban Farming',
                  }
                ]
              },
              {
                id: 'safety_and_security',
                label: 'Safety and security',
                path: '/esg/social/community-development/safety-and-security',
                children: [
                  {
                    id: 'safety_security_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'smart_access_control',
                    label: 'Smart Access Control',
                    L5Name: 'Smart Access Control',
                  },
                  {
                    id: 'emergency_comms_systems',
                    label: 'Emergency Communcation Systems',
                    L5Name: 'Emergency Communcation Systems',
                  },
                  {
                    id: 'fire_safety_systems',
                    label: 'Fire Safety Systems',
                    L5Name: 'Fire Safety Systems',
                  }
                ]
              },
            ]
          },
          {
            id: 'universal_design',
            label: 'Universal Design',
            path: '/esg/social/universal-design/',
            children: [
              {
                id: 'universal_design_overview',
                label: 'Overview',
                path: '/esg/social/universal-design/overview',
              },
              {
                id: 'automatic_doors',
                label: 'Automatic Doors',
                path: '/esg/social/universal-design/automatic-doors',
              },
              {
                id: 'innovative_ramps',
                label: 'Innovative Ramps and Slopes',
                path: '/esg/social/universal-design/innovative-ramps-and-slopes',
              },
              {
                id: 'smart_ramps',
                label: 'Smart Ramp Technologies',
                path: '/esg/social/universal-design/smart-ramp-technologies',
              },
              {
                id: 'wayfinding',
                label: 'Wayfinding Technologies',
                path: '/esg/social/universal-design/wayfinding-technologies',
              }
            ]
          },
          {
            id: 'mobility',
            label: 'Mobility',
            children: [
              {
                id: 'mobility_overview',
                label: 'Overview',
                path: '/esg/social/mobility/overview'
              },
              {
                id: 'electric_vehicles',
                label: 'Electric Vehicles',
                path: '/esg/social/mobility/electric-vehicles',
                children: [
                  {
                    id: 'electric_vehicles_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'ev_charging_infra',
                    label: 'Electric Vehicle (EV) Charging Infrastructure',
                    L5Name: 'Electric Vehicle (EV) Charging Infrastructure'
                  },
                  {
                    id: 'solar_powered_ev_charge',
                    label: ' Solar Powered EV Charging System',
                    L5Name: ' Solar Powered EV Charging System'
                  },
                ]
              }
            ]
          },
          {
            id: 'labour_practices',
            label: 'Labour practices and ethics',
            path: '/esg/social/labour-practices-and-ethics/',
            children: [
              {
                id: 'labour_practices_overview',
                label: 'Overview',
                path: '/esg/social/labour-practices-and-ethics/overview'
              },
              {
                id: 'labour_empowerment',
                label: 'Laborers Empowerment Aid Programme (LEAP)',
                path: '/esg/social/labour-practices-and-ethics/laborers-empowerment-aid-programme-(leap)'
              },
              {
                id: 'training_skill_dev',
                label: 'Training and skill development',
                path: '/esg/social/labour-practices-and-ethics/training-and-skill-development',
              },
              {
                id: 'health_and_safety',
                label: 'Health and Safety Standards',
                path: '/esg/social/labour-practices-and-ethics/health-and-safety-standards',
                children: [
                  {
                    id: 'health_and_safety_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'general_safety_ppe',
                    label: 'General Safety and PPE Training',
                    L5Name: 'General Safety and PPE Training'
                  },
                  {
                    id: 'task_specific_safety',
                    label: 'Task-Specific Safety Training',
                    L5Name: 'Task-Specific Safety Training'
                  },
                  {
                    id: 'emergency_rt',
                    label: 'Emergency Response Training',
                    L5Name: 'Emergency Response Training'
                  }
                ]
              },
              {
                id: 'labour_management',
                label: 'Labour Management',
                path: '/esg/social/labour-practices-and-ethics/labour-management',
                children: [
                  {
                    id: 'Worker Hiring',
                    label: 'Worker Hiring',
                    L5Name: 'Worker Hiring'
                  },
                  {
                    id: 'Worker / Labour Rental Housing',
                    label: 'Worker / Labour Rental Housing',
                    L5Name: 'Worker / Labour Rental Housing'
                  },
                  {
                    id: 'Workforce Management & Compliance',
                    label: 'Workforce Management & Compliance',
                    L5Name: 'Workforce Management & Compliance'
                  },
                ]
              },
            ]
          }
        ]
      },
      {
        id: 'esg_governance',
        label: 'Governance',
        children: [
          {
            id: 'esg_governance_overview',
            label: 'Overview',
            path: '/esg/governance/overview'
          },
          {
            id: 'esg_reporting_benchmarks',
            label: 'ESG Reporting Benchmarks',
            path: '/esg/governance/esg-reporting-benchmarks/',
            children: [
              {
                id: 'esg_reporting_benchmarks_overview',
                label: 'Overview',
                path: '/esg/governance/esg-reporting-benchmarks/overview',
              },
              {
                id: 'common_esg_reporting_standards',
                label: 'Common ESG Reporting Standards',
                path: '/esg/governance/esg-reporting-benchmarks/common-esg-reporting-standards',
                children: [
                  {
                    id: 'common_esg_reporting_standards_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'esg_standard_sasb',
                    label: 'The Sustainability Accounting Standards Board (SASB) standards',
                    L5Name: 'The Sustainability Accounting Standards Board (SASB) standards'
                  },
                  {
                    id: 'esg_standard_ifrs',
                    label: 'IFRS (International Financial Reporting Standards)',
                    L5Name: 'IFRS (International Financial Reporting Standards)'
                  },
                ]
              },
              {
                id: 'common_esg_reporting_frameworks',
                label: 'Common ESG Reporting Frameworks',
                path: '/esg/governance/esg-reporting-benchmarks/common-esg-reporting-frameworks',
                children: [
                  {
                    id: 'common_esg_reporting_frameworks_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'esg_framework_gri',
                    label: 'Global Reporting Initiative (GRI)',
                    L5Name: 'Global Reporting Initiative (GRI)'
                  },
                  {
                    id: 'esg_framework_cdp',
                    label: 'Carbon Disclosure Project (CDP)',
                    L5Name: 'Carbon Disclosure Project (CDP)',
                  },
                  {
                    id: 'esg_framework_tcfd',
                    label: 'Task Force on Climate-related Financial Disclosures (TCFD)',
                    L5Name: 'Task Force on Climate-related Financial Disclosures (TCFD)',
                  },
                  {
                    id: 'esg_framework_un_cop',
                    label: 'UN Global Compact (Communication on Progress - COP)',
                    L5Name: 'UN Global Compact (Communication on Progress - COP)',
                  },
                  {
                    id: 'esg_framework_integrated',
                    label: 'Integrated Reporting Framework',
                    L5Name: 'Integrated Reporting Framework',
                  },
                  {
                    id: 'esg_framework_gresb',
                    label: 'Global ESG Benchmark for Real Assets (GRESB)',
                    L5Name: 'Global ESG Benchmark for Real Assets (GRESB)',
                  },
                  {
                    id: 'esg_framework_brsr',
                    label: 'Business Responsibility and Sustainability Reporting (BRSR)',
                    L5Name: 'Business Responsibility and Sustainability Reporting (BRSR)',
                  },
                ]
              },
            ]
          },
          {
            id: 'esg_green_building_certifications',
            label: 'Green Building Certifications',
            children: [
              {
                id: 'esg_green_building_certifications_overview',
                label: 'Overview',
                path: '/esg/governance/green-building-certifications/overview'
              },
              {
                id: 'growth_in_green_buildings',
                label: 'Growth in Green Buildings',
                path: '/esg/governance/green-building-certifications/growth-in-green-buildings',
              },
              {
                id: 'green_certification_india',
                label: 'Green Certification in India',
                path: '/esg/governance/green-building-certifications/green-certification-in-india/',
                children: [
                  {
                    id: 'green_certification_india_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'igbc_certification',
                    label: 'IGBC Certification',
                    L5Name: 'IGBC Certification',
                  },
                  {
                    id: 'griha_certification',
                    label: 'GRIHA Certification',
                    L5Name: 'GRIHA Certification',
                  }
                ]
              },
              {
                id: 'green_certification_global',
                label: 'Green Certification Globally',
                path: '/esg/governance/green-building-certifications/green-certification-globally/',
                children: [
                  {
                    id: 'green_certification_global_overview',
                    L5Name: 'Overview',
                    label: 'Overview',
                  },
                  {
                    id: 'leed_certification',
                    label: 'LEED',
                    L5Name: 'LEED (Leadership in Energy and Environmental Design)'
                  },
                  {
                    id: 'edge_certification',
                    label: 'EDGE',
                    L5Name: 'EDGE (Excellence in Design for Greater Efficiencies)'
                  },
                  {
                    id: 'edge_certification',
                    label: 'UN PRI',
                    L5Name: 'UN PRI (United Nations Principles for Responsible Investment)'
                  }
                ]
              },
            ]
          },
        ]
      },
      
    ]
  },
  {
    id: 'knowledge',
    label: 'Knowledge',
    children: [
      {
        id: 'research_reports',
        label: 'Research Reports',
        path: '/research-reports'
      },
      {
        id: 'academia',
        label: 'Academia',
        path: '/academia'
      },
      {
        id: 'case_studies',
        label: 'Case Studies',
        path: '/case-studies'
      },
    ],
  },
  {
    id: 'finance',
    label: 'Finance',
    children: [
      {
        id: 'finance_apply',
        label: 'Apply',
        children: [
          {
            id: 'finance_apply_developers',
            persona: 'developers',
            label: 'Developers & Builders',
            path: '/finance',
          },
          {
            id: 'finance_apply_suppliers',
            persona: 'suppliers',
            label: 'Supply Ecosystem',
            path: '/finance',
          },
          {
            id: 'finance_apply_startups',
            persona: 'startups',
            label: 'Startups & Innovators',
            path: '/finance',
          }
        ]
      },
      {
        id: 'financing_solutions',
        label: 'Solutions',
        children: [
          {
            id: 'land_financing',
            label: 'Land Financing',
            path: '/financing/land-financing',
          },
          {
            id: 'msme_credit',
            label: 'MSME Credit',
            path: '/financing/msme-credit',
          },
          {
            id: 'invoice_discounting',
            label: 'Invoice Discounting',
            path: '/financing/invoice-discounting',
          },
          {
            id: 'home_loan_tech',
            label: 'Home Loan Technology',
            path: '/financing/home-loan-technology',
          },
          {
            id: 'insurance',
            label: 'Insurance',
            path: '/financing/insurance',
          },
        ],
      },
      {
        id: 'fractional_ownership',
        label: 'Fractional Ownership',
        imageTitle: 'Fractional Ownership',
        children: [
          {
            id: 'fractional_assets',
            label: 'Fractional Assets',
            path: '/fractional-ownership/fractional-assets'
          },
          {
            id: 'fractional_debts',
            label: 'Fractional Debts',
            path: '/fractional-ownership/fractional-debts'
          }
        ]
      }
    ],
  }
];