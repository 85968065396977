import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import axios from "axios";
import Banner from "../../components/Banner";
import TruncateText from "../../components/TruncateText";
import Markdown from "react-markdown";
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

import '../../styles/esg.css';
import EsgImageGrid from "./EsgImageGrid";
import { getStrapiApis } from "../../getStrapiApis";
import NewLoginDialog from "../../accounts/NewLoginDialog";
import SolutionInfographics from "../../components/SolutionInfographics";
import SolutionBlogsReports from "../../components/SolutionBlogsReports";
import { useNavigate } from "react-router";
import CustomMarkdown from "../../components/CustomMarkdown";

const API_BASE_URL = getStrapiApis();

const EsgOverview = () => {
  const navigate = useNavigate();
  const [esgContent, setESGContent] = useState();
  const breadcrumbs = [["Home", "home"], ["ESG"]];

  useEffect(() => {
    const fetchESGData = async () => {
      try {
        const [response] = await Promise.all([
          axios.get(`${API_BASE_URL}/esg-overview?populate=esgCategories.esgCatImage&populate=bannerImage&populate=esgSolutions.esgCatImage&populate=verticalInfographics.url&populate=verticalInfographics.url&populate=verticalInfographics2.url&populate=referenceReports.reportUrl&populate=blogs.blogUrl`),
        ]);
        setESGContent(response?.data?.data?.attributes);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchESGData();
  }, []);


  const esgSolutionRedirect = (solution) => {
    if (!solution || !solution.esgCatName) return;
    navigate(`/esg/${solution?.esgCatName?.toLowerCase()}`);
  }
  
  return (<>
    <Header selectedOpt={"esg"} />
    
    <NewLoginDialog />
    <Banner heading={'ESG'} subheading={esgContent?.bannerContent} breadcrumbs={breadcrumbs} url={esgContent?.bannerImage?.data?.attributes?.url}/>
    <div className="text-container" style={{paddingBottom: '1rem'}}>
      <div className="esg-text">
        <CustomMarkdown>{esgContent?.whatIsESG}</CustomMarkdown>
      </div>
      {esgContent?.verticalInfographics?.length > 0 && esgContent?.verticalInfographics.map((vi, i) => (
        <SolutionInfographics key={i} infographic={vi} />
      ))}
      <div className="esg-text">
        <CustomMarkdown>{esgContent?.whatIsESGContinued}</CustomMarkdown>
      </div>
      <h3 className="esg-title">What is the need for ESG in India?</h3>
      <TruncateText>
        <div className="esg-text">
          {esgContent?.needInIndia && esgContent?.needInIndia.length && (
            <BlocksRenderer content={esgContent?.needInIndia} />
          )}
        </div>
      </TruncateText>
      <h3 className="esg-title">What does ESG mean for Housing sector?</h3>
      <div className="esg-text">
        <TruncateText>
          <CustomMarkdown>{esgContent?.esgMeanForRealEstate}</CustomMarkdown>
        </TruncateText>
      </div>
      {esgContent?.verticalInfographics2?.length > 0 && esgContent?.verticalInfographics2.map((vi, i) => (
        <SolutionInfographics key={i} infographic={vi} />
      ))}
      <div className="esg-text">
        <CustomMarkdown>{esgContent?.esgMeanForRealEstate2}</CustomMarkdown>
      </div>
      {esgContent?.esgSolutions?.length > 0 && (
        <div className="esg-solutions-grid">
          {esgContent.esgSolutions.map((sol,i) => (
            <div key={i} className="esg-solution-card" onClick={_ => esgSolutionRedirect(sol)}>
              <div className="esg-solution-card-img" style={{backgroundImage: `url(${sol?.esgCatImage?.data?.attributes?.url})`}} />
              <div className="esg-solution-content">
                <div className="esg-solution-title">{sol?.esgCatName}</div>
                <div className="esg-solution-subtitle">{sol?.esgCatDesc}</div>
              </div>
            </div>
          ))}
        </div>
      )}

      {esgContent?.referenceReports?.length > 0 && (
        <SolutionBlogsReports type="report" data={esgContent?.referenceReports} />
      )}
      {esgContent?.blogs?.length > 0 && (
        <SolutionBlogsReports type="blog" data={esgContent?.blogs} />
      )}

      <h3 className="esg-title">Discover more on ESG</h3>
    </div>
    <EsgImageGrid data={esgContent?.esgCategories}/>
    <Footer />
  </>);
};

export default EsgOverview;
