import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactGA from "react-ga";
import "../App.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import HomeInnovativeSolutions from "../components/HomeInnovativeSolutions";
import { Box, useMediaQuery, Typography } from "@mui/material";
import develop from "../assets/images/grow.png";
import supply from "../assets/images/Supplier.jpg";
import startup from "../assets/images/Startups.jpg";
import financiers from "../assets/images/Financier.jpg";
import others from "../assets/images/others.jpg";
import rocket from "../assets/images/rocket.svg";
import coinStack from "../assets/images/coin-stack.svg";
import users from "../assets/images/users.svg";
import truck from "../assets/images/truck.svg";
import grid from "../assets/images/grid.svg";
import { useSelector } from "react-redux";
import Insights from '../components/Insights';
import SolutionsForAll from "../components/SolutionsForAll";
import SolutionsToDrive from "../components/SolutionsToDrive";
import { useLocation, useNavigate } from 'react-router-dom';

import { getStrapiApis } from "../getStrapiApis";
import NewLoginDialog from "../accounts/NewLoginDialog";

// const API_BASE_URL = "https://strapi-hdfc.azurewebsites.net/api";

// const API_BASE_URL = "http://localhost:4000/cms-api";
const API_BASE_URL = getStrapiApis();

const styles = {
  hero: {
    display: "flex",
    position: "relative",
    width: "100%",
  },
  iconcont: {
    width: "100%",
    display: "flex",
    padding: "0px 168px 12px 0px",
  },
  iconoutline: {
    display: "flex",
    width: "32",
    height: "32px",
    padding: "18px 18px 18px 18px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
    borderRadius: "50%",
    background: "#E7F5FF",
  },
};

function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  // if (!localStorage.user || !localStorage.accessToken) navigate('/');

  const [apply, setApply] = useState(false)
  const [innovations, setInnovations] = useState([]);
  const [content, setContent] = useState([]);
  const [insights, setInsights] = useState([]);
  const [welcomeDesc,setWelcomeDesc] = useState("");
  let userName = '';
  let persona = '';
  // let userName = useSelector((state) => state?.form?.formData?.name || state?.subscriber?.userName);
  // let persona = useSelector((state) => state?.subscriber?.persona ||  state?.form?.formData?.persona);

  try {
   userName = JSON.parse(localStorage.getItem('new_user')).name;
   persona = JSON.parse(localStorage.getItem('new_user')).persona;
  } catch (e){}
  console.log(persona, !persona);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [innovationsResponse, contentResponse, insightsResponse] =
          await Promise.all([
            axios.get(
              `${API_BASE_URL}/prelogin-innovations?populate=*&sortId=ImageId`
            ),
            axios.get(`${API_BASE_URL}/prelogin-contents?sort=contentId`),
            axios.get(`${API_BASE_URL}/prelogin-insights?populate=*`),
          ]);

        setInnovations(innovationsResponse.data);
        setContent(contentResponse.data);
        setInsights(insightsResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (content?.data?.length) {
      setWelcomeDesc(content.data[2]?.attributes?.content)
    }
  }, [content]);

  useEffect(() => {
    if(location?.state?.apply) {
      setApply(true)
      const footerSection = document.getElementById('solutiontoDrive');
      if (footerSection) {
        footerSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [apply]); 

  const backgroundMap = {
    developers: develop,
    suppliers: supply,
    startups: startup,
    financiers: financiers,
  }
  const iconMap = {
    developers: users,
    suppliers: truck,
    startups: rocket,
    financiers: coinStack,
  }
  
  return (
    <div>
      <Header toggleTransparency={true}/>
      <NewLoginDialog />
      <div className="home-hero">
        <div className="home-hero-bg" style={{backgroundImage: `linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 25%), url(${backgroundMap[persona] || others})`}}></div>
      </div>
      <div className="home-hello">
        <div className="home-hello-header">
          <div className="home-hello-icon">
            <img src={iconMap[persona] || grid} alt="persona icon" />
          </div>
          <div className="home-hello-hi">
            Hi {userName}!
          </div>
        </div>
        <div className="home-hello-body">
        {welcomeDesc}
        </div>
      </div>

      {innovations?.data?.length > 1 && (
        <HomeInnovativeSolutions innoData={innovations.data} />
      )}
      {insights?.data?.length && innovations?.data?.length > 1 && <Insights insights={insights} showInnovators={false} innoData={innovations.data}/>}
      <div  id='solutiontoDrive'>
        {(['developers', 'suppliers', 'startups'].includes(persona))  && <SolutionsToDrive persona={persona}/>}
        {(['financiers', 'homebuyer', 'others'].includes(persona))  && <SolutionsForAll />}
        {!persona && <SolutionsToDrive />}
      </div>
      <Footer />
    </div>
  );
}

export default Home;
