import React, { useEffect, useState } from "react";
import "../accounts/logIn.css";
import {
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  MenuItem,
  Select,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import loginImage from "../assets/images/login.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
// import OTPInput from "otp-input-react";
import OtpInput from 'react-otp-input';
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { savePhoneNumberFunc, sendOTP } from "../redux/slice/otpVarifySlice";
import { verifyOTP, clearError } from "../redux/slice/otpVerificationSlice";
import { submitFormData } from "../redux/slice/personaRegSlice";

const LoginDialog = ({
  open,
  onClose,
  redirection,
  handleNavOnlyAfterLogin,
  childParentItem,
  inno,
}) => {
  // Define a theme with your custom font
  const theme = createTheme({
    typography: {
      fontFamily: ["Syne", "sans-serif"].join(","),
      fontSize: 20,
      fontWeight: 500,
    },
  });
  useEffect(() => {
    return () => dispatch(clearError());
  });

  const [showVarifyOtpDialog, setShowVarifyOtpDialog] = useState(false);
  const [showThirdDialog, setShowThirdDialog] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accessToken = useSelector((state) => state?.auth?.accessToken);
  const subscriber = useSelector((state) => state?.subscriber);
  const persona = subscriber?.otpResponse?.persona;

  const ThirdDialog = persona === null || persona === undefined;

  const handleOnChange = (value) => {
    setPhoneNumber(value);
    dispatch(savePhoneNumberFunc(value));
    setIsPhoneNumberValid(value?.length >= 3);
  };

  const handleKeyDown = (e) => {
    if (phoneNumber?.length >= 13 && e.key !== "Backspace") {
      e.preventDefault();
    }
  };

  const handleOtpChange = (value) => {
    setOtp(value);
  };

  const handleSendOTP = (e) => {
    e.preventDefault();
    if (!phoneNumber?.length) {
      setIsPhoneNumberValid(false);
    } else {
      setIsPhoneNumberValid(true);
      dispatch(sendOTP({ phoneNumber, accessToken }));
      onClose();
      setShowVarifyOtpDialog(true);
    }
  };

  const handleVerifyOTP = (e) => {
    e.preventDefault();
    if (!otp) {
      setIsOtpValid(false);
      return;
    }

    dispatch(verifyOTP({ otp, phoneNumber, accessToken })).then((response) => {
      if (!response || !response.payload || (response.payload && response.payload.status === false)) {
        setIsOtpValid(false);
        setShowThirdDialog(false);
        setErrorMessage(response?.payload?.message || "Please Enter Valid OTP");
      } else {
        setIsOtpValid(true);
        setShowVarifyOtpDialog(false);
        setShowThirdDialog(true);
        setOtp("");
        setErrorMessage("");
        if (accessToken) {
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("unsubscribedPersona", subscriber?.unsubscribedPersona)
          localStorage.setItem(
            "user",
            JSON.stringify({
              name: subscriber?.otpResponse.name,
              persona: subscriber?.persona,
              phoneNumber: subscriber?.phoneNumber?.slice(1),
              personaSubtype: subscriber?.personaSubtype,
              unsubscribedPersona: subscriber?.unsubscribedPersona,
              email: subscriber?.email,
              firm: subscriber?.firm
            })
          );
        }
        if (inno) {
          navigate("/innovations/solutions", {
            state: {
              ...inno,
              headerSelectedCategory: null,
              headerSelectedSubCategoryName2: null,
            },
          });
        }
        else if ((JSON.parse(localStorage.user).persona || persona) && !redirection) {
          navigate("/home");
        } else if ((JSON.parse(localStorage.user).persona || persona) && redirection) {
          if (childParentItem) {
            if (childParentItem?.parent && childParentItem.parent.id === "apply" && childParentItem?.item &&
              childParentItem.item.id === "finance") {
                if(['developers', 'suppliers', 'startups'].includes(persona) || ['developers', 'suppliers', 'startups'].includes(JSON.parse(localStorage?.user)?.persona))
                navigate('/finance');
              else
              navigate('/home')
            }
            else {
              handleNavOnlyAfterLogin(
                childParentItem.child,
                childParentItem.parent,
                childParentItem.item,
                childParentItem.childHolder
              );
            }
          }
        }
      }
    });
  };

  const [formValue, setFormValue] = useState({
    name: "",
    persona: "",
    subPersona: "",
    isChecked: false,
    errors: {
      name: "",
      persona: null,
      subPersona: null,
      isChecked: null,
    },
  });

  const handleFormChange = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    let prev = formValue;
    prev[key] = value;
    prev.errors[key] = null;
    if (key === "persona") {
      prev.subPersona = getSubPersona(value);
      prev.errors.subPersona = null;
    }
    setFormValue((prev) => ({
      ...prev,
      [key]: value,
      errors: {
        ...prev.errors,
        [key]: null,
      },
    }));
  };

  function getSubPersona(persona) {
    if (persona === "developers") return "Developers & Builders";
    if (persona === "startups") return "Startups & Innovators";
    if (persona === "homebuyer") return "Homebuyers";
    return "";
  }

  function handleCheckboxChange(e) {
    const value = e.target.checked;
    const key = e.target.name;
    setFormValue((prev) => ({
      ...prev,
      [key]: value,
      errors: {
        ...prev.errors,
        [key]: null,
      },
    }));
  }

  // final submit
  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, persona, subPersona, isChecked } = formValue;
    let errors = {nameError: "", subjectError: "", isCheckedError: ""};
    let isValid = true;

    if (!name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (!persona || !persona.length) {
      errors.persona = "Please select a category";
      isValid = false;
    }

    if (!isChecked) {
      errors.isChecked = "Please agree to BLEU Circle's Terms of Service and Privacy Policy.";
      isValid = false;
    }

    // Update errors in the state
    setFormValue((prevState) => ({
      ...prevState,
      errors: errors,
    }));

    // If all fields are valid, proceed with form submission
    if (isValid) {
      // Dispatch only name and selectedPersona
      dispatch(
        submitFormData({
          formData: { name, persona, subPersona, phoneNumber},
          accessToken,
        })
      );
      setPhoneNumber("");
      navigate("/home");
      setShowThirdDialog(false);
    }
  };

  const lastDigits = phoneNumber?.slice(-2);
  const formattedNumber = `${+91}-XXXXXXX${lastDigits}`;

  function skip() {
    // onClose();
    setIsPhoneNumberValid(true);
    setShowVarifyOtpDialog(true);
    // setShowThirdDialog(true);
  }

  return (
    <>
      <Dialog
        className="login-dialog"
        open={open}
        onClose={onClose}
        PaperProps={{style: {margin: '0', borderRadius: '1.25rem', width: '640px', maxWidth: '100%'}}}
      >
        <DialogContent className="login-dialog-content" style={{}}>
          <div className="image-container">
            <img src={loginImage} alt="loginImage" width="100%" />
            <CloseIcon className="close-icon" onClick={() => onClose()} />
          </div>
          <div className="login-dialog-form">
            <div className="login-modal-title">Sign In</div>
            <div>
              <label htmlFor="mobileNumber" className="mobile-num">Mobile number</label>
              <PhoneInput
                className={`login-phone-input ${isPhoneNumberValid ? '' : 'invalid'}`}
                defaultCountry="IN"
                value={phoneNumber}
                onChange={handleOnChange}
                onKeyDown={handleKeyDown}
                international={true}
                placeholder="Enter mobile number"
                inputprops={{maxLength: 10}}
              />
              {!isPhoneNumberValid && <div className="login-error-msg">Please enter a valid phone number</div>}
            </div>
            <div className="login-button-container">
              <button className="login-submit-button" onClick={handleSendOTP}>
                Send OTP
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        className="login-dialog"
        open={showVarifyOtpDialog}
        onClose={() => {
          setPhoneNumber("");
          setShowVarifyOtpDialog(false);
          dispatch(clearError());
        }}
        PaperProps={{style: {margin: '0', borderRadius: '1.25rem'}}}
      >
        <DialogContent>
          <div className="image-container">
            <img src={loginImage} alt="loginImage" width="100%" />
            <CloseIcon className="close-icon" onClick={() => setShowVarifyOtpDialog(false)} />
          </div>
          <div className="login-dialog-form">
            <div className="login-modal-title">Verify OTP</div>
            <div className="login-modal-body verify">
              Please enter the OTP received on your mobile number +{formattedNumber}.
            </div>
            <div>
              <OtpInput
                value={otp}
                onChange={handleOtpChange}
                numInputs={4}
                // isInputNum={true}
                inputType="tel"
                isSecure={true}
                containerStyle="otp-input-container"
                renderInput={props => <input {...props} />}
                // inputStyle="otp-input-field"
              />
              {/* {!isOtpValid && <div className="login-error-msg">OTP is required!</div>} */}
              {errorMessage && <div className="login-error-msg">{errorMessage}</div>}
            </div>
            <div className="resend-otp-container">
              <div className="recieve_otp">Didn't recieve the OTP?</div>
              <div className="recieve_otp sendOtp_btn" onClick={handleSendOTP}>Resend OTP</div>
            </div>
            <div className="login-button-container">
              <button className="login-submit-button" onClick={handleVerifyOTP}>
                Verify OTP
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {ThirdDialog && (
        <Dialog
          className="login-dialog"
          open={showThirdDialog}
          onClose={() => setShowThirdDialog(false)}
          PaperProps={{style: {margin: '0', borderRadius: '1.25rem', width: '640px', maxWidth: '100%'}}}
        >
          <DialogContent>
            <div className="image-container">
              <img src={loginImage} alt="loginImage" width="100%"  style={{marginTop: '-2.5rem'}}/>
              <CloseIcon className="close-icon" onClick={() => setShowThirdDialog(false)} />
            </div>
            <div className="login-dialog-form">
              <div className="login-dialog-form-max-height">
                <div className="login-modal-title gradient-header">Getting Started</div>
                <div className="login-modal-body">
                  Awesome! You are almost there. Please help us fill this information to set up your account.
                </div>
                <FormControl fullWidth className="input-field-container">
                  <label>Name <span className="required">*</span></label>
                  <TextField
                    type="text"
                    size="small"
                    variant="outlined"
                    placeholder="Enter your name"
                    value={formValue.name}
                    name="name"
                    onChange={handleFormChange}
                    error={!!formValue.errors.name}
                    helperText={formValue.errors.name}
                  />
                </FormControl>
                <FormControl fullWidth className="input-field-container">
                  <label>
                    Which category do you fall in?
                    <span className="required">*</span>
                  </label>
                  <Select
                    className="persona-selector"
                    value={formValue.persona}
                    onChange={handleFormChange}
                    name="persona"
                    placeholder="Select Category"
                    error={!!formValue.errors.persona}
                    helperText={formValue.errors.persona}
                  >
                    <MenuItem value="developers">
                      <PeopleAltOutlinedIcon className="select-icon" color="primary" />
                      Developers & Builders
                    </MenuItem>
                    <MenuItem value="suppliers">
                      <LocalShippingOutlinedIcon className="select-icon" color="primary" />
                      Supply Ecosystem
                    </MenuItem>
                    <MenuItem value="startups">
                      <RocketLaunchOutlinedIcon className="select-icon" color="primary" />
                      Startups & Innovators
                    </MenuItem>
                    <MenuItem value="financiers">
                      <AccountBalanceOutlinedIcon className="select-icon" color="primary" />
                      Financiers & Corporates
                    </MenuItem>
                    <MenuItem value="homebuyer">
                      <HomeOutlinedIcon className="select-icon" color="primary" />
                      Homebuyers
                    </MenuItem>
                    <MenuItem value="others">
                      <GridViewOutlinedIcon className="select-icon" color="primary" />
                      Others
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth className="input-field-container">
                  {["developers", "suppliers", "startups", "financiers", "homebuyer"].includes(formValue.persona) && (
                    <>
                      <label>What describes you the best? <span className="required">*</span></label>
                      <RadioGroup
                        name="subPersona"
                        onChange={handleFormChange}
                        value={formValue.subPersona}
                      >
                        {formValue.persona === "developers" && (
                          <FormControlLabel
                            value="Developers and Builders"
                            control={<Radio />}
                            label="Developers & Builders"
                          />
                        )}
                        {formValue.persona === "suppliers" && (
                          <>
                            <FormControlLabel
                              value="Supply ecosystem"
                              control={<Radio />}
                              label="Supply Ecosystem (Suppliers, Vendors, Service Providers etc.)"
                            />
                            <FormControlLabel
                              value="Bankers or lawyers"
                              control={<Radio />}
                              label="Bankers & Lawyers"
                            />
                          </>
                        )}
                        {formValue.persona === "startups" && (
                          <FormControlLabel
                            value="Startups"
                            control={<Radio />}
                            label="Startups & Innovators"
                          />
                        )}
                        {formValue.persona === "financiers" && (
                          <>
                            <FormControlLabel
                              value="Global Investors"
                              control={<Radio />}
                              label="Global Investors"
                            />
                            <FormControlLabel
                              value="Indian Investors"
                              control={<Radio />}
                              label="Indian Investors"
                            />
                            <FormControlLabel
                              value="Corporate/Firms"
                              control={<Radio />}
                              label="Corporates/Firms"
                            />
                          </>
                        )}
                        {formValue.persona === "homebuyer" && (
                          <FormControlLabel
                            value="Homebuyers"
                            control={<Radio />}
                            label="Homebuyers"
                          />
                        )}
                      </RadioGroup>
                    </>
                  )}
                  {formValue.persona === "others" && (
                    <>
                      <label>If others, please specify</label>
                      <TextField
                        type="text"
                        size="small"
                        variant="outlined"
                        value={formValue.subPersona}
                        name="subPersona"
                        onChange={handleFormChange}
                        error={!!formValue.errors.subPersona}
                        helperText={formValue.errors.subPersona}
                      />
                    </>
                  )}
                </FormControl>
                <FormControl fullWidth className="input-field-container">
                  <FormControlLabel
                    control={
                      <Checkbox checked={formValue.isChecked} name="isChecked" onChange={handleCheckboxChange} color="primary" />
                    }
                    label={
                      <div className="terms-checkbox">
                        By continuing, you agree to BLEU Circle's{" "}
                        <span className="terms-url">Terms of Use</span> and{" "}
                        <span className="terms-url">Privacy Policy</span>.
                      </div>
                    }
                  />
                  {formValue.errors.isChecked && <div className="form-error">{formValue.errors.isChecked}</div>}
                </FormControl>
              </div>
              <div className="login-button-container" style={{marginTop: '.75rem'}}>
                <button className="login-submit-button" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default LoginDialog;
