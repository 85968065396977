import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getApiUrl } from '../../getApiUrl';

export const financialRegistrationForStartup = createAsyncThunk(
    'startups/financialRegistrationForStartup',
    async ({ formValues, accessToken,gettingLoginNums }, { rejectWithValue }) => {
        const {
            startupName,
            startupContactPersonName,
            startupWebsiteURL,
            startupFinanceType
          } = formValues;
          
          
          const processedPhoneNumber = gettingLoginNums.replace(/^(\+91)/, "");
          
          const apiUrl = getApiUrl();

    try {
        const url = `${apiUrl}/services/apexrest/financialregistrationForStartup`
        const paramData = {
          "data": {
            startUpName: startupName,
            contactPerson: startupContactPersonName,
            mobileNumberOfContact: processedPhoneNumber,
            websiteURL: startupWebsiteURL,
            typesOfFinanceReq: startupFinanceType,
            financeType: 'Startups & Innovators',
            userName: JSON.parse(localStorage.getItem("new_user")).name
          }
      }
      const apiResp = await axios.post(url, JSON.stringify(paramData), {headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
      }});
        
        return apiResp;
    } catch (error) {
            return rejectWithValue(error.response.data);
          }
    }
  );
  

const apiSlice = createSlice({
  name: 'startups',
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
  
  },
  extraReducers: (builder) => {
    builder.addCase(financialRegistrationForStartup.pending, (state) => {
      // Handle pending state
      state.loading = true;
      state.error = null;
    });
    builder.addCase(financialRegistrationForStartup.fulfilled, (state, action) => {
      // Handle success state
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(financialRegistrationForStartup.rejected, (state, action) => {
      // Handle error state
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default apiSlice.reducer;
