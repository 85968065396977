import React, { useEffect, useState } from 'react'
import Header from '../../components/Header';
import Finance from './Finance';
import FinStartup from './FinStartup';
import FinSupplier from './FinSupplier';
import { useLocation } from "react-router";

const MasterFinance = () => {
    const location = useLocation();
    const [personaType, setPersonaType] = useState(null);

    // const {persona} = location?.state;
    console.log(location);
    useEffect(() => {
      if (location?.state?.persona?.length) {
        setPersonaType(location.state.persona);
      } else {
        try {
          setPersonaType(JSON.parse(localStorage.getItem('new_user')).persona);
        } catch {
          setPersonaType('developers');
        }
      }
    }, []);

    useEffect(() => {
      setPersonaType(location?.state?.persona);
    }, [location?.state])

      if (personaType === null) {
        return <p>Loading...</p>;
      }
  return (
    <div>
        {/* <Header/> */}
        { personaType === 'developers'?<Finance />:personaType === 'startups'?<FinStartup />:personaType === 'suppliers'?<FinSupplier />:<Finance />}
      
    </div>
  )
}

export default MasterFinance
