import React, { useState, useEffect } from 'react'
import Lottie from 'react-lottie';
import Vision from './Vision';
import TechInnovators from './TechInnovators';
import AcademicPartnerships from '../assets/animations/AcademicPartnerships.json';
import ReportsInsights from '../assets/animations/ReportsInsights.json';
import { useNavigate } from "react-router-dom";
import LoginDialog from "../accounts/LoginDialog";

function Insights({insights, showInnovators = true, innoData}) {
	const navigate = useNavigate();
	const [dialogOpen, setDialogOpen] = useState(false);
	const [esgSol, setEsgSol] = useState(null);
	const [academiaSol, setAcademiaSol] = useState(null);
	const handleClick =(id)=> {
		if (id == 'research-reports') {
			navigate('/research-reports')
		} else {
			navigate('/academia');
		}
		// else if (!localStorage.accessToken) {
		// 	redirectToLogin()
		// }
		// else if (localStorage.accessToken) {
		// }
	}
	const handleCloseDialog = () => {
		setDialogOpen(false);
	};
	const redirectToLogin = () => {
		setDialogOpen(true);
	}
	const [content, setContent] = useState({
		contentId1: {
			heading: '',
			subHeading: '',
			image: null
		},
		contentId2: {
			heading: '',
			subHeading: '',
			image: null
		},
		contentId3: {
			heading: '',
			subHeading: '',
			image: null
		},
		contentId4: {
			heading: '',
			subHeading: '',
			image: null
		},
		contentId5: {
			heading: '',
			subHeading: '',
			image: null
		}
	})
	useEffect(()=>{
		const updatedContent = {};

		for (const item of insights.data) {
			const { id, attributes } = item;
			const { contentID, heading, subHeading, image, imageList } = attributes;

			switch (contentID) {
				case 1:
					updatedContent.contentId1 = { heading, subHeading, image: image?.data?.attributes };
					break;
				case 2:
					updatedContent.contentId2 = { heading, subHeading, image: image?.data?.attributes };
					break;
				case 3:
					updatedContent.contentId3 = { heading, subHeading, image: image?.data?.attributes };
					break;
				case 4:
					updatedContent.contentId4 = { heading, subHeading, image: image?.data?.attributes };
					break;
				case 5:
					updatedContent.contentId5 = { heading, subHeading, image: imageList };
					break;
				default:
					break;
			}
			
			setContent((prevContent) => ({
				...prevContent,
				...updatedContent,
			})); 
		}
	}, [insights]);

	useEffect(()=> {
		if (innoData.length) {
			const [esg] = innoData.filter((inn) => {
				return inn?.attributes?.imageTitle === 'ESG';
			})
			setEsgSol(esg);
			const [academia] = innoData.filter((inn) => {
				return inn?.attributes?.imageTitle === 'Academia';
			})
			setAcademiaSol(academia)
		}
	}, [])
	const lottieOptions = {
		loop: true, 
		autoplay: true,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	return (
		<>
			{content.contentId5?.heading !== '' && esgSol && <Vision vision={content.contentId1} innoSol={esgSol} />}
			<div className="insights-background">
				<div className="insights-container">
					<div className="insights-info">
						<div className="insights-text">
							<div className="insights-title render-new-lines">{content.contentId2?.heading}</div>
							<div className="insights-body render-new-lines">{content.contentId2?.subHeading}</div>
						</div>
					</div>
					<div className="insights-cards">
						<div className="column">
							<div className="insight-card" onClick={_ => handleClick('Academia')}>
								<div className="card-featured-image">
									<Lottie options={{...lottieOptions, animationData: AcademicPartnerships}} height={180} width={180} />
								</div>
								<div className="card-title">{content.contentId3?.heading}</div>
								<div className="card-body">{content.contentId3?.subHeading}</div>
							</div>
							{content.contentId3?.image?.url ? 
								<div className="insight-card full-image-card" style={{backgroundImage: `url(${content.contentId3?.image?.url})`}}></div>
							: ''}
						</div>
						<div className="column">
							{content.contentId4?.image?.url ? 
								<div className="insight-card full-image-card" style={{backgroundImage: `url(${content.contentId4?.image?.url})`}}></div>
							: ''}
							<div className="insight-card" onClick={_ => handleClick('research-reports')}>
								<div className="card-featured-image" style={{marginLeft: '-1rem'}}>
									<Lottie options={{...lottieOptions, animationData: ReportsInsights}} height={180} width={180} />
								</div>
								<div className="card-title">{content.contentId4?.heading}</div>
								<div className="card-body">{content.contentId4?.subHeading}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{showInnovators && <TechInnovators content={content.contentId5}/>}
		</>
	)
}

export default Insights