import React from "react";
import arrow from "../assets/images/arrow-right.svg";

function SolutionNews({ data, titleClass, sectionTitle="", sectionHeader = "" }) {
  const handleClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <>
      <div>
        <div className="section-heading">
          {sectionHeader ? <div className="rrreports-heading">{sectionHeader}</div> : "News Articles"}
          <span>{sectionTitle}</span>
        </div>
        <div className="news-container">
          {data.map((d, i) => (
            <div
              key={i}
              className="news-card"
              onClick={() => handleClick(d.newsLink)}
            >
              <div>
                <div className="news-author no-wrap">{d.dateAuthorName}</div>
                <div className="news-title">
                  {d.newsHeadline || "News Article"}
                </div>
              </div>
              <div className="news-action">
                Read <img src={arrow} alt="read arrow" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default SolutionNews;
